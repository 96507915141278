.invisible {
  display: none;
}

.analytics-header-export-btn {
  width: fit-content;
  padding: 8px 12px;
  height: 35px;
  gap: 8px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: 0.2s;
}

.analytics-header-export-btn:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.analytics-header-dropdown-menu {
  padding: 4px !important;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.analytics-header-dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  color: #141315;
  width: 232px;
  height: 36px;
  padding: 0 12px;
}

.analytics-header-dropdown-menu .dropdown-item:hover {
  border-radius: 8px;
}

.analytics-header-dropdown .dropdown-toggle svg {
  transition: 0.2s;
}

.analytics-header-dropdown.show .dropdown-toggle svg {
  transform: rotate(180deg);
  transition: 0.2s;
}

.analytics-total-info-wrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
  gap: 8px;
}

.analytics-total-info-block-item {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 77px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #edf3f9;
  width: 100%;
  background-color: #ffffff;
}

.analytics-total-info-block-information {
  display: flex;
  flex-direction: column;
}

.analytics-total-info-block-title {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
}

.analytics-total-info-block-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #7d8898;
}

.faq-section-wrapper {
  width: 388px;
  height: 596px;
  max-height: calc(100vh - 86px);
  box-shadow: 0px 9px 18px 0px #00000026;
  border-radius: 16px;
  position: absolute;
  right: 16px;
  bottom: 70px;
  background-color: #ffffff;
  overflow: hidden;
}

.faq-open-image {
  position: absolute;
  bottom: 16px;
  right: 16px;
  cursor: pointer;
}

.faq-section-header {
  width: 100%;
  height: 60px;
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  background-color: #1b003e;
  font-family: "Inter";
  font-size: 23px;
  font-weight: 800;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #e0c8ff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.faq-section-content {
  padding: 16px;
  overflow-y: auto;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.faq-content-question-types-filter-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 44px;
  width: 100%;
  border-radius: 8px;
  padding: 4px;
  gap: 4px;
  background-color: #edf3f9;
}

.faq-content-question-type-item {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #7d8898;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.faq-content-question-type-item.active {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #9747ff;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.faq-content-questions-list-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.faq-content-question-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.faq-content-question-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #141315;
  cursor: pointer;
}

.faq-content-question-item-header svg {
  width: 20px;
  min-width: 20px;
  transform: rotate(180deg);
  transition: 0.2s;
}

.faq-content-question-item-header.opened svg {
  transition: 0.2s;
  transform: rotate(0deg);
}

.faq-content-question-item-answer {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #141315;
}

.faq-section-header-mobile-close {
  display: none;
}

.maintenance-page-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #1b003e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
}

.maintenance-page-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-transform: uppercase;
}

.maintenance-page-image {
  width: 120px;
}

.main-block-wrapper {
  width: 100%;
  min-height: 400px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.generator-section-title {
  font-weight: 1.1rem;
  font-weight: 700;
}

.generator-color-block {
  width: 44px;
  height: 44px;
  background-color: black;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.generator-image-block {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 2px;
}

.generator-image-block-selected {
  background: rgba(151, 71, 255, 0.1);
  border-radius: 8px;
}

.google-sign-in-button {
  background-color: #ffffff;
  color: #000000;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #6a5ab0;
}

.main-content-wrapper {
  min-height: calc(100vh - 76px);
  max-width: 1420px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.main-content-wrapper-centered {
  min-height: calc(100vh - 76px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.header-wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  background: #ffffff;
  border-bottom: 1px solid #e2e8f0;
}

.svg-white-color {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.sign-in-header-button {
  padding: 0px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  color: #334155;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 8px;
  height: 44px;
}

.blue-color {
  color: #9747ff;
}

.block-shadow {
  box-shadow: 0 0 2px 3px #d6dadb16;
  background-color: #ffffff;
}

.error-text {
  color: #ff4853;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
}

.dashboard-navigation {
  max-width: 280px;
  width: 100%;
  min-height: inherit;
  padding: 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.navigation-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #334155;
  padding: 8px 12px;
  margin-bottom: 8px;
  height: 36px;
}

.navigation-item:hover {
  color: #9747ff;
}

.navigation-item-active {
  display: block;
  padding: 8px 12px;
  gap: 8px;
  width: 100%;
  height: 36px;
  background: rgba(151, 71, 255, 0.1);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #9747ff;
  margin-bottom: 8px;
}

.qr-code-expire-text {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 3px 6px;
  background-color: red;
  border-radius: 5px;
  color: white;
  font-size: 0.8rem;
}

.qr-code-expired-text {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 3px 15px;
  background-color: rgb(113, 151, 161);
  border-radius: 5px;
  color: white;
  font-size: 0.8rem;
}

.select-type-block {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border: 1px solid #f1f5f9;
  background: #fff;
  cursor: pointer;
  flex-basis: calc(33.33% + 2px);
  border-collapse: collapse;
  margin: -1px;
}

.selected-type-block {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border: 1px solid #f1f5f9;
  background: rgba(151, 71, 255, 0.1);
  cursor: pointer;
  flex-basis: calc(33.33% + 2px);
  border-collapse: collapse;
  margin: -1px;
}

.header-logo-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 23px;
  margin-left: 8px;
  color: #141315;
  text-transform: capitalize;
}

.header-free-downloads-left {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.header-google-sign-up-btn {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #334155;
  height: 44px;
  column-gap: 8px;
  border: 1px solid #e2e8f0;
}

.header-max-width-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1120px;
  width: 100%;
}

.generate-qr-code-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #334155;
}

.select-type-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
}

.selected-type-title {
  color: #141315;
  font-size: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: -0.38px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.form-input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  color: #334155;
  background-color: transparent !important;
  width: 100%;
  border-radius: 8px !important;
  height: 44px;
  border: 1px solid #e2e8f0 !important;
}

.form-select select {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #334155 !important;
  background-color: transparent !important;
  width: 100%;
  border-radius: 8px !important;
  height: 44px !important;
  border: 1px solid #e2e8f0 !important;
  height: 100% !important;
}

.form-select-dashboard select {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #334155 !important;
  width: 100%;
  border-radius: 8px !important;
  height: 44px !important;
  border: 1px solid transparent !important;
  height: 100% !important;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px !important;
  background-color: #f1f5f9 !important;
  line-height: 24px;
}

.form-input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #bec5cf !important;
}

.qr-code-properties-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
}

.border-line {
  border: 1px solid #f1f5f9;
  width: 100%;
  height: 1px;
}

.qr-code-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 0px;
  max-width: 364px;
  width: 100%;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 16px 120px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
}

.qr-customize-option-title {
  color: #334155;
  font-size: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: -0.38px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.qr-customize-option-label {
  color: #334155;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.qr-colors-customize-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
}

.qr-color-change-input-block {
  display: flex;
  margin-right: 8px;
  width: calc(100% - 65px);
  position: relative;
}

.qr-color-customize-pen {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.static-content-block {
  width: 100%;
}

.color-picker {
  position: absolute;
  right: 0;
  margin-right: 48px;
  margin-top: -247px;
  z-index: 10;
}

.switch-block {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  width: 55px;
}

.switch-circle {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #bec5cf;
  z-index: 2;
  border-radius: 50%;
  transition: 0.2s linear;
}

.switch-line {
  position: absolute;
  width: 40px;
  height: 8px;
  background: #f1f5f9;
  border-radius: 100px;
}

.switch-circle-active {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #f2c44c;
  z-index: 2;
  border-radius: 50%;
  left: 20px;
  transition: 0.2s linear;
}

.switch-line-active {
  position: absolute;
  width: 40px;
  height: 8px;
  background: #f2c44c;
  opacity: 0.3;
  border-radius: 100px;
}

.reset-to-default-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #9747ff;
}

.save-qr-code-block {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.save-qr-code-block-disabled {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
}

.download-qr-code-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px 8px 0px;
  gap: 8px;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background: #9747ff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-right: 8px;
}

.download-qr-code-btn:disabled {
  background-color: rgba(151, 71, 255, 0.2);
}

.please-sign-up-modal-body {
  display: flex;
  padding: 0 !important;
}

.sign-up-modal-right-side-wrapper {
  padding: 8px;
  width: 50%;
  height: 100vh;
}

.sign-up-modal-right-side-background-image {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  transform: translate3d(0, 0, 0);
  backface-visibility: visible;
}

.please-signup-girl-image {
  width: 100%;
  border-radius: 24px;
  border: 1px solid #e2e8f0;
  background: linear-gradient(180deg, #390084 0%, #9747ff 100%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  mix-blend-mode: hard-light;
  transform: translate3d(0, 0, 0);
  backface-visibility: visible;
}

.please-signup-girl-image img {
  width: 60%;
  user-select: none;
}

.please-signup-text-block {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
}

.please-signup-small-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #334155;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.please-signup-big-dark-text {
  color: #334155;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: -0.56px;
}

.header-signup-white-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-top: 8px;
}

.please-signup-big-grey-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: #bec5cf;
}

.already-have-an-account-text {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin-top: 24px;
}

.already-have-an-account-text span:hover {
  color: #7f2fe7 !important;
}

.please-signup-close-btn {
  position: absolute;
  width: 44px;
  height: 44px;
  right: 0px;
  top: 0px;
  background: #9747ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top-right-radius: 8px;
}

.static-types-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-collapse: collapse;
  width: 100%;
}

.header-unauthorized-desktop {
  display: flex;
  gap: 16px;
}

.header-unauthorized-mobile {
  display: none;
}

.header-menu-mobile {
  display: none;
}

.header-mobile-menu-signin-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  height: 44px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.header-menu-mobile-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 16px;
}

.qr-color-picker-block {
  display: flex;
  justify-content: space-between;
}

.create-qr-code-btn-mobile {
  display: none;
}

.static-types-wrapper-mobile {
  display: none;
}

.qr-code-block-back-btn {
  display: none;
}

.generate-btn-under-qr {
  display: flex;
}

.header-free-downloads-left {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #334155;
  margin-right: 16px;
}

.header-upgrade-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 106.75px;
  height: 36px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #334155;
  margin-right: 16px;
}

.header-vertical-line {
  height: 36px;
  width: 1px;
  border: 1px solid #f1f5f9;
  margin-right: 24px;
  margin-left: 8px;
}

.header-notification-icon {
  fill: #334155;
  margin-right: 19px;
}

.dashboard-content-wrapper {
  width: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  background-color: #f1f5f9;
}

.navigation-create-new-code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  height: 36px;
  background: #9747ff;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.overview-welcome-back-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.overview-welcome-back-dark-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: #334155;
}

.overview-welcome-back-blue-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: #9747ff;
}

.overview-block-wrapper {
  max-width: 1200px;
  width: 100%;
  padding: 60px;
}

.border-line-static-types {
  display: none;
}

.fixed-button-generate {
  display: none;
}

.header-dropdown-desktop {
  display: flex !important;
}

.header-dropdown-mobile {
  display: none !important;
}

.overview-stats-block {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.overview-stats-live-scans-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  max-width: 349px;
  width: 100%;
  height: 449px;
  background: #ffffff;
  border-radius: 16px;
}

.overview-stats-activity-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  max-width: 722px;
  width: 100%;
  height: 449px;
  background: #ffffff;
  border-radius: 16px;
}

.overflow-stats-live-server-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #334155;
}

.overview-stats-live-item-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 68px 9px 11px;
  gap: 9px;
  width: 100%;
  height: 57px;
  border: 1px solid #f1f5f9;
  border-radius: 8px;
}

.overview-stats-live-item-info {
  display: flex;
  flex-direction: column;
}

.overview-stats-live-item-info-place {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #334155;
}

.overview-stats-live-item-info-time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #bec5cf;
}

.overview-active-codes-block {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.overview-section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #334155;
  display: flex;
  align-items: center;
  gap: 8px;
}

.overview-section-count {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  width: 23px;
  height: 23px;
  background: rgba(151, 71, 255, 0.1);
  border-radius: 50%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #9747ff;
}

.overview-next-previous-block {
  display: flex;
  gap: 8px;
}

.overview-arrow-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 6px;
  gap: 8px;
  width: 36px;
  height: 36px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
}

.overview-active-block-qrs-list {
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
  gap: 10px;
}

.overview-block-qr {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 33%;
  height: 172px;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
}

.overview-block-qr-image-block {
  width: 120px;
  height: 120px;
}

.overview-qr-code-unique-users {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #334155;
}

.overview-qr-code-total-scans {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #334155;
}

.overview-qr-code-total-scans-count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #334155;
}

.overview-qr-code-link-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px;
  gap: 6.32px;
  width: 100%;
  height: 36px;
  border: 0.789389px solid #e2e8f0;
  border-radius: 5.54px;
  margin-top: 2px;
}

.overview-qr-code-link-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11.0514px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #9747ff;
}

.overview-qr-code-more-options {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.overview-stats-live-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  width: 100%;
  padding: 24px;
  padding-top: 0;
}

.overview-stats-live-list-wrapper::-webkit-scrollbar {
  width: 5px;
}

.overview-stats-live-list-wrapper::-webkit-scrollbar-thumb {
  background-color: #e2e8f0;
}

.upgrade-plan-block-wrapper {
  background-color: #9747ff;
  display: flex;
  max-width: 1120px;
  width: 100%;
  height: 371px;
  background: #9747ff;
  border-radius: 16px;
  overflow: hidden;
}

.upgrade-plan-image-section {
  width: 50%;
  height: 100%;
  position: relative;
}

.upgrade-plan-image {
  height: 100%;
  width: 100%;
}

.upgrade-plan-button {
  padding: 8px 23px;
  height: 41px;
  background: rgba(255, 255, 255, 0.15);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(41px);
  border-radius: 165px;
  position: absolute;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 19.83px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.first-upgrade-plan-button {
  top: 85px;
  right: 60px;
}

.second-upgrade-plan-button {
  top: 210px;
  right: -40px;
}

.third-upgrade-plan-button {
  bottom: 30px;
  right: 100px;
}

.upgrade-plan-text-section {
  display: flex;
  flex-direction: column;
  padding: 65px 80px;
  position: relative;
  width: 50%;
}

.upgrade-plan-text-circle {
  position: absolute;
  top: 0;
  right: 0;
}

.upgrade-plan-text-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 33px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #ffffff;
  max-width: 317px;
}

.upgrade-plan-text-header-blue {
  color: #ffffff80;
}

.upgrade-plan-text-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 16px;
}

.upgrade-plan-text-button {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  gap: 8px;
  width: 288px;
  height: 44px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #334155;
}

.overview-active-codes-header {
  display: flex;
  justify-content: space-between;
}

.overview-block-qr-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user-code-creation-container {
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 1120px;
  width: 100%;
  gap: 16px;
}

.code-creation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.code-creation-header-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.code-creation-header-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 6px;
  height: 36px;
  background: #9747ff;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.code-creation-header-button:disabled {
  background-color: rgba(151, 71, 255, 0.2);
}

.code-creation-header-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #334155;
  display: flex;
  align-items: center;
  gap: 24px;
}

.code-creation-qr-type-block {
  display: flex;
  flex-direction: column;
}

.code-creation-select-kind {
  display: flex;
  border: 1px solid #f1f5f9;
  width: 220px;
  border-radius: 8px;
  background-color: #f1f5f9;
  padding: 1px;
  overflow: hidden;
}

.code-creation-select-kind-button-active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 6px;
  height: 28px;
  background: #fff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #9747ff;
  width: 110px;
  border-radius: 8px;
}

.code-creation-select-kind-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 6px;
  height: 28px;
  background: transparent;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  color: #334155;
  width: 110px;
}

.code-creation-select-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 34px;
  gap: 16px;
}

.code-creation-content-section {
  width: 100%;
}

.code-creation-preview-section {
  width: 30%;
}

.user-code-creation-content {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 40px;
  width: 100%;
  height: fit-content;
  min-height: 500px;
}

.user-code-creation-preview {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 32px 16px;
  height: fit-content;
  max-width: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.preview-section-phone-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.preview-section-phone-wrapper {
  display: flex;
  position: relative;
  height: 433px;
  min-width: 220px;
  max-width: 220px;
  width: 100%;
}

.preview-section-content {
  position: absolute;
  top: 5px;
  left: 7px;
  width: 207px;
  height: 423px;
  overflow-y: auto;
  border-radius: 25px;
}

.preview-section-content * {
  pointer-events: none;
  user-select: none;
}

.preview-section-content::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
  background-color: black;
}

.preview-section-content::-webkit-scrollbar-thumb {
  background-color: #e2e8f0;
}

.only-for-dynamics-qr-codes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #334155;
  padding: 20px;
}

.code-creation-type {
  width: 48%;
  padding: 8px 16px;
  border: 1px solid #9747ff;
  border-radius: 8px;
  color: #334155;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  cursor: pointer;
}

.code-creation-type:hover {
  width: 48%;
  padding: 8px 16px;
  border: 1px solid #9747ff;
  background-color: #9747ff;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.code-creation-type-active {
  width: 48%;
  padding: 8px 16px;
  border: 1px solid #9747ff;
  background-color: #9747ff;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.code-creation-type-desc {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-weight: 400;
}

.overview-qr-code-options-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.auth-button {
  background-color: #9747ff !important;
}

.app-form-wrapper {
  display: flex;
  flex-direction: column;
}

.description-text {
  font-size: 14px;
  color: #334155;
  font-weight: 500;
}

.app-form-colors-block {
  display: flex;
  gap: 10px;
}

.app-form-color-item-wrapper {
  width: 75px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f5f9;
  border: 1px solid #d6dde7;
  cursor: pointer;
}

.app-form-color-item {
  width: 55px;
  height: 30px;
}

.app-form-custom-background {
  display: flex;
}

.app-form-main-information {
  display: flex;
  flex-direction: column;
}

.app-form-logo-change-block {
  display: flex;
  align-items: center;
}

.app-form-logo {
  width: 120px;
  height: 120px;
  border-radius: 15px;
  object-fit: cover;
}

.app-form-logo-upload-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 6px;
  height: 36px;
  background: #9747ff;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  margin-left: 15px;
}

.app-form-selected-color {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.social-form-page-color {
  display: flex;
  flex-direction: column;
}

.social-form-page-information {
  display: flex;
  flex-direction: column;
}

.social-media-socials-block {
  display: flex;
  flex-direction: column;
}

.social-media-links {
  display: flex;
  flex-direction: column;
}

.social-media-link-item {
  display: flex;
  flex-direction: column;
}

.social-media-item-remove {
  cursor: pointer;
}

.social-media-more-socials-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 12px;
}

.social-media-more-social-item {
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(242, 234, 234);
  cursor: pointer;
}

.images-form-wrapper {
  display: flex;
  flex-direction: column;
}

.images-form-list-uploaded-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.images-form-list-uploaded-item {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.images-form-list-uploaded-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 0;
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
}

.images-form-list-uploaded-item-remove {
  cursor: pointer;
}

.images-form-list-uploaded-item-name {
  font-size: 14px;
  font-weight: 500;
  color: #334155;
  margin-left: 10px;
}

.video-frame-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.profile-form-wrapper {
  display: flex;
  flex-direction: column;
}

.event-form-wrapper {
  display: flex;
  flex-direction: column;
}

.event-form-services-list {
  display: flex;
  gap: 20px;
}

.mp3-form-wrapper {
  display: flex;
  flex-direction: column;
}

.mp3-form-audio-block {
  border: 1px solid #f1f5f9 !important;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f5f9;
}

.overview-block-qr-image {
  transform: scale(1.1);
}

.overview-activity-block {
  display: flex;
  width: 100%;
  height: 100%;
}

.overview-activity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.overview-activity-filters {
  display: flex;
  background: #f1f5f9;
  padding: 2px;
  border-radius: 100px;
}

.overview-activity-filter-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #334155;
  cursor: pointer;
}

.overview-activity-filter-item-active {
  background-color: #ffffff;
  color: #9747ff;
}

.overview-activity-filters-dropdown button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #f1f5f9;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #9747ff;
  box-shadow: none;
}

.overview-activity-filters-dropdown button:hover {
  background: #ffffff;
}

.overview-activity-filters-dropdown button:active {
  box-shadow: none;
  outline: none;
}

.header-signup-blue-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: #ffffff50;
  margin-top: 8px;
}

.password-input-wrapper {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.password-eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 4;
}

.header-sign-up-btn {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #9747ff;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.header-sign-up-btn:disabled {
  background-color: rgba(151, 71, 255, 0.2);
}

.button-start-opacity {
  opacity: 0.2;
}

.creation-dashboard-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  width: 100%;
}

.creation-content-block {
  width: 70%;
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.creation-qr-code-block {
  width: 30%;
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  height: fit-content;
}

.creation-content-title {
  color: #334155;
  font-size: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: -0.38px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.creation-step-container {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #9747ff;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.creation-content-first-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.creation-content-show-codes {
  color: #9747ff;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.28px;
  cursor: pointer;
}

.creation-content-select-type {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #f1f5f9;
}

.select-type-text-block {
  display: flex;
  flex-direction: column;
}

.select-type-name-text {
  color: #334155;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.select-type-description-text {
  color: #334155;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.selected-type-icon-block {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #9747ff;
  border: 1px solid #9747ff;
}

.select-type-icon-block {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
}

.creation-content-block-wrapper {
  border-top: 1px solid #f1f5f9;
  padding-top: 24px;
  border-bottom: 1px solid #f1f5f9;
  padding-bottom: 24px;
}

.content-creation-form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.content-creation-input-wrapper {
  display: flex;
  flex-direction: column;
}

.content-creation-input-label {
  margin-bottom: 4px;
  color: #141315;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-input-dashboard {
  display: flex;
  height: 44px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px !important;
  background-color: #f1f5f9 !important;
  border: none;
  color: #334155;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
}

.form-input-dashboard::placeholder {
  color: #bec5cf;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.url-input-wrapper-block:focus-within {
  border: 1px solid #9747ff;
}

.qr-option-customize-expand-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  cursor: pointer;
}

.qr-option-customize-expand-button-with-background {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: #f1f5f9;
}

.qr-customize-option-header-actions {
  display: flex;
  align-items: center;
  gap: 24px;
}

.qr-customize-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.qr-code-customize-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  background: #fff;
  gap: 24px;
}

.qr-colors-customize-content-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.preview-example-qr-wrapper {
  padding: 44px 0;
}

.preview-upgrade-block {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #f1f5f9;
  border-radius: 8px;
}

.preview-upgrade-premium-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preview-upgrade-icon-wrapper {
  display: flex;
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
}

.preview-upgrade-header-title {
  color: #334155;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.28px;
  width: 174px;
}

.preview-upgrade-premium-text {
  display: flex;
  align-items: center;
  gap: 8px;
}

.preview-upgrade-list-options {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.preview-upgrade-option {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #334155;
  text-align: center;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.preview-upgrade-button {
  display: flex;
  height: 44px;
  padding: 2px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #f1f5f9;
  width: 100%;
  color: #334155;
  text-align: center;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.download-options-modal-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.download-options-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.download-option-header-text {
  color: #334155;
  font-size: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
}

.download-option-header-close {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #f1f5f9;
  cursor: pointer;
}

.download-options-block-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.download-option-block {
  padding: 25px 12px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.download-options-blok-label {
  color: #334155;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.creation-content-show-codes-mobile {
  display: none;
}

.creation-mobile-generate-block {
  display: none;
}

.qr-code-customization-frames-margin {
  margin-bottom: 0px;
}

.creation-qr-code-block-mobile {
  display: none;
}

.overview-mobile-generate-button {
  display: none;
}

.preview-section-phone {
  width: 290px;
}

.dropdown-label-wrapper-url-type {
  display: flex;
  align-items: center;
  color: #334155;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: transparent;
  padding: 0 16px;
  border-right: 1px solid #e2e8f0 !important;
}

.url-input-wrapper-block {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  position: relative;
  background-color: #f1f5f9;
  padding: 8px 0;
}

.border-radius-left-none {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.url-input-wrapper-block-red-border {
  border: 1px solid #ff4853 !important;
}

.icon-input-validation-passed {
  position: absolute;
  right: 15px;
}

.preview-link-text {
  display: flex;
  min-height: 44px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  color: #9747ff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  word-break: break-all;
}

.please-signup-big-purple-text {
  color: #9747ff;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: -0.56px;
}

.please-signup-form-input {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 16px !important;
  align-items: center;
  gap: 8px;
  border-radius: 8px !important;
  background-color: #f1f5f9 !important;
  border: none;
  color: #334155;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}

.please-signup-form-input:focus {
  border: 1px solid #9747ff !important;
}

.please-signup-form-input::placeholder {
  color: #bec5cf;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.please-signup-password-eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 4;
}

.please-signup-or-line {
  display: flex;
  align-items: center;
  height: 16px;
  width: 100%;
  margin: 24px 0;
  position: relative;
}

.please-signup-line {
  height: 1px;
  width: 100%;
  border-top: 1px solid #f2f2f2;
}

.please-signup-or-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  width: 50px;
  background-color: white;
  text-align: center;
}

.please-sign-up-additional-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.please-sign-up-remember {
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.please-sign-up-forgot-password {
  color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.please-sign-up-forgot-password:hover {
  color: #7f2fe7;
}

.checkbox-remember-me {
  border-width: 2px;
  border-radius: 3px;
  border-color: #00000019 !important;
}

.user-header-input-wrapper {
  display: flex;
  max-width: 480px;
  width: 100%;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.2s;
}

.user-header-input-wrapper:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.05);
  transition: 0.2s;
}

.user-header-input {
  border: none;
  background-color: transparent !important;
  height: 100%;
  width: 100%;
  padding-left: 5px !important;
}

.user-header-input::placeholder {
  color: #8f7da5;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.user-header-input-diffent-placeholder::placeholder {
  color: #bec5cf;
}

.user-header-input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.user-dashboard-wrapper {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 60px);
}

.user-dashboard-navigation {
  max-width: 240px;
  width: 100%;
  display: flex;
  padding: 16px 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #1b003e;
  height: 100%;
}

.user-dashboard-navigation-collapsed {
  max-width: 64px;
  width: 100%;
  display: flex;
  padding: 16px 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #1b003e;
  height: 100%;
}

.navigation-items-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navigation-item-block {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
  height: 44px;
  padding: 16px 12px;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  border-radius: 8px;
}

.navigation-item-block:hover {
  background-color: #311951;
  transition: 0.2s;
}

.navigation-item-block-active {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
  height: 44px;
  padding: 16px 12px;
  align-items: center;
  background-color: #e0c8ff;
  border-radius: 8px;
  cursor: pointer;
}

.nav-item-collapsed {
  padding: 0;
  justify-content: center;
}

.navigation-item-block-active .navigation-item-text {
  color: #1b003e;
}

.navigation-item-text {
  color: #e0c8ff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.navigation-footer-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nav-footer-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  gap: 8px;
}

.nav-footer-user-personal {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-footer-user-plan {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #f5edff;
  color: #9747ff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  width: 80px;
}

.footer-user-personal-image {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  object-fit: cover;
}

.footer-user-personal-name {
  color: #334155;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  white-space: normal;
}

.nav-footer-line {
  height: 1px;
  width: 100%;
  border-top: 1px solid #e2e8f0;
  margin-bottom: 16px;
}

.nav-footer-upgrade {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f2c44c;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin-top: 16px;
}

.nav-footer-trial-ends {
  color: #7d8898;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.user-dashboard-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 68px);
  overflow-y: auto;
  border-radius: 16px;
}

.user-dashboard-content-new-home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: auto;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
}

.dashboard-content-trial-block {
  display: flex;
  width: 100%;
  height: 72px;
  padding: 16px 24px 16px 16px;
  justify-content: space-between;
  align-items: center;
  background: var(--New-Gradient,
      radial-gradient(125.73% 1111.42% at 0% 0%, #eb72a5 0%, #9747ff 100%));
}

.dashboard-content-trial-end-block {
  display: flex;
  width: 100%;
  height: 72px;
  padding: 16px 24px 16px 16px;
  justify-content: space-between;
  align-items: center;
  background: #ef1e11;
}

.trial-block-left-side {
  display: flex;
  align-items: center;
  gap: 16px;
}

.trial-end-block-left-side {
  display: flex;
  align-items: center;
  gap: 16px;
}

.trial-block-right-side {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
}

.trial-block-left-text {
  color: #fff;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.trial-block-left-days {
  display: flex;
  height: 32px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.trial-block-right-upgrade {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  border-radius: 8px;
  background: #ffffff;
}

.trial-end-block-right-upgrade {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  border-radius: 8px;
  background: #fff;
}

.dashboard-content-create-block {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.dashboard-content-create-block-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  padding: 16px;
  gap: 16px;
  border-bottom: 1px solid #e2e8f0;
}

.dashboard-content-create-select-type {
  display: flex;
  width: 180px;
  height: 44px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  border-radius: 8px;
}

.gradient-background-wrapper {
  width: fit-content;
  background: linear-gradient(to right, #56ccf2, #f2c34c, #ff47ed, #9747ff);
  padding: 1px;
  border-radius: 8px;
  border: 1px solid #fff;
}

.gradient-background-default-wrapper {
  width: fit-content;
  padding: 1px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.dashboard-content-create-select-type-dynamic {
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(to right,
      #56ccf2,
      #f2c34c,
      #ff47ed,
      #9747ff);
}

.dashboard-content-create-select-type-dynamic:first-child {
  border-image-slice: 1;
}

.dashboard-content-create-selected-type {
  color: #334155;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.dashboard-content-create-input-block {
  display: flex;
  width: calc(100% - 360px);
  height: 48px;
  padding: 8px 16px;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  flex: 1 0 0;
}

.dashboard-content-create-input-block:focus-within {
  border: 1px solid #9747ff;
}

.content-create-name-input {
  background-color: transparent;
  border: 1px solid transparent !important;
  outline: none;
  width: 100%;
}

.content-create-name-input:focus {
  box-shadow: none !important;
  border: 1px solid transparent !important;
}

.content-create-name-input::placeholder {
  color: #bec5cf;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.dashboard-content-codes-list {
  display: flex;
  width: 100%;
  padding: 0 40px 40px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.content-codes-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content-codes-list-title {
  color: #141315;
  font-family: "Inter";
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
}

.content-codes-filters-block {
  display: flex;
  align-items: center;
  gap: 24px;
}

.content-codes-filters-select-all {
  color: #9747ff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.content-codes-filters-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #7d8898;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.convert-code-modal-body {
  padding: 40px;
  display: flex;
  flex-direction: column;
  min-height: 600px;
  height: 100%;
}

.convert-code-icon-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.convert-code-qr-image-gradient-wrapper {
  width: 122px;
  height: 122px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #56ccf2, #f2c34c, #ff47ed, #9747ff);
  z-index: 2;
  border-radius: 8px;
  overflow: hidden;
}

.home-convert-code-qr-image-gradient-wrapper {
  width: 122px;
  height: 122px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #56ccf2, #f2c34c, #ff47ed, #9747ff);
  z-index: 2;
  border-radius: 8px;
  position: relative;
  padding: 1px;
}

.convert-code-qr-image-wrapper {
  display: flex;
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 8px;
}

.convert-code-qr-image {
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.convert-code-graient {
  width: 163px;
  height: 163px;
  transform: rotate(45deg);
  flex-shrink: 0;
  opacity: 0.20000000298023224;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%),
    radial-gradient(785.73% 141.42% at -0% 0%,
      #56ccf2 0%,
      #f2c34c 33.85%,
      #ff47ed 65.1%,
      #9747ff 100%);
  position: absolute;
  top: 35px;
  left: 40px;
}

.home-convert-code-graient {
  width: 163px;
  height: 163px;
  transform: rotate(45deg);
  flex-shrink: 0;
  opacity: 0.20000000298023224;
  background: linear-gradient(200deg, rgba(255, 255, 255, 0) 0%, #fff 100%),
    radial-gradient(785.73% 141.42% at -0% 0%,
      #56ccf2 0%,
      #f2c34c 33.85%,
      #ff47ed 65.1%,
      #9747ff 100%);
  position: absolute;
  top: 35px;
  left: -75px;
}

.convert-code-qr-text {
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.36px;
  margin-top: 24px;
  z-index: 2;
}

.convert-code-qr-text-grey {
  color: #bec5cf;
  font-family: "Inter";
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.36px;
}

.convert-code-qr-text-gradient {
  background: radial-gradient(785.73% 141.42% at -0% 0%,
      #56ccf2 0%,
      #d551e9 33.85%,
      #ff47ed 65.1%,
      #9747ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.36px;
}

.convert-code-options-block {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  margin-top: 30px;
}

.convert-code-button {
  display: flex;
  height: 60px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #9747ff;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;
}

.convert-code-skip {
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
}

.content-codes-list-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  width: 100%;
  gap: 16px;
  background: #ffffff;
}

.content-codes-item-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 0;
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.content-codes-item-checkbox {
  width: 24px !important;
  height: 24px !important;
  border-radius: 4px !important;
  border-color: #e2e8f0 !important;
  border-width: 2px !important;
}

.content-codes-item-main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.code-main-info-name {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.code-main-name-text {
  color: #334155;
  text-align: start;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 300px);
  padding-right: 10px;
  -webkit-mask-image: linear-gradient(90deg, #000 90%, transparent);
}

.code-main-name-date {
  color: #334155;
  text-align: start;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.code-main-name-type {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #e2e8f0;
  color: #9747ff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background-color: white;
}

.content-codes-link-block {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  height: 40px;
  gap: 8px;
}

.content-codes-link-text {
  color: #334155;
  text-align: start;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.content-codes-link-text-redirect {
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  max-width: 300px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-codes-link-text-redirect:hover {
  color: #9747ff;
}

.content-convert-to-dynamic-wrraper {
  display: flex;
  height: 40px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 8px;
}

.content-convert-to-dynamic-button {
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  height: 100%;
  min-width: 175px;
  border-radius: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #9747ff;
}

.dropdown-font-wrapper div {
  width: 100%;
}

.dropdown-font-wrapper div button {
  width: 100%;
}

.link-filter-block {
  display: none;
}

.close-trial-image-mobile {
  display: none;
}

.user-dashboard-qr-code-image {
  width: 120px;
  min-height: 120px;
}

.code-main-name-type-gradient {
  background: #e0c8ff;
  padding: 1px;
  border-radius: 12px;
}

.content-dynamic-details-button {
  border-radius: 8px;
  background: #f1f5f9;
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.content-dynamic-details-button:disabled {
  opacity: 0.3;
}

.content-code-dynamic-base-info {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
}

.content-code-dynamic-base-info-details {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
}

.details-code-information {
  display: flex;
  align-items: center;
  gap: 24px;
}

.content-codes-link-block-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.code-dynamic-base-links-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 24px;
  border-right: 1px solid #f1f5f9;
}

.code-dynamic-total-scans {
  display: flex;
  flex-direction: column;
}

.code-dynamic-total-scans-title {
  color: #334155;
  text-align: start;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
}

.code-dynamic-total-scans-count {
  color: #334155;
  text-align: start;
  font-family: "Inter";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.dropdown-item-dashboard {
  color: #7d8898 !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.content-codes-mobile-type-filter {
  display: none;
}

.user-dashboard-add-mobile-btn {
  display: none;
}

.upgrade-plan-title {
  color: #334155;
  font-family: "Inter";
  font-size: 33px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -0.66px;
  margin-bottom: 80px;
}

.upgrade-plan-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;
  background-color: white;
}

.main-content-wrapper .upgrade-plan-wrapper {
  position: absolute;
  top: 76px;
  left: 0;
  min-height: calc(100vh - 76px);
}

.upgrade-plan-cards-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  max-width: 1264px;
}

.upgrade-plan-card-block {
  display: flex;
  height: 600px;
  max-width: 380px;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 14px;
  border: 1px solid #e2e8f0;
}

.upgrade-plan-card-block-best {
  display: flex;
  height: 600px;
  max-width: 380px;
  padding: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  border-top: 1px solid white;
  background-color: #f5edff;
  position: relative;
}

.upgrade-plan-name {
  color: #141315;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.02em;
}

.upgrade-plan-price-block {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 0px;
}

.upgrade-plan-cost {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
}

.upgrade-plan-period {
  color: #141315;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
}

.upgrade-plan-options-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.upgrade-plan-active-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.upgrade-plan-active-option-text {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.upgrade-plan-new-card-block-best .upgrade-plan-active-option-text {
  font-weight: 600;
}

.upgrade-plan-inactive-option-text {
  color: #7d8898;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-decoration: line-through;
}

.upgrade-plan-most-popular {
  display: flex;
  width: 100%;
  height: 34px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #9747ff;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border-radius: 14px 14px 0px 0px;
  background: #f5edff;
  position: absolute;
  top: -35px;
  left: 0;
}

.choose-plan-button {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #9747ff;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.upgrade-success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
}

.upgrade-success-text {
  max-width: 360px;
  width: 100%;
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: -0.56px;
  margin-top: 24px;
  margin-bottom: 40px;
}

.upgrade-success-button {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  width: 320px;
  height: 60px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #9747ff;
}

.upgrade-error-underline-text {
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin-top: 40px;
}

.upgrade-error-try-again {
  color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
}

.mobile-upgrade-text {
  display: none;
}

.desktop-upgrade-text {
  display: flex;
}

.nav-footer-signout {
  color: #fff;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #9747ff;
  margin-top: 16px;
  height: 40px;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.cards-block-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkout-card-img {
  width: 32px;
  height: 32px;
}

.checkout-form {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.checkout-page {
  background: transparent;
  width: 100%;
  max-width: 1420px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  height: fit-content;
}

.checkout-page-loader {
  background: transparent;
  width: 100%;
  max-width: 1420px;
  height: fit-content;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  overflow: auto;
}

.checkout-page-loader-wrapper {
  width: 100%;
  height: calc(100vh - 70px);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  overflow-y: auto;
}

.checkout-header {
  width: 100%;
  border-radius: 14px;
  background: #fff;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-header span {
  color: #334155;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.checkout-header svg {
  font-size: 27px;
}

.checkout-body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: fit-content;
  width: 100%;
  gap: 24px;
  max-width: 1264px;
}

.checkout-field-block {
  width: calc(100% / 3 * 2);
  background: #ffffff;
  border-radius: 16px;
  height: 100%;
  border: 1px solid var(--grey-1, #e2e8f0);
  padding: 40px;
}

.checkout-body-header {
  width: 100%;
  color: #334155;
  font-family: "Inter";
  font-size: 33px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  text-align: start;
}

.checkout-card-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}

.checkout-address-block {
  padding: 24px;
  padding-bottom: 0;
}

.checkout-address-block h2 {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
}

.checkout-address-block p {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 16px;
}

.checkout-input-block input {
  display: flex;
  width: 100%;
  height: 44px;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--grey-1, #f1f5f9);
  border: none;
}

.checkout-input-block span {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  display: block;
  margin-bottom: 4px;
}

.checkout-address-fields {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.checkout-input-block-width {
  flex: 0 0 calc(50% - 8px);
}

.payment-element-wrapper {
  padding-bottom: 24px;
}

.checkout-body-right {
  width: calc(100% / 3);
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.checkout-plan-info {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #e2e8f0;
  border-bottom: none;
  background: #fff;
}

.checkout-plan-info h4 {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.checkout-plan-price p {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.checkout-plan-total-price p {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}

.checkout-line {
  background: #f1f5f9;
  width: 100%;
  height: 1px;
}

.checkout-plan-text {
  color: var(--grey-4, #9a9ea3);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
}

.checkout-satisfaction {
  display: flex;
  height: 60px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  background: var(--light-blue, #f2f2f2);
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  border: 1px solid #e2e8f0;
  border-top: none;
}

.checkout-satisfaction svg {
  color: #19f;
  font-size: 24px;
}

.checkout-button {
  display: flex;
  height: 60px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--purple, #9747ff);
  width: 100%;
  border: none;
  color: var(--white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.checkout-button:disabled {
  background: var(--purple, #9747ff21);
}

.checkout-bottom-text {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-bottom-text svg {
  font-size: 18px;
  color: #7d8898;
}

.checkout-bottom-label {
  color: var(--off-black, #7d8898);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.checkout-bottom-label-wrapper {
  padding: 24px;
}

.dashboard-create-container {
  width: 100%;
  display: flex;
  background-color: white;
  padding: 24px;
  height: 100%;
}

.dashboard-content-container-wrapper {
  display: flex;
}

.dashboard-content-container-left-side {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dashboard-content-container-right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 340px;
  background-color: white;
  padding: 24px;
  gap: 16px;
  border-left: 1px solid #e2e8f0;
}

.dashboard-content-qr-code-block {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}

.dashboard-content-qr-code-example {
  padding: 16px;
  width: 160px;
  height: 160px;
}

.dashboard-content-creation-preview-title {
  color: #334155;
  font-family: "Inter";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
  width: 100%;
  margin-bottom: 16px;
}

.dashboard-content-edit-preview-button {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--purple, #9747ff);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin-top: 16px;
}

.dashboard-content-creation-actions {
  display: flex;
  border-top: 1px solid #e2e8f0;
  min-height: 60px;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  background-color: white;
}

.content-creation-actions-cancel {
  width: 50%;
  height: 100%;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey-2, #e2e8f0);
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.content-creation-actions-generate {
  width: 50%;
  height: 100%;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--purple, #9747ff);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.content-creation-actions-generate:disabled {
  background-color: var(--purple, rgba(151, 71, 255, 0.2));
}

.mobile-search-wrapper {
  display: none;
}

.code-details-header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  background-color: white;
  padding: 40px 0 25px 0;
}

.details-header-actions-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.details-header-back-btn {
  display: flex;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--grey-2, #e2e8f0);
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.details-header-code-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.deatils-header-download-btn {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--purple, #9747ff);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.details-header-edit-btn {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--grey-1, #f1f5f9);
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.details-code-item-block {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  border-radius: 14px;
  background: #fff;
  padding: 0px 48px 0 25px;
}

.details-qr-code-image {
  width: 160px;
}

.details-statistics-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
}

.details-statistics-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top: 1px solid var(--grey-2, #e2e8f0);
  height: 54px;
}

.details-statistics-title {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.details-period-filter-list {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  padding: 2px;
}

.details-period-filter-item-active {
  display: flex;
  height: 28px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #f5edff;
  color: #9747ff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 100px;
}

.details-period-filter-item {
  display: flex;
  height: 28px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  color: #334155;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background: transparent;
  cursor: pointer;
  width: 100px;
}

.details-operating-system-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.details-scans-table {
  padding: 24px;
  background-color: white;
  border-radius: 14px;
  width: 100%;
}

.scans-table-name {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
}

.scans-table-header {
  color: var(--off-black, #7d8898);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  text-align: start;
  padding-bottom: 16px;
}

.scans-table-cell-title {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 250px;
}

.scans-table-cell-progress-block {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 8px;
  width: 100%;
}

.scans-table-cell-progress-count {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  width: 25px;
}

.scans-table-cell-persentage {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  width: 40px;
}

.scans-table {
  margin-top: 24px;
  width: 100%;
}

.scans-table-cell-progress-block div {
  width: 100%;
}

.scans-table-cell-progress-block div div div {
  background-color: #9747ff;
}

.scans-table-row {
  height: 36px;
}

.details-statistics-graph {
  background-color: #fff;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 14px;
}

.details-statistics-graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.overview-activity-block canvas {
  height: 300px !important;
  width: 100% !important;
}

.uplaod-block-wrapper {
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 14px;
  border: 1px dashed #bec5cf;
  width: 100%;
  height: 157px;
}

.uplaod-block-button {
  display: flex;
  min-width: 264px;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #f6edff;
  color: #8229ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  transition: 0.3s;
}

.uplaod-block-button:hover {
  background: #E0C8FF;
  transition: 0.3s;
}

.upload-block-limit-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #7d8898;
}

.dynamic-form-underline {
  border-top: 1px solid #e2e8f0;
  height: 1px;
  width: 100%;
  box-sizing: content-box;
}

.form-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.form-checkbox-input {
  border-color: #9747ff !important;
  border-width: 1px !important;
  border-radius: 4px !important;
  width: 18px;
  height: 18px;
}

.video-dynamic-code-extra-text {
  color: var(--off-black, #7d8898);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.video-dynamic-checkbox-list {
  display: flex;
  align-items: center;
  gap: 40px;
}

.preparing-payment-information-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  gap: 16px;
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.stripe-loader {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.stripe-loader circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

.edit-design-icons-block div {
  width: 60px;
  height: 60px;
}

.code-dynamic-total-scans-wrapper {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e2e8f0;
  padding: 4px 0;
  padding-right: 24px;
  height: 60px;
}

.dashboard-code-buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.graph-no-data {
  color: var(--off-black, #7d8898);
  text-align: start;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  padding: 30px 0 20px 0;
}

.graph-activity-no-data {
  color: var(--off-black, #7d8898);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
  padding: 50px 0 40px 0;
}

.uplaod-block-button-gray {
  display: flex;
  width: 264px;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #f6edff;
  color: #8229ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  transition: 0.3s;
  min-height: 44px;
}

.uplaod-block-button-gray:hover {
  background: #e0c8ff;
  transition: 0.3s;
}

.preview-navigation-block {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  background-color: transparent;
  padding: 0 15px;
  gap: 13px;
  z-index: 3;
}

.preview-navigation-time {
  color: var(--label-color-light-primary, #000);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.preview-navigation-black-camera {
  background-color: #000;
  width: 60px;
  height: 16px;
  border-radius: 25px;
  margin-left: 23px;
}

.preview-navigation-icons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.my-dropdown-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.my-dropdown-items {
  position: absolute;
  width: fit-content;
  min-width: 100%;
  bottom: 0;
  background-color: white;
  transform: translateY(calc(100% + 5px));
  border-radius: 8px;
  z-index: 10;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border: 1px solid rgb(229 231 235 / 1);
  cursor: pointer;
}

.my-dropdown-from-left {
  left: 0;
}

.my-dropdown-from-right {
  right: 0;
}

.my-dropdown-item {
  padding: 8px 16px;
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.my-dropdown-item:hover {
  background-color: rgb(243 244 246 / 1);
}

.edit-design-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.edit-design-acbk-block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-design-back-text {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.edit-design-clear-btn {
  color: var(--purple, #9747ff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}

.edit-design-qr-code-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-design-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
}

.design-customize-colors {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.design-customize-section-title {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.edit-design-underline {
  height: 1px;
  border-bottom: 1px solid #e2e8f0;
  width: 100%;
}

.edit-design-social-icons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.details-code-base-information {
  display: flex;
  align-items: center;
  gap: 24px;
  width: calc(100% - 400px);
}

.mobile-creation-flow-wrapper {
  display: none;
}

.creation-link-input {
  outline: none;
  background-color: transparent !important;
}

.creation-link-input:focus {
  border: 1px solid transparent !important;
}

.url-input-wrapper-block-verified {
  background-color: #f5edff;
}

.creation-step-container-default {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  color: #334155;
  text-align: center;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.creation-step-container-mobile {
  display: none;
}

.qr-code-result-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: fit-content;
  color: #1f2123;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.201px;
  position: relative;
  word-break: break-all;
}

.default-select-block select {
  border: 1px solid transparent;
  background-color: #f1f5f9;
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.default-select-block select:focus {
  border: 1px solid #9747ff;
  outline: none;
  box-shadow: none;
}

.default-select-option {
  padding: 8px 16px;
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 40px;
}

.bootstrap-default-dropdown {
  border: 1px solid transparent;
  background-color: #f1f5f9 !important;
  color: var(--black, #334155) !important;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  outline: none !important;
  text-align: start !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bootstrap-default-dropdown:hover {
  border: 1px solid transparent !important;
}

.bootstrap-default-dropdown:focus,
.bootstrap-default-dropdown:focus-visible {
  border: 1px solid #9747ff !important;
  outline: none !important;
  box-shadow: none !important;
}

.bootstrap-default-dropdown::after {
  content: unset;
}

.bootstrap-default-item {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.bootstrap-default-item:hover,
.bootstrap-default-item:active {
  background-color: #f1f5f9 !important;
  color: var(--black, #334155) !important;
}

.dropdown-label-wrapper-url-type,
.dropdown-label-wrapper-url-type:focus,
.dropdown-label-wrapper-url-type:active,
.dropdown-label-wrapper-url-type:focus-visible,
.dropdown-label-wrapper-url-type:target {
  border: none !important;
  border-right: 1px solid #e2e8f0 !important;
  background-color: transparent !important;
  color: var(--black, #334155) !important;
  box-shadow: none !important;
}

.dropdown-label-wrapper-url-type::after {
  content: unset;
}

.bootstrap-transparent-dropdown {
  background-color: transparent !important;
}

.bootstrap-transparent-dropdown,
.bootstrap-transparent-dropdown:hover,
.bootstrap-transparent-dropdown:focus,
.bootstrap-transparent-dropdown:focus-within,
.bootstrap-transparent-dropdown:focus-visible {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.bootstrap-transparent-dropdown {
  padding: 0px !important;
}

.bootstrap-bold-item {
  color: #334155 !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.dashboard-content-create-new-title {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.user-dashboard-add-desktop-btn {
  display: flex;
  width: 60px;
  height: 60px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #9747ff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
  position: absolute;
  bottom: 50px;
  right: 20px;
  cursor: pointer;
}

.content-dynamic-view-dynamic-button {
  display: flex;
  width: 167px;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--purple, #9747ff);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.content-dynamic-view-dynamic-button:disabled {
  opacity: 0.23;
}

.code-main-type-icon-img {
  width: 12px;
  height: 12px;
  filter: invert(27%) sepia(100%) saturate(2199%) hue-rotate(251deg) brightness(103%) contrast(101%);
}

.dashboard-code-row-with-gap {
  display: flex;
  align-items: center;
  gap: 24px;
}

.dashboard-code-row-with-gap:first-child {
  width: 100%;
}

.half-width-header {
  width: calc(50% - 180px);
}

.w-50 {
  width: 70%;
}

.user-header-logo-block-width {
  display: flex;
  align-items: center;
  min-width: 180px;
}

.global-search-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.user-global-search-input {
  display: flex;
  width: 480px;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f1f5f9;
  border: 1px solid transparent;
}

.user-global-search-input:focus-within {
  border: 2px solid #e0c8ff;
}

.user-global-search-container {
  padding: 16px;
  padding-bottom: 0px;
  background-color: white;
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 14px 14px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  z-index: 10;
  max-width: 512px;
  overflow: hidden;
}

.global-search-no-result-text {
  color: var(--off-black, #7d8898);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-search-codes-list {
  max-height: 500px;
  height: auto;
  overflow-y: auto;
  width: calc(100% + 32px);
  margin-left: -16px;
}

.global-search-no-result-text-left {
  color: #7d8898;
  text-align: start;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.global-search-qr-code-icon {
  width: 18px;
}

.global-search-qr-code-name {
  color: #141315;
  text-align: start;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(370px);
  width: 100%;
  -webkit-mask-image: linear-gradient(90deg, #000 80%, transparent);
}

.global-search-qr-code-main-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.global-search-qr-code-date {
  color: #7d8898;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.global-search-codes-item-block {
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid transparent;
  transition: 0.4s;
}

.global-search-codes-item-block .dashboard-code-row-with-gap {
  gap: 8px;
}

.global-search-type-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

.codes-mobile-type-filter-list {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #f1f5f9;
  padding: 2px;
}

.global-search-type-filter-list {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #f1f5f9;
  padding: 2px;
  width: 100%;
}

.codes-mobile-type-filter-item-active {
  display: flex;
  height: 28px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #fff;
  color: #9747ff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 80px;
}

.codes-mobile-type-filter-item {
  display: flex;
  height: 28px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  color: #334155;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background: transparent;
  cursor: pointer;
  width: 80px;
}

.link-filter-background {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background: rgba(51, 65, 85, 0.5);
  z-index: 7;
}

.global-search-filter-background {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(51, 65, 85, 0.5);
  z-index: 7;
  border-radius: 14px;
}

.link-filter-block {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 150px;
  background-color: white;
  border-radius: 14px 14px 0px 0px;
  z-index: 3;
  padding: 16px;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.global-search-filter-background .link-filter-block {
  border-radius: 14px;
}

.link-filter-block-title {
  color: #7d8898;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.link-filter-title-underline {
  width: 100%;
  height: 1px;
  border-top: 1px solid #e2e8f0;
}

.link-filter-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.link-filter-active-item {
  color: #9747ff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.link-filter-default-item {
  color: #334155;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.global-search-kind-filter {
  display: flex;
  gap: 4px;
  padding: 16px;
  border-bottom: 1px solid var(--grey-2, #e2e8f0);
  overflow-x: auto;
  overflow-y: hidden;
  width: calc(100% + 32px);
  margin-left: -16px;
}

.global-search-kind-filter-item {
  display: flex;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #141315;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  cursor: pointer;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  white-space: nowrap;
}

.global-search-kind-filter-item:hover {
  display: flex;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #edf3f9;
  color: #141315;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border: 1px solid #edf3f9;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  white-space: nowrap;
}

.global-search-kind-filter-item-active {
  display: flex;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #f5edff;
  color: #9747ff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  border: 1px solid #f5edff;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  white-space: nowrap;
}

.user-global-search-cancel {
  display: none;
}

.user-global-search-input-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  height: 52px;
}

.nav-dropdown-item:hover {
  background-color: #f1f5f9;
}

.trial-end-block-left-desktop-text {
  color: var(--white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.trial-end-block-left-texts-mobile {
  display: none;
}

.trial-end-modal-body {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trial-end-image {
  width: 120px;
  height: 120px;
  margin-top: 40px;
}

.trial-end-heading {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.36px;
  margin-top: 40px;
}

.trial-end-text {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 18px;
}

.trial-end-view-plans-btn {
  display: flex;
  height: 60px;
  padding: 8px 24px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background: var(--purple, #9747ff);
  color: var(--white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 40px;
}

.trial-end-cancel-btn {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin-top: 24px;
}

.form-input-dashboard-textarea {
  display: flex;
  height: 44px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px !important;
  background-color: #f1f5f9 !important;
  border: none;
  color: #334155;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  resize: none;
  height: 80px;
}

.form-input-dashboard-textarea::placeholder {
  color: #bec5cf;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.form-input-error-border,
.form-input-error-border:focus,
.form-input-error-border:focus-visible {
  border: 1px solid #ff4853 !important;
  box-shadow: none !important;
}

.upload-block-error-text {
  color: var(--red, #ff4853);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.upload-block-wrapper-error {
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 14px;
  border: 2px dashed var(--light-purple-2, #ff4853);
  width: 100%;
  height: 157px;
}

.manage-plan-modal-body {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manage-plan-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.manage-plan-header-text {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.manage-plan-current-text-block {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 40px;
  width: 100%;
}

.manage-plan-current-text {
  color: var(--off-black, #7d8898);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.manage-plan-current-status-block {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #00a066;
  color: #009864;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.manage-plan-current-status-block-inactive {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #7d8898;
  color: #7d8898;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.manage-plan-current-status-icon {
  width: 6px;
  height: 6px;
  background-color: #009864;
  border-radius: 6px;
}

.manage-plan-current-status-icon-inactive {
  width: 6px;
  height: 6px;
  background-color: #7d8898;
  border-radius: 6px;
}

.manage-plan-header-close {
  cursor: pointer;
}

.manage-plan-active-plan-block {
  display: flex;
  padding: 16px 24px 16px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--light-purple, #f5edff);
  width: 100%;
  margin-top: 16px;
}

.manage-plan-active-plan-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.manage-plan-active-plan-name {
  color: var(--black, #334155);
  text-align: start;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-transform: capitalize;
}

.manage-plan-active-plan-price {
  color: var(--black, #334155);
  text-align: start;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.36px;
}

.manage-plan-active-plan-button {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--purple, #9747ff);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.edit-file-block-wrapper {
  display: flex;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--grey-2, #e2e8f0);
  background: var(--white, #fff);
}

.edit-file-left-side-block {
  display: flex;
  align-items: center;
  gap: 16px;
}

.edit-file-left-side-img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 1px solid var(--grey-2, #e2e8f0);
}

.edit-file-left-side-info-block {
  display: flex;
  flex-direction: column;
}

.edit-file-left-side-info-name {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  word-break: break-all;
}

.edit-file-left-side-info-size {
  color: var(--black, #334155);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.edit-file-right-side-block {
  display: flex;
  align-items: center;
  gap: 16px;
}

.edit-file-right-side-change-btn {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--purple, #9747ff);
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.edit-file-right-side-delete-btn {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--grey-2, #e2e8f0);
}

.edit-file-left-side-img-block {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--grey-2, #e2e8f0);
  overflow: hidden;
  min-width: 80px;
}

.edit-file-list-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}

.edit-files-list-block-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--grey-2, #e2e8f0);
  background: var(--white, #fff);
}

.user-dashboard-wrapper-edit {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 60px);
}

.edit-list-files-img {
  width: 80px;
  height: 80px;
}

.dropdown-edit-list {
  display: none;
}

.twitter-purple-color-fill {
  filter: invert(32%) sepia(46%) saturate(4869%) hue-rotate(251deg) brightness(101%) contrast(101%);
}

.twitter-black-color-fill {
  filter: invert(24%) sepia(12%) saturate(1424%) hue-rotate(176deg) brightness(87%) contrast(88%);
}

.reset-password-text {
  color: #334155;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.reset-password-sent-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.reset-password-sent-block img {
  width: 120px;
  height: 120px;
}

.details-header-edit-btn-icon {
  margin-left: 15px;
}

.details-filter-period-mobile {
  height: 100%;
}

.details-filter-period-mobile .details-period-filter-item-active {
  display: flex;
  height: 100%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 0px;
  background: transparent;
  color: #9747ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
  min-width: 110px;
  border-bottom: 2px solid #9747ff;
}

.details-filter-period-mobile .details-period-filter-item {
  display: flex;
  height: 28px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  color: #334155;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  background: transparent;
  cursor: pointer;
  min-width: 110px;
  border-bottom: 2px solid transparent;
}

.details-filter-scans-mobile {
  padding: 2px;
  background-color: #f1f5f9;
  height: 100%;
}

.details-filter-scans-mobile .details-period-filter-item-active {
  display: flex;
  height: 28px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #fff;
  color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
  width: 100px;
}

.details-filter-scans-mobile .details-period-filter-item {
  display: flex;
  height: 28px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  background: transparent;
  cursor: pointer;
  width: 100px;
}

.global-search-static-image-wrapper {
  width: 46px;
  height: 46px;
  padding: 10px;
  border-radius: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--empty-state-text, #e2e8f0);
  min-width: 46px;
}

.global-search-dynamic-image-gradient-wrapper {
  background: linear-gradient(to right, #eb72a5, #9747ff);
  padding: 1px;
  border-radius: 100%;
}

.global-search-dynamic-image-wrapper {
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-no-scans-banner-gradient {
  background: linear-gradient(to right, #56ccf2, #f2c34c, #ff47ed, #9747ff);
  padding: 1px;
  border-radius: 14px;
  width: 100%;
}

.details-no-scans-banner {
  display: flex;
  padding: 16px 16px 16px 24px;
  justify-content: space-between;
  align-items: center;
  background-color: #f5edff;
  border-radius: 14px;
}

.details-no-scans-banner-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.details-no-scans-banner-text {
  color: var(--black, #334155);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.details-no-scans-banner-copy-btn {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: var(--purple, #9747ff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.preview-upgrade-block-line-mobile {
  display: none;
}

.reset-password-back-btn {
  display: none;
}

.reset-password-close {
  position: absolute;
  top: 28px;
  right: 15px;
  cursor: pointer;
}

.upgrade-plan-price-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.upgrade-plan-billed-annually {
  color: var(--Off-Black, #7d8898);
  text-align: start;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  margin-bottom: 24px;
}

.trademark-text {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: -0.38px;
  font-style: italic;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.stripe-purchase-button {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: end;
  padding: 0;
}

.purchase-button-mobile {
  display: none;
  margin-top: 16px;
}

.product-information-section {
  padding: 40px;
  max-width: 1420px;
  height: fit-content;
}

.product-information-section * {
  font-family: "Inter";
  color: var(--black, #334155);
}

.terms-of-service-section h3 {
  margin-bottom: 12px !important;
}

.terms-of-service-section h4 {
  margin-top: 16px !important;
}

.product-information-main {
  position: absolute;
  top: 60px;
  left: 8px;
  width: calc(100% - 16px);
  height: calc(100vh - 68px);
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  overflow-y: auto;
}

.purple-corners-block-wrapper.no-user .product-information-main {
  position: absolute;
  top: 60px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 68px);
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 0px;
  overflow-y: auto;
}

.contact-us-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px;
}

.contact-us-form {
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contact-us-button {
  display: flex;
  height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Purple, #9747ff);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.contact-us-button:disabled {
  background-color: var(--purple, rgba(151, 71, 255, 0.2));
}

.header-busines-pages-navigation {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-nav-link {
  color: var(--Black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  transition: 0.5s;
}

.header-nav-link:hover {
  color: #9747ff;
  transition: 0.5s;
}

.qr-code-expired-blur {
  filter: blur(4px);
}

.qr-code-expired-scans-blur {
  filter: blur(5.5px);
}

.qr-code-expired-label {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--Black, #334155);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border-radius: 100px;
  width: 60px;
  background: rgba(239, 30, 17, 0.2);
}

.convert-button-disabled {
  opacity: 0.4;
}

.dropdown-disabled:disabled {
  opacity: 0.2;
}

.close-search-icon {
  display: flex;
  cursor: pointer;
}

.global-search-codes-static-hover {
  border: 1px solid transparent;
  transition: 0.4s;
  border-radius: 8px;
}

.global-search-codes-static-hover:hover {
  border: 1px solid #bec5cf;
  transition: 0.4s;
  border-radius: 8px;
}

.global-search-codes-dynamic-hover .global-search-codes-item-block:hover {
  background-color: #f7fafe;
}

.reset-header-logo-mobile {
  display: none;
}

.mobile-block {
  display: none;
}

.continue-to-login-button {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  height: 44px;
}

.min-width-upgrade-option-icon {
  min-width: 24px;
}

.new-home-landing {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.new-home-introducing-section {
  width: 100%;
  max-width: 1180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 16px;
  padding-top: 120px;
  gap: 24px;
}

.introducing-section-information {
  display: flex;
  flex-direction: column;
}

.introducing-section-image {
  position: relative;
}

.introducing-section-image-mobile {
  position: relative;
  display: none;
}

.introducing-section-heading {
  color: #334155;
  font-family: "Inter";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.92px;
}

.introducing-section-description {
  color: #334155;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
}

.introducing-section-create-button {
  display: flex;
  height: 66px;
  padding: 11.878px 24px;
  justify-content: center;
  align-items: center;
  gap: 5.091px;
  border-radius: 8px;
  background: #9747ff;
  color: #f8fafc;
  text-align: center;
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-top: 40px;
  width: fit-content;
  min-width: 222px;
  letter-spacing: -0.02em;
}

.introducing-section-image-link {
  position: absolute;
  top: -70px;
  left: 30px;
  width: 68px;
}

.introducing-section-image-pdf {
  position: absolute;
  top: 15px;
  left: -65px;
  width: 68px;
}

.introducing-section-image-mp3 {
  position: absolute;
  bottom: 0;
  left: -75px;
  width: 68px;
}

.introducing-section-image-notification {
  position: absolute;
  top: 68%;
  left: -150px;
  width: 234px;
}

.new-home-abilities-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f5edff;
  padding: 80px 0;
}

.new-home-abilities-section-items-wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
  max-width: 1180px;
  width: 100%;
  padding: 0px 16px;
}

.abilities-section-block {
  display: flex;
  flex-direction: column;
  max-width: 339px;
  width: 100%;
}

.abilities-section-block-icon {
  width: 40px;
  height: 40px;
}

.abilities-section-block-title {
  color: #334155;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
  margin-top: 40px;
}

.abilities-section-block-description {
  color: #334155;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}

.new-home-discover-section {
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  width: 100%;
  padding: 80px 16px;
}

.discover-section-heading {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.66px;
  margin-bottom: 16px;
}

.discover-section-description {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
}

.discover-section-codes-preview-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.discover-section-codes-preview-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 400px;
  width: 100%;
}

.discover-section-code-item-default {
  display: flex;
  max-width: 400px;
  width: 100%;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #f1f5f9;
  background: #fff;
  color: var(--Black, #334155);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  gap: 12px;
  cursor: pointer;
}

.discover-section-code-item-active {
  display: flex;
  max-width: 400px;
  width: 100%;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #9747ff;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  gap: 12px;
  cursor: pointer;
}

.discover-section-code-icon-img {
  width: 21px;
}

.discover-section-codes-preview {
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 24px;
  background: #f1f5f9;
  max-height: 660px;
}

.discover-section-codes-preview-about {
  max-width: 292px;
  width: 100%;
  height: 100%;
  padding-top: 160px;
}

.discover-section-codes-preview-about-content {
  display: flex;
  flex-direction: column;
}

.discover-section-preview-content-icon {
  width: 60px;
}

.discover-section-preview-content-title {
  color: #334155;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.56px;
  margin-top: 24px;
}

.discover-section-preview-content-description {
  color: #334155;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 8px;
}

.new-home-empower-section {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1180px;
  width: 100%;
  padding: 80px 16px;
}

.empower-section-gradient-wrapper {
  background: linear-gradient(to right, #56ccf2, #f2c34c, #ff47ed, #9747ff);
  padding: 1px;
  border-radius: 16px;
  width: 100%;
}

.empower-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 16px;
  border-radius: 16px;
  width: 100%;
  background-color: #fff;
}

.empower-section-description {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 965px;
  margin-top: 24px;
}

.empower-section-create-button {
  display: flex;
  padding: 11.878px 16.969px;
  justify-content: center;
  align-items: center;
  gap: 5.091px;
  border-radius: 8px;
  background: #9747ff;
  color: #f8fafc;
  text-align: center;
  font-family: Inter;
  font-size: 15.272px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.666px;
  margin-top: 40px;
}

.new-home-footer {
  padding: 64px 0px;
  background-color: rgba(237, 243, 249, 1);
  width: 100%;
  display: flex;
  justify-content: center;
}

.new-home-footer-wrapper {
  max-width: 1180px;
  width: 100%;
  padding: 0 16px;
}

.footer-main-info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e8f0;
}

.footer-main-right {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}

.footer-main-right-links {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer-main-right-auth-buttons {
  display: flex;
  gap: 16px;
}

.footer-right-link {
  color: #334155;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.footer-login-button {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #334155;
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.footer-create-account-button {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #9747ff;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.footer-copyright {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 0;
  padding-top: 40px;
}

.hidden-convert-modal {
  position: absolute;
  top: -2111px !important;
}

.discover-section-codes-preview-picture {
  height: 560px;
  width: 270px;
  object-fit: cover;
  border-radius: 24px;
}

.introducing-section-image-image {
  position: absolute;
  top: -60px;
  right: 60px;
  width: 68px;
}

.new-flow-checkout-text {
  color: var(--Black, #334155);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.new-flow-checkout-text-link {
  color: #9747ff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.code-expired-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
}

.code-expired-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  border-bottom: 2px solid #f1f5f9;
}

.code-expired-main-content {
  min-height: calc(100vh - 76px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 40px;
}

.code-expired-title {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: -0.56px;
}

.code-expired-description {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.code-expired-create-button {
  display: flex;
  width: 320px;
  height: 60px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  border-radius: 8px;
  background: #9747ff;
  margin-top: 16px;
}

.code-expired-owner-text {
  color: #334155;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin-top: 16px;
}

.code-expired-owner-login-text {
  color: #9747ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
}

.code-expired-mobile-create-block {
  display: none;
}

.header-upgrade-new-button {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: radial-gradient(85.73% 141.42% at 0% 0%,
      #eb72a5 0%,
      #9747ff 100%);
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  transition: 0.3s;
}

.header-upgrade-new-button:hover {
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%),
    radial-gradient(55.73% 141.42% at 0% 0%, #eb72a5 0%, #9747ff 100%);
  transition: 0.3s;
}

.header-new-user-plan {
  display: flex;
  height: 20px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 100px;
  background: rgba(39, 174, 96, 0.1);
  color: #27ae60;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  width: fit-content;
}

.header-new-user-plan-expired {
  display: flex;
  height: 20px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 100px;
  background: rgba(248, 113, 113, 0.1);
  color: #f87171;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.header-new-user-plan-paid {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #9747ff;
  color: #9747ff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.navigation-control-icon {
  cursor: pointer;
}

.user-dashboard-second-navigation-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 40px 16px 40px;
  gap: 16px;
  background-color: #ffffff;
}

.user-dashboard-second-navigation-actions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-dashboard-second-navigation-filters {
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-dashboard-second-navigation-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.user-dashboard-second-navigation-convert-all {
  display: flex;
  height: 36px;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #334155;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.user-dashboard-second-navigation-create {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  border-radius: 8px;
  background: #9747ff;
}

.filters-dropdown-button {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background-color: transparent !important;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.filters-dropdown-button img {
  filter: invert(22%) sepia(12%) saturate(1524%) hue-rotate(176deg) brightness(96%) contrast(87%);
}

.filters-dropdown-button:hover {
  border: 1px solid #334155 !important;
}

.filters-dropdown-button.show {
  border: 1px solid #9747ff !important;
  color: #9747ff !important;
}

.filters-dropdown-button.selected img,
.filters-dropdown-button.show img {
  filter: invert(32%) sepia(51%) saturate(2393%) hue-rotate(245deg) brightness(101%) contrast(109%);
}

.filters-dropdown-menu {
  padding: 16px !important;
  width: 310px;
  border-radius: 8px;
  border: 1px solid #e2e8f0 !important;
  background: #ffffff !important;
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15) !important;
}

.filters-dropdown-button:active,
.filters-dropdown-button:target,
.filters-dropdown-button.show:focus,
.filters-dropdown-button:focus-visible {
  border: 1px solid #9747ff !important;
  outline: none !important;
  box-shadow: none !important;
}

.filters-dropdown-button.selected {
  background-color: #f5edff !important;
  border: 1px solid #e0c8ff !important;
  color: #9747ff !important;
}

.filters-dropdown-button:focus {
  border: 1px solid #e2e8f0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.filters-dropdown-menu-title {
  color: #334155;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e2e8f0;
}

.filters-dropdown-menu-types-title {
  color: #7d8898;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.filters-dropdown-menu-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filters-dropdown-code-types-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filters-dropdown-code-list-item {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  cursor: pointer;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.filters-dropdown-code-list-active-item {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #f5edff;
  color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
  border: 1px solid transparent;
}

.filters-dropdown-code-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.filters-dropdown-code-clear-button,
.filters-dropdown-code-clear-button:active,
.filters-dropdown-code-clear-button:hover,
.filters-dropdown-code-clear-button:focus {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #334155;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
  background-color: transparent;
}

.filters-dropdown-code-apply-button,
.filters-dropdown-code-apply-button:active,
.filters-dropdown-code-apply-button:hover,
.filters-dropdown-code-apply-button:focus {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #9747ff;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
}

.sort-dropdown-menu-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sort-dropdown-menu-item {
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.sort-dropdown-menu-item.active {
  color: #9747ff;
}

.codes-list-add-code-block {
  background: #ffffff;
  padding: 24px;
  color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboard-code-dynamic-info-block {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 24px;
  height: 100%;
  border-right: 1px solid #e2e8f0;
  height: 60px;
}

.dashboard-code-static-info-block {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
  height: 60px;
}

.dashboard-code-dynamic-info-title {
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.dashboard-code-dynamic-info-icon {
  cursor: pointer;
  margin-left: 2px;
}

.code-dynamic-totals-today {
  display: flex;
  height: 20px;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 100px;
  background: rgba(39, 174, 96, 0.1);
  color: #27ae60;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  gap: 4px;
  min-width: 30px;
}

.dashboard-options-icon-wrapper {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.dashboard-options-icon-wrapper:hover {
  background: #edf3f9;
}

.dashboard-options-icon-wrapper:hover img {
  filter: invert(19%) sepia(52%) saturate(345%) hue-rotate(176deg) brightness(0%) contrast(49%);
}

.dashboard-options-icons-block {
  display: flex;
  align-items: center;
  gap: 1px;
}

.dashboard-more-options-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  height: 36px;
  padding: 8px 12px;
  border-radius: 8px;
}

.dashboard-more-options-item-wrapper:hover {
  background: #edf3f9;
  border-radius: 8px;
}

.dashboard-options-menu-block {
  width: 240px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15);
}

.dashboard-options-icon-wrapper.disabled {
  opacity: 0.6;
}

.dashboard-code-static-info-title {
  color: #7d8898;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.codes-list-static-vertical-line {
  height: 60px;
  width: 1px;
  background-color: #e2e8f0;
}

.user-dashboard-qr-code-page-preview-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 120px;
}

.user-dashboard-qr-code-page-preview {
  width: 45px;
  height: 80px;
  border-radius: 4px;
  background: #334155;
}

.dashboard-second-navigation-filters-mobile {
  display: none;
}

.tooltip-custom {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #141315;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  position: fixed;
}

.confirm-archive-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px;
}

.confirm-modal-icon {
  width: 48px;
  height: 48px;
}

.confirm-modal-title {
  width: 280px;
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
}

.confirm-modal-actions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 12px;
  width: 100%;
}

.confirm-modal-action-cancel {
  display: flex;
  height: 44px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  border-radius: 8px;
  border: 1px solid #334155;
  width: 100%;
  transition: 0.3s;
}

.confirm-modal-action-cancel:hover {
  border: 1px solid #bec5cf;
  transition: 0.3s;
}

.confirm-modal-action-confirm {
  display: flex;
  height: 44px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #7f2fe7;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
  transition: 0.3s;
}

.confirm-modal-action-confirm:hover {
  background: #9747ff;
  transition: 0.3s;
}

.confirm-modal-action-delete {
  display: flex;
  height: 44px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #f87171;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  width: 100%;
  transition: 0.3s;
}

.confirm-modal-action-delete:hover {
  background-color: #e56969;
  transition: 0.3s;
}

.code-main-type-global-search-mobile {
  color: #334155;
}

.code-main-type-global-search-mobile img {
  filter: invert(22%) sepia(12%) saturate(1524%) hue-rotate(176deg) brightness(96%) contrast(87%);
}

.header-gray-background {
  background-color: #e2e8f0;
}

.upgrade-plan-new-card-block-best {
  display: flex;
  max-width: 405px;
  padding: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  border-top: 1px solid white;
  background-color: #9747ff;
  border: 1px solid #7f2fe7;
  border-top: none;
  position: relative;
  box-shadow: 8px 16px 39px 0px #1b003e14, 31px 63px 70px 0px #1b003e12,
    70px 141px 95px 0px #1b003e0a, 125px 251px 112px 0px #1b003e03,
    195px 392px 123px 0px #1b003e00;
}

/*

box-shadow: 8px 16px 39px 0px #1B003E14;

box-shadow: 31px 63px 70px 0px #1B003E12;

box-shadow: 70px 141px 95px 0px #1B003E0A;

box-shadow: 125px 251px 112px 0px #1B003E03;

box-shadow: 195px 392px 123px 0px #1B003E00;

*/

.upgrade-plan-new-card-block {
  display: flex;
  max-width: 405px;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 14px;
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  position: relative;
  box-shadow: 8px 16px 39px 0px #1b003e14, 31px 63px 70px 0px #1b003e12,
    70px 141px 95px 0px #1b003e0a, 125px 251px 112px 0px #1b003e03,
    195px 392px 123px 0px #1b003e00;
}

.upgrade-plan-new-underline-best {
  width: 100%;
  height: 1px;
  background-color: #7f2fe7;
  margin-bottom: 24px;
}

.upgrade-plan-new-underline {
  width: 100%;
  height: 1px;
  background-color: #e2e8f0;
  margin-bottom: 24px;
}

.choose-plan-button-new {
  display: flex;
  width: 100%;
  height: 60px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #141315;
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
  background-color: #ffffff;
}

.upgrade-plan-new-most-popular {
  display: flex;
  width: calc(100% + 2px);
  height: 34px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  border-radius: 14px 14px 0px 0px;
  background: #7f2fe7;
  position: absolute;
  top: -34px;
  left: -1px;
  border: 1px solid #7f2fe7;
}

.upgrade-plan-new-note {
  color: #7d8898;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 24px 0;
  height: 36px;
  display: flex;
  align-items: center;
}

.upgrade-plan-new-card-block-best .upgrade-plan-name,
.upgrade-plan-new-card-block-best .upgrade-plan-cost,
.upgrade-plan-new-card-block-best .upgrade-plan-active-option-text,
.upgrade-plan-new-card-block-best .upgrade-plan-new-note {
  color: #ffffff;
}

.header-checkout-pages-title {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: -0.02em;
}

.sticky-header-upgrade {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  border: unset;
  background-color: #ffffff !important;
}

.upgrade-plan-new-save {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #25af60;
  color: #25af60;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  position: absolute;
  right: 24px;
  top: 24px;
}

.upgrade-plan-new-mobile-block {
  display: none;
}

.checkout-new-title-powered {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 40px;
}

.checkout-new-payment-method-title {
  color: #141315;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.56px;
}

.select-payments-block-wrapper {
  display: flex;
  padding: 4px;
  align-items: center;
  border-radius: 100px;
  background: #ffffff;
}

.selecet-payment-method-element {
  display: flex;
  align-items: center;
  gap: 8px;
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.select-payment-method-name {
  color: #7d8897;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.selecet-payment-method-element.active {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #edf3f9;
}

.selecet-payment-method-element.active .select-payment-method-name {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.checkout-guarantee-block {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.checkout-guarantee-icon-wrapper {
  display: flex;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #f5edff;
}

.checkout-guarantee-block-title {
  color: #141315;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 4px;
}

.checkout-guarantee-block-text {
  color: #141315;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.checkout-new-selected-plan-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}

.change-selected-plan-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.change-selected-plan-block-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.change-selected-plan-block-title {
  color: #141315;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.change-selected-plan-block-name {
  color: #141315;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.change-selected-plan-block-change-btn {
  color: #9747ff;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  cursor: pointer;
}

.checkout-new-due-total-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkout-new-due-total-title {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.checkout-new-due-total-price {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.payment-paypal-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
}

.payment-paypal-block-text {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.checkout-button-paypal {
  display: flex;
  align-items: center;
  gap: 8px;
  display: flex;
  height: 56px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: #ffd140;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
}

.checkout-guarantee-underline {
  width: 100%;
  height: 1px;
  background-color: #e2e8f0;
}

.disable-on-desktop {
  display: none;
}

.checkout-new-purchase-block {
  display: none;
}

.change-selected-plan-block-mobile {
  display: none;
}

.auth-modal-new {
  max-width: unset;
}

.auth-modal-new .max-w-4xl {
  padding: 0 !important;
  min-height: 100vh;
  max-width: unset;
}

.auth-modal-new .rounded-lg {
  max-height: unset;
  height: 100vh;
  overflow-y: auto;
  border-radius: unset !important;
}

.new-sign-in-up-form {
  padding: 0 110px;
  width: 100%;
}

.sign-up-in-form-wrapper {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  display: flex;
}

.new-sign-up-in-page-title {
  color: #141315;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: -0.56px;
}

.new-sign-up-in-page-google-btn {
  display: flex;
  height: 56px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #141315;
  color: #141315;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  transition: 0.3s;
}

.new-sign-up-in-page-google-btn:hover {
  border: 1px solid #bec5cf;
  transition: 0.3s;
}

.new-sign-up-in-page-btn {
  display: flex;
  height: 56px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #141315;
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  transition: 0.2s;
}

.new-sign-up-in-input::placeholder {
  color: #bec5cf;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.new-sign-up-in-input {
  color: #141315;
  font-family: "Inter";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: transparent !important;
}

.new-sign-up-in-input:focus {
  border: 1px solid transparent !important;
}

.new-sign-up-checks-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.new-sign-up-check-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.new-sign-up-check-text {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.new-sign-up-in-page-btn:disabled {
  opacity: 1;
}

.new-sign-up-in-page-btn:hover {
  background-color: #29282b;
  transition: 0.2s;
}

.new-sign-up-input-block-wrapper.first {
  display: flex;
  height: 56px;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  border-radius: 8px 8px 0px 0px !important;
  border: 1px solid #e2e8f0 !important;
  background-color: #ffffff !important;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.new-sign-up-input-block-wrapper.first:focus-within {
  border: 1px solid #e0c8ff !important;
  border-radius: 8px 8px 0px 0px;
}

.new-sign-up-input-block-wrapper.first:focus-within::after {
  position: absolute;
  top: 0px;
  content: "";
  left: 0px;
  width: 100%;
  height: 54px;
  border: 1px solid #e0c8ff;
  border-radius: 8px 8px 0 0;
  background-color: transparent;
  display: inherit;
  z-index: 2;
}

.new-sign-up-input-block-wrapper.second {
  display: flex;
  height: 56px;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #e2e8f0 !important;
  border-top: none !important;
  background-color: #ffffff !important;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.new-sign-up-input-block-wrapper.second:focus-within {
  border: 1px solid #e0c8ff !important;
  border-radius: 0px 0px 8px 8px;
}

.new-sign-up-input-block-wrapper.second:focus-within::after {
  position: absolute;
  top: 0px;
  content: "";
  left: 0px;
  width: 100%;
  height: 54px;
  border: 1px solid #e0c8ff;
  border-radius: 0 0 8px 8px;
  background-color: transparent;
  display: inherit;
  z-index: 2;
}

.new-sign-up-label {
  color: #bec5cf;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  transition: 0.2s;
}

.new-sign-up-input-block-wrapper.first:focus-within .new-sign-up-label,
.new-sign-up-input-block-wrapper.filled.first .new-sign-up-label {
  color: #7d8898;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  top: 12px;
  left: 12px;
  transition: 0.2s;
  z-index: 3;
}

.new-sign-up-input-block-wrapper.second:focus-within .new-sign-up-label,
.new-sign-up-input-block-wrapper.filled.second .new-sign-up-label {
  color: #7d8898;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  top: 12px;
  left: 12px;
  transition: 0.2s;
  z-index: 3;
}

.new-sign-up-input-block-wrapper.first:focus-within .new-sign-up-in-input,
.new-sign-up-input-block-wrapper.filled.first .new-sign-up-in-input {
  height: 43px;
  border: none !important;
  width: calc(100% - 2px);
  margin-left: 1px;
  padding-left: 11px;
  margin-bottom: 1px;
}

.new-sign-up-input-block-wrapper.second:focus-within .new-sign-up-in-input,
.new-sign-up-input-block-wrapper.filled.second .new-sign-up-in-input {
  height: 43px;
  border: none !important;
  width: calc(100% - 2px);
  margin-left: 1px;
  padding-left: 11px;
  margin-bottom: 1px;
}

.new-sign-up-in-input:-webkit-autofill,
.new-sign-up-in-input:-webkit-autofill:hover,
.new-sign-up-in-input:-webkit-autofill:focus,
.new-sign-up-in-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 300px white inset !important;
}

.auth-switch-wrapper {
  display: none;
}

.desktop-hidden {
  display: none !important;
}

.new-auth-page-close-mobile {
  display: none;
}

.d-flex-important {
  display: flex !important;
}

.new-dashboard-header-wrapper {
  background-color: #1b003e !important;
  border-bottom: none !important;
  padding: 8px 16px 8px 8px !important;
}

.header-notification-block-wrapper {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.header-notifications-block {
  display: flex;
  height: 44px;
  width: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  user-select: none;
  margin-right: 4px;
  position: relative;
  cursor: pointer;
  transition: 0.2s;
}

.header-notifications-block:hover {
  background-color: #311951;
  transition: 0.2s;
}

.header-notifications-block.opened {
  background-color: #e0c8ff;
  transition: 0.2s;
}

.header-notification-coming-soon-text {
  color: #e0c8ff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.navigation-control-icon-block {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.navigation-control-icon-block:hover {
  background: rgba(255, 255, 255, 0.2);
}

.dashboard-navigation-bottom-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.dashboard-navigation-bottom-scan-left-text {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.8;
}

.dashboard-navigation-bottom-upgrade-block {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  z-index: 3;
}

.dashboard-navigation-bottom-user-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  margin-bottom: -8px;
  user-select: none;
  border-radius: 8px;
  padding-left: 6px;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-bottom-user-block:hover {
  background-color: transparent;
  transition: 0.2s;
}

.dashboard-navigation-bottom-user-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dashboard-navigation-bottom-user-base-info {
  display: flex;
  flex-direction: column;
  gap: -2px;
}

.dashboard-navigation-bottom-user-info-name {
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.dashboard-navigation-bottom-user-info-plan {
  color: #e0c8ff;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.2px;
  height: 12px;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-bottom-user-base-info,
.user-dashboard-navigation-collapsed .dashboard-navigation-bottom-user-block-arrow {
  display: none;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-bottom-scan-left-text,
.user-dashboard-navigation-collapsed .upgrade-button-text {
  display: none;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-bottom-upgrade-block {
  padding: 0 6px;
  border: 0;
  width: 100%;
  padding-bottom: 17px;
  z-index: 3;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-bottom-upgrade-block img {
  width: 18px;
}

.user-dashboard-navigation-collapsed .header-upgrade-new-button {
  padding: 0;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-bottom-user-block {
  padding: 0 0 8px 0;
  justify-content: center;
}

.header-upgrade-new-button:hover .hovered {
  display: flex;
}

.header-upgrade-new-button:hover .default {
  display: none;
}

.header-upgrade-new-button .hovered {
  display: none;
}

.dashboard-navigation-options-dropdown {
  display: flex;
  width: 218px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.11);
}

.user-dashboard-navigation .dashboard-navigation-options-dropdown {
  position: absolute;
  left: 256px;
  bottom: 16px;
  z-index: 10;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-options-dropdown {
  position: absolute;
  left: 76px;
  bottom: 16px;
  z-index: 10;
}

.dashboard-navigation-options-dropdown-item {
  display: flex;
  height: 44px;
  padding: 16px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #334155;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}

.dashboard-navigation-options-dropdown-item:hover {
  background: #edf3f9;
  transition: 0.2s;
}

.dashboard-navigation-options-dropdown-item:hover svg {
  fill: #7d8898 !important;
  transition: 0.2s;
}

.recent-close-icon {
  cursor: pointer;
}

.user-dashboard-content-new {
  border-radius: 16px;
  background-color: #f1f5f9;
  margin-right: 8px;
}

.purple-corners-block-wrapper {
  display: flex;
  width: 100%;
  background-color: #1b003e;
  padding: 8px;
}

.purple-corners-block-wrapper.no-user {
  background-color: #ffffff;
}

.upgrade-plan-purple-corners {
  border-radius: 16px;
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.header-upgrade-back-new-button {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  transition: 0.2s;
}

.header-upgrade-back-new-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transition: 0.2s;
}

.overview-panel-wrapper {
  position: fixed;
  top: 60px;
  right: 8px;
  width: 600px;
  z-index: 10;
  height: calc(100vh - 68px);
  background-color: #fff;
  border-left: 1px solid #e2e8f0;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.overview-panel-wrapper.full-page {
  position: initial;
  width: 700px;
  box-shadow: none;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.overview-panel-header-options {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  background: #fff;
  padding: 8px;
  justify-content: space-between;
}

.tooltip-custom {
  z-index: 11;
}

.overview-panel-code-info {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
}

.overview-panel-code-info-block {
  display: flex;
  flex-direction: column;
}

.overview-panel-code-info-preview-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: relative;
  max-width: 152px;
}

.overview-panel-code-info-name {
  color: #141315;
  text-align: start;
  font-family: "Inter";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  width: 360px;
  -webkit-mask-image: linear-gradient(90deg, #000 80%, transparent);
}

.overview-panel-code-info-table td {
  height: 33px;
  padding-left: 0px;
  padding-right: 12px;
}

.overview-panel-code-info-table .heading {
  color: #7d8898;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  min-width: 85px;
}

.overview-panel-code-info-table .default-text {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.overview-panel-code-info-type {
  display: flex;
  align-items: center;
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  width: fit-content;
  height: 24px;
  padding: 0px 6px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  gap: 4px;
}

.overview-panel-code-info-preview-phone {
  border-radius: 5px;
  background: #fff;
  width: 64px;
  height: 114px;
  overflow-y: auto;
}

.overview-panel-code-info-preview-phone::-webkit-scrollbar {
  width: 0px;
}

.overview-panel-code-info-preview-switch {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-panel-code-info-preview-switch:hover {
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%),
    #fff;
  transition: 0.3s;
}

.overview-panel-code-info-preview-phone-switch {
  width: 11px;
  height: 20px;
  border-radius: 2px;
  background: #141315;
  overflow-y: auto;
  pointer-events: none;
}

.overview-panel-stats-wrapper {
  padding: 16px;
  padding-top: 0;
}

.overview-panel-stats {
  border-top: 1px solid #e2e8f0;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}

.overview-panel-stats-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
}

.overview-panel-stats-heading {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.38px;
}

.overview-panel-stats-header-filters {
  display: flex;
  align-items: center;
  gap: 16px;
}

.overview-panel-stats-header-unique-scans {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.overview-panel-stats-header-unique-scans-switch {
  display: flex;
  height: 18px;
  padding: 2px 2px 2px 2px;
  align-items: center;
  border-radius: 100px;
  background: #bec5cf;
  width: 36px;
  cursor: pointer;
  transition: 0.3s;
  justify-content: start;
  margin-left: 4px;
}

.overview-panel-stats-header-unique-scans-switch.active {
  background: #9747ff;
  transition: 0.3s;
  justify-content: end;
}

.overview-panel-switch-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
  transition: 0.3s;
}

.overview-panel-stats-period-filter-wrapper {
  position: relative;
  margin-left: 8px;
}

.overview-panel-stats-period-filter-button {
  display: flex;
  height: 24px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #e2e8f0;
  color: #141315;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  gap: 4px;
  user-select: none;
  transition: 0.3s;
}

.overview-panel-stats-period-filter-menu {
  display: flex;
  width: 240px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 40px;
  right: 0;
}

.overview-panel-stats-period-filter-menu-heading {
  color: #141315;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
}

.overview-panel-stats-period-filter-menu-underline {
  width: 100%;
  height: 1px;
  background-color: #e2e8f0;
}

.overview-panel-stats-period-filter-menu-items-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.overview-panel-stats-period-filter-menu-item {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.overview-panel-stats-tables {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.overview-panel-stats-total-scans-block {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.overview-panel-stats-total-scans-block-header {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
}

.overview-panel-stats-total-scans-block-heading {
  color: #7d8898;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.overview-panel-stats-total-scans-total-numbers {
  display: flex;
  align-items: center;
  gap: 8px;
}

.overview-panel-stats-total-scans-count {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: -1.56px;
}

.overview-panel-stats-total-scans-graph {
  display: flex;
  padding-top: 16px;
  width: 100%;
  touch-action: none;
}

.overview-panel-stats-scans-by-category-two-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.overview-panel-stats-scans-by-category-one-col {
  width: 100%;
}

.overview-panel-stats-scans-by-category-column {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.overview-panel-stats-scans-by-category-column-title {
  color: #7d8898;
  text-align: start;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
}

.overview-panel-stats-scans-by-category-table {
  width: 100%;
}

.overview-panel-stats-scans-by-category-table td {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.overview-panel-stats-scans-by-category-table td.overview-panel-count-scans {
  color: #141315;
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: end;
}

.overview-panel-graph-no-data {
  color: #bec5cf;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  margin-top: 12px;
}

.overview-panel-graph-scans-no-data {
  color: #bec5cf;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.overview-panel-scrollable-content {
  height: calc(100vh - 133px);
  overflow-y: auto;
}

.overview-panel-wrapper.full-page .overview-panel-scrollable-content {
  height: calc(100vh - 121px);
  overflow-y: auto;
}

.overview-panel-stats-scans-by-category-table .scans-table-row {
  height: 44px;
  border-bottom: 1px solid #e2e8f0;
}

.overview-panel-stats-scans-by-category-table .scans-table-row:last-child {
  border-bottom: none;
}

.overview-panel-code-image-static {
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  border: 1px solid #e2e8f0;
}

.overview-panel-code-image-dynamic {
  width: 75px;
}

.overview-panel-header-options-right {
  display: flex;
  align-items: center;
}

.overview-panel-header-options-convert-button {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #141315;
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.overview-panel-stats-convert-static-block {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #f5edff;
  margin-bottom: 16px;
}

.overview-panel-stats-convert-static-block-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.overview-panel-stats-convert-static-block-left-text {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.overview-panel-stats-convert-static-block-right-button {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #9747ff;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  white-space: nowrap;
}

.unusable-area {
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}

.scans-table-row-static-value {
  color: #bec5cf !important;
  text-align: end !important;
  font-family: "Inter";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: -0.46px !important;
  opacity: 0.6;
}

.overview-panel-count-scans {
  text-align: end;
}

.code-page-exit-preview-button {
  display: flex;
  height: 44px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 100;
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
}

.analytics-wrapper {
  width: 100%;
  background-color: #1b003e;
  padding: 0 8px 8px 0;
  display: flex;
}

.analytics-content-wrapper {
  border-radius: 16px;
  background-color: #e2e8f0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.analytics-content-header {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  background-color: white;
  gap: 8px;
}

.analytics-content-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.analytics-content-header-bottom {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
}

.analytics-header-title {
  color: #141315;
  font-family: "Inter";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
}

.notifications-plan-modal.h-modal {
  height: 100vh !important;
}

.notifications-plan-modal .max-w-xl {
  display: flex !important;
}

.notifications-plan-modal .rounded-lg {
  background-color: transparent !important;
  margin: auto 0;
}

.notifications-plan-modal-body {
  border-radius: 16px;
  background-color: white;
  padding: 32px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.notifications-plan-modal-title {
  font-family: "Inter";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.46px;
  color: #141315;
}

.analytics-progress-bar-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.analytics-empty-progress-bar {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
}

.analytics-empty-progress-bar.pink {
  background-color: #eb72a5;
}

.analytics-empty-progress-bar.purple {
  background-color: #9747ff;
}

.analytics-header-options {
  display: flex;
  align-items: center;
  gap: 8px;
}

.analytics-header-option-block {
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
}

.analytics-header-option-label {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.analytics-header-date-option-block {
  position: relative;
  margin-left: 8px;
}

.analytics-header-date-option-button {
  display: flex;
  height: 36px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  transition: 0.3s;
}

.analytics-header-date-option-button:hover,
.analytics-header-date-option-button.active {
  border: 1px solid #141315;
  transition: 0.3s;
}

.analytics-header-date-option-button.applied {
  border: 1px solid #e0c8ff;
  color: #9747ff;
  background-color: #f5edff;
}

.analytics-header-date-option-calendar {
  position: absolute;
  top: 60px;
  right: 0;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 9px 18px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 2;
}

.analytics-header-date-calendar-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.analytics-header-calendar-buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  width: fit-content;
  gap: 8px;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.analytics-header-calendar-clear-btn {
  display: flex;
  height: 44px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #141315;
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.analytics-header-calendar-done-btn {
  display: flex;
  height: 44px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #9747ff;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.analytics-content-stats-block-wrapper {
  padding: 16px;
}

.analytics-content-stats-block {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: #ffffff;
  overflow-y: auto;
}

.analytics-content-codes-select {
  display: flex;
  flex-direction: column;
  position: relative;
}

.analytics-content-codes-select-btn {
  display: flex;
  align-items: center;
  gap: 12px;
  user-select: none;
  transition: 0.2s;
  width: 100%;
}

.analytics-content-codes-select-btn.oversized .analytics-selected-code-name-text {
  -webkit-mask-image: linear-gradient(90deg, #000 85%, transparent);
}

.analytics-selected-code-name-text {
  color: #141315;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.38px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: start;
}

.analytics-content-codes-select-btn:hover span,
.analytics-content-codes-select-btn:hover {
  color: #7d8898;
  transition: 0.2s;
}

.analytics-content-codes-select-btn:hover img {
  filter: invert(66%) sepia(10%) saturate(554%) hue-rotate(176deg) brightness(79%) contrast(90%);
  transition: 0.2s;
}

.analytics-content-codes-select-btn.active img {
  transform: rotate(90deg);
  transition: 0.2s;
}

.analytics-content-codes-select-arrow {
  transform: rotate(-90deg);
  width: 16px;
  margin-left: -5px;
}

.analytics-content-block {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background: #fff;
}

.analytics-content-block-title {
  color: #141315;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.analytics-content-activity-block {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analytics-content-no-scans-text {
  color: #7d8898;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.analytics-content-block-expand-icon {
  cursor: pointer;
  transition: 0.3s;
}

.analytics-content-block-expand-icon.active {
  transform: rotate(180deg);
  transition: 0.3s;
}

.analytics-block-table {
  width: 100%;
}

.analytics-table-header {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.96px;
  color: #7d8898;
  text-transform: uppercase;
}

.analytics-table-row {
  height: 35px;
}

.analytics-table-cell-title {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.28px;
  color: #141315;
}

.analytics-table-cell-progress-block {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

.analytics-table-cell-progress-count {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  width: 40px;
}

.analytics-table-cell-percentage {
  color: #141315;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  width: 45px;
}

.analytics-select-codes-menu {
  position: absolute;
  top: 34px;
  left: 0;
  box-shadow: 0px 9px 18px 0px #00000026;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #fff;
  width: 320px;
  z-index: 2;
}

.analytics-select-codes-menu-where-my-data-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  width: 100%;
  border-top: 1px solid #e2e8f0;
}

.analytics-select-codes-list-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.analytics-select-codes-search-block {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  background-color: #edf3f9;
  padding: 16px 12px 16px 12px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
}

.analytics-select-codes-search-input {
  background-color: transparent;
  width: 100%;
  color: #7d8898;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 24px;
}

.analytics-select-codes-search-input:focus {
  outline: none;
  border: 1px solid transparent !important;
}

.select-codes-menu-where-my-data-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  color: #9747ff;
  cursor: pointer;
}

.select-codes-menu-where-my-data-title:hover {
  color: #7f2fe7;
}

.select-codes-menu-where-my-data-text {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #7d8898;
}

.select-codes-menu-where-my-data-title img {
  transition: 0.3s;
}

.select-codes-menu-where-my-data-title.active img {
  transform: rotate(180deg);
  transition: 0.3s;
  text-align: center;
  color: #141315;
}

.notifications-plan-modal-caption {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #7d8898;
  margin-top: -8px;
}

.notifications-plan-modal-list-block {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  border: 1px solid #edf3f9;
  width: 100%;
}

.notifications-plan-modal-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.notifications-plan-modal-list-item-text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.notification-plan-modal-btn {
  width: 100%;
  height: 44px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 10px;
  margin-top: 8px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
  background: radial-gradient(100% 779.34% at 0% 0%, #eb72a5 0%, #9747ff 100%);
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
}

.analytics-select-codes-list-item-block {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  width: 100%;
  margin-left: 0px;
  border-radius: 8px;
  transition: 0.3s;
  height: 76px;
  position: relative;
  user-select: none;
}

.analytics-select-codes-list-item-block.dynamic:hover {
  background-color: #f7fafe;
  transition: 0.3s;
  cursor: pointer;
}

.analytics-select-codes-list-item-block.disabled-code {
  pointer-events: none;
  opacity: 0.5;
}

.analytics-select-codes-list-item-info {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.analytics-select-codes-list-item-name {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #141315;
  white-space: nowrap;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(90deg, #000 80%, transparent);
  width: calc(100% - 14px);
}

.analytics-select-codes-list-item-type {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #7d8898;
}

.analytics-select-codes-convert-to-dynamic {
  width: 100%;
  height: 36px;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background-color: #edf3f9;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  margin-top: 4px;
  transition: 0.3s;
}

.analytics-select-codes-convert-to-dynamic:hover {
  background-color: #e2e8f0;
  transition: 0.3s;
}

.analytics-header-filter-block-menu {
  position: absolute;
  top: 60px;
  right: 0;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 9px 18px 0px #00000033;
  width: 340px;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 2;
}

.analytics-filter-block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.analytics-filter-block-header-title {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #141315;
  user-select: none;
}

.analytics-filter-block-header-clear {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #9747ff;
  opacity: 0.35;
  user-select: none;
}

.analytics-filter-block-header-clear.active {
  color: #9747ff;
  opacity: 1;
  cursor: pointer;
}

.analytics-filter-item-block {
  display: flex;
  width: 100%;
  height: 56px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
  gap: 8px;
}

.analytics-filter-item-block:hover {
  border: 1px solid #141315;
  transition: 0.3s;
}

.analytics-filter-item-block svg {
  transition: 0.3s;
}

.analytics-filter-item-block:hover svg {
  fill: #141315;
  transition: 0.3s;
}

.analytics-filter-item-block-info {
  display: flex;
  flex-direction: column;
}

.analytics-filter-item-block-info-name {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d8898;
}

.analytics-filter-item-block-info-value {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.analytics-selected-filter-block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 16px;
}

.analytics-selected-filter-back-block {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.analytics-selected-filter-back-block-title {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #141315;
  user-select: none;
}

.analytics-selected-filter-options-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.analytics-selected-filter-options-item-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.analytics-selected-filter-options-item-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
  user-select: none;
}

.analytics-selected-filter-options-item-select {
  border: 2px solid #bec5cf !important;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.analytics-selected-filter-options-item-select:active,
.analytics-selected-filter-options-item-select:hover,
.analytics-selected-filter-options-item-select:focus,
.analytics-selected-filter-options-item-select:focus-visible {
  border: 2px solid #bec5cf !important;
}

.analytics-selected-filter-options-item-select:checked {
  border: 2px solid #9747ff !important;
}

.analytics-filters-apply-btn {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  opacity: 0.35;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ffffff;
  transition: 0.2s;
}

.analytics-filters-apply-btn.active {
  opacity: 1;
}

.analytics-filters-apply-btn.active:active {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.analytics-content-header-applied-filter-block {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 32px;
  padding: 8px 4px 8px 8px;
  border-radius: 100px;
  background-color: #9747ff;
  white-space: nowrap;
}

.analytics-content-header-applied-filter-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.analytics-content-header-applied-filter-clear {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  gap: 10px;
  background-color: transparent;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.analytics-content-header-applied-filter-clear:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.analytics-content-select-codes-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.analytics-graph-wrapper {
  width: 100%;
}

.analytics-content-select-code-pink-dot {
  min-width: 10px;
  max-width: 10px;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #eb72a5;
}

.analytics-content-select-code-purple-dot {
  min-width: 10px;
  max-width: 10px;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #9747ff;
}

.analytics-block-table-compare .analytics-table-cell-percentage {
  padding-left: 10px;
}

.analytics-select-codes-list-block-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 2px;
}

.analytics-select-codes-list-block-items::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.analytics-select-codes-list-block-items::-webkit-scrollbar-thumb {
  background-color: #e2e8f0;
  border-radius: 8px;
  width: 4px;
}

.analytics-select-codes-list-item-static-wrapper {
  display: flex;
  flex-direction: column;
}

.analytics-select-code-check-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.analytics-graph-mobile-static-ticks {
  display: none;
}

.user-dashboard-second-navigation-code-actions-block {
  display: flex;
  align-items: center;
  gap: 16px;
}

.user-dashboard-second-navigation-create-code {
  width: 236px;
  height: 83px;
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
  background-color: #9747ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #9747ff;
  transition: 0.2s;
}

.user-dashboard-second-navigation-create-code:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.analytics-header-reset-all-changes {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9747ff;
  cursor: pointer;
}

.user-dashboard-second-navigation-default-button {
  min-width: 236px;
  height: 83px;
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
  cursor: pointer;
  border: 1px solid #e2e8f0;
  justify-content: space-between;
  transition: 0.2s;
}

.user-dashboard-second-navigation-default-button:hover {
  border: 1px solid #141315;
  transition: 0.2s;
}

.user-dashboard-second-navigation-view-title {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
}

.user-dashboard-second-navigation-button {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.user-dashboard-second-navigation-button.active:hover,
.user-dashboard-second-navigation-button.active {
  background-color: #f5edff;
}

.user-dashboard-second-navigation-button.active img {
  filter: invert(31%) sepia(46%) saturate(3345%) hue-rotate(249deg) brightness(99%) contrast(104%);
}

.user-dashboard-second-navigation-button:hover {
  background-color: #edf3f9;
  transition: 0.3s;
}

.dashboard-grid-view-element {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  gap: 8px;
  border: 1px solid #e2e8f0;
  cursor: pointer;
  transition: 0.3s;
}

.dashboard-grid-view-element:active,
.dashboard-grid-view-element:hover {
  box-shadow: 0px 0px 30.799999237060547px -3px #00000059;
  transition: 0.3s;
}

.dashboard-grid-view-top-block {
  width: 100%;
  background-color: #edf3f9;
  display: flex;
  justify-content: center;
  height: 180px;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

.dashboard-grid-view-bottom-block {
  display: flex;
  align-items: start;
  gap: 8px;
  justify-content: space-between;
}

.dashboard-grid-view-bottom-left-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 140px);
}

.dashboard-grid-view-bottom-right-block {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}

.dashboard-grid-view-bottom-left-name {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-mask-image: linear-gradient(90deg, #000 80%, transparent);
}

.dashboard-grid-view-bottom-left-types {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dashboard-grid-view-content-type-dynamic-block {
  height: 24px;
  padding: 0px 6px;
  border-radius: 4px;
  border: 1px solid #9747ff;
  gap: 4px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #9747ff;
  display: flex;
  align-items: center;
}

.dashboard-grid-view-code-type-block {
  height: 24px;
  padding: 0px 6px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  gap: 4px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #7d8898;
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: fit-content;
}

.dashboard-grid-view-code-type-icon {
  filter: invert(59%) sepia(6%) saturate(831%) hue-rotate(176deg) brightness(88%) contrast(89%);
}

.dashboard-view-grid-code-expired {
  height: 24px;
  border-radius: 4px;
  border: 1px solid #f87171;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #f87171;
  padding: 0px 6px;
}

.dashboard-grid-view-blue-button {
  height: 32px;
  padding: 8px 12px;
  border-radius: 100px;
  gap: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  width: fit-content;
  cursor: pointer;
  transition: 0.2s;
}

.dashboard-grid-view-blue-button:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.dashboard-grid-view-button-gradient {
  height: 32px;
  padding: 8px 12px;
  border-radius: 100px;
  gap: 8px;
  background: linear-gradient(to right, #eb72a5 0%, #9747ff 100%);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: 0.2s;
}

.dashboard-grid-view-button-gradient:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    radial-gradient(100% 779.34% at 0% 0%, #eb72a5 0%, #9747ff 100%);
  transition: 0.2s;
}

.dashboard-grid-view-top-static-image {
  margin: auto 0;
  width: 140px;
}

.code-block-page-preview-disable-margins {
  background-color: #ffffff;
  margin-top: 16px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.code-block-page-preview-disable-margins>div {
  zoom: 0.6;
}

.code-block-page-preview-disable-margins .mp3-preview-wrapper,
.code-block-page-preview-disable-margins .video-preview-wrapper,
.code-block-page-preview-disable-margins .image-preview-wrapper,
.code-block-page-preview-disable-margins .wifi-preview-wrapper,
.code-block-page-preview-disable-margins .pdf-preview-wrapper,
.code-block-page-preview-disable-margins .text-preview-wrapper {
  margin-top: 0 !important;
}

.dashboard-content-line-table {
  width: 100%;
}

.dashboard-content-line-table thead {
  border-top: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
}

.dashboard-content-line-table thead th {
  height: 54px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.analytics-header-reset-all-changes:disabled {
  opacity: 0.5;
}

.dashboard-content-line-table tbody td {
  height: 54px;
  border-top: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.dashboard-content-line-table tbody .code-block-name {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(90deg, #000 80%, transparent);
}

.dashboard-active-dynamic-code-green-circle-wrapper {
  width: 15px;
  height: 15px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
}

.dashboard-active-dynamic-code-green-circle {
  width: 6px;
  height: 6px;
  min-width: 6px;
  background-color: #27ae60;
  border-radius: 100px;
}

.dashboard-list-view-element-active-text {
  display: flex;
  align-items: center;
  gap: 8px;
}

.code-block-fill-type-icon.paused,
.code-block-fill-type-icon.active {
  filter: invert(25%) sepia(99%) saturate(1835%) hue-rotate(251deg) brightness(110%) contrast(101%);
}

.code-block-fill-type-icon.expired {
  filter: invert(63%) sepia(71%) saturate(1442%) hue-rotate(312deg) brightness(95%) contrast(105%);
}

.code-block-fill-type-icon.static {
  filter: invert(62%) sepia(7%) saturate(750%) hue-rotate(176deg) brightness(85%) contrast(89%);
}

.dashboard-content-line-table tbody tr:hover {
  background-color: #f7fafe;
  transition: 0.2s;
  cursor: pointer;
}

.dashboard-list-view-status-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dashboard-code-trend-wrapper {
  height: 50px;
  width: 180px;
}

.dashboard-list-view-hover-options {
  display: none;
}

.dashboard-content-line-table tbody tr:hover .dashboard-code-trend-wrapper {
  display: none;
}

.dashboard-content-line-table tbody tr:hover .dashboard-list-view-hover-options {
  display: flex;
  justify-content: end;
  gap: 8px;
}

.dashboard-list-view-scans-count.inactive {
  color: #bec5cf;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px 40px 16px;
  gap: 5px;
}

.pagination-prev-next-button {
  height: 36px;
  padding: 12px 8px;
  border-radius: 4px;
  border: 1px;
  gap: 11px;
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #141315;
  background-color: white;
  user-select: none;
}

.pagination-prev-next-button:disabled {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bec5cf;
}

.pagination-number {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  background-color: white;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #141315;
  user-select: none;
}

.pagination-prev-next-button:hover,
.pagination-number:hover {
  border: 1px solid #141315;
}

.pagination-prev-next-button:disabled:hover {
  border: 1px solid #e2e8f0;
}

.pagination-number.active:hover {
  border: 1px solid #9747ff;
}

.pagination-number.active {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #9747ff;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
}

.code-block-icon-black {
  filter: invert(25%) sepia(17%) saturate(901%) hue-rotate(176deg) brightness(88%) contrast(53%);
}

.settings-wrapper {
  display: flex;
  width: 100%;
}

.settings-content-wrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #1b003e;
  padding: 0px 8px 8px 0;
}

.settings-navigation-wrapper {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background-color: #e0c8ff;
  padding: 8px;
  border-top-left-radius: 8px;
  min-width: 240px;
}

.settings-navigation-item {
  width: 100%;
  height: 44px;
  padding: 0px 16px;
  border-radius: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.settings-navigation-item:hover {
  background-color: #ccaef2;
}

.settings-navigation-item.active {
  background-color: #1b003e;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #e0c8ff;
}

.settings-navigation-item.active img {
  filter: invert(71%) sepia(74%) saturate(228%) hue-rotate(212deg) brightness(99%) contrast(105%);
}

.settings-content-tabs {
  width: 100%;
  background-color: #ffffff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: calc(100vh - 68px);
  overflow-y: auto;
}

.settings-tab-wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.settings-tab-title {
  font-family: "Inter";
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
  margin-bottom: 8px;
}

.settings-tab-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d8898;
}

.settings-tab-information-block {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.settings-tab-upload-photo-block {
  display: flex;
  align-items: center;
  gap: 16px;
}

.profile-settings-uploaded-photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #bec5cf;
  border-radius: 8px;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.profile-settings-default-image {
  filter: invert(88%) sepia(5%) saturate(432%) hue-rotate(176deg) brightness(90%) contrast(88%);
}

.profile-settings-upload-photo-button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9747ff;
  cursor: pointer;
}

.profile-settings-user-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profile-settings-inputs-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.profile-settings-input-wrapper {
  width: 100%;
  height: 56px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: relative;
}

.profile-settings-input-wrapper.empty {
  display: flex;
  align-items: center;
}

.profile-settings-input-wrapper.empty .profile-settings-input-label {
  display: none;
}

.profile-settings-input-wrapper.empty .profile-settings-input {
  margin-bottom: 0;
}

.profile-settings-input-wrapper.disabled {
  background-color: #f7fafe;
  position: relative;
}

.profile-settings-input-wrapper.disabled input {
  pointer-events: none;
  background-color: #f7fafe;
  color: #7d8898;
}

.profile-settings-input-wrapper.disabled label {
  color: #7d8898;
}

.profile-settings-input-google-image {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.profile-settings-input {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
  padding-left: 2px !important;
  height: 22px;
  margin-bottom: -4px;
  width: calc(100% - 30px);
}

.profile-settings-input::placeholder {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bec5cf;
}

.profile-settings-input:focus,
.profile-settings-input:focus-visible {
  border: 1px solid transparent !important;
  outline: none;
}

.profile-settings-input-label {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d8898;
  position: absolute;
  top: 8px;
  left: 16px;
}

.profile-settings-buttons-group {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  gap: 8px;
}

.profile-settings-cancel-button {
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #141315;
  gap: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.profile-settings-save-button {
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.settings-tab-delete-account {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
}

.profile-settings-save-button:disabled {
  opacity: 0.35;
}

.profile-settings-delete-account-button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #f87171;
}

.profile-settings-delete-account-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d8898;
}

.notification-settings-switch-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  border: 1px solid #e2e8f0;
  margin-top: 24px;
  border-radius: 8px;
}

.notification-settings-left-side {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notification-settings-title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.notification-settings-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d8898;
}

.setting-tab-sub-title {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
  margin-top: 24px;
  margin-bottom: 16px;
}

.notification-settings-switch-group-block {
  border: 1px solid #e2e8f0;
  border-radius: 16px;
}

.notification-settings-switch-group-block-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  gap: 8px;
}

.notification-settings-switch-group-block-item:last-child {
  border-bottom: none;
}

.profile-settings-analytics-group {
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.profile-settings-analytics-check-item-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-settings-analytics-check-text {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profile-settings-checkbox {
  min-width: 22px;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  background-color: #ffffff;
  border: 2px solid #bec5cf;
  cursor: pointer;
}

.profile-settings-checkbox.checked {
  min-width: 22px;
  width: 22px;
  height: 22px;
  border-radius: 50px;
  background-color: #7f2fe7;
  border: 2px solid #7f2fe7;
}

.profile-settings-checkbox.checked::before {
  content: "";
  width: 17px;
  height: 17px;
  display: block;
  position: relative;
  top: 1px;
  left: 1px;
  border: 3px solid #ffffff;
  border-radius: 50px;
}

.profile-settings-analytics-check-item-block {
  padding: 16px;
  padding-top: 0px;
}

.scheduled-notifications-setup {
  margin-left: 28px;
  width: fit-content;
  height: 56px;
  padding: 8px 16px;
  border-radius: 8px;
  gap: 8px;
  background-color: #edf3f9;
  display: flex;
  align-items: center;
  gap: 8px;
}

.schedule-notifications-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.schedule-notifications-time-picker {
  width: 70px;
  height: 40px;
  border-radius: 8px !important;
  gap: 4px;
  background-color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
  border: 1px solid #e2e8f0 !important;
}

.schedule-notifications-time-picker:focus,
.schedule-notifications-time-picker:focus-visible {
  border: 1px solid #e2e8f0 !important;
  outline: none;
}

.schedule-notifications-timezone-wrapper {
  position: relative;
}

.schedule-notifications-timezone-picker {
  width: 80px;
  height: 40px;
  border-radius: 8px !important;
  gap: 4px;
  background-color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
  border: 1px solid #e2e8f0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}

.schedule-notification-timezone-menu {
  position: absolute;
  top: 41px;
  right: 0px;
  border-radius: 8px !important;
  gap: 4px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  user-select: none;
  padding: 4px;
}

.schedule-notification-timezone-menu-item {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
  padding: 2px 8px;
  user-select: none;
  cursor: pointer;
}

.schedule-notification-timezone-menu-item:hover {
  background-color: #eef3f8;
  border-radius: 4px;
}

.profile-settings-uploaded-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.payments-and-plan-settings-subtitle {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 8px;
}

.payments-and-plan-subscription-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.subscription-status-title {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #7d8898;
}

.subscription-status-block {
  width: fit-content;
  height: 24px;
  padding: 8px;
  border-radius: 100px;
  border: 1px solid #00a066;
  gap: 4px;
  display: flex;
  align-items: center;
}

.subscription-status-green-dot {
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background-color: #009864;
}

.subscription-status-text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  color: #00a066;
}

.subscription-current-plan-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background: linear-gradient(90deg, #eb72a5 0%, #9747ff 100%);
  padding: 16px 24px 16px 16px;
  border-radius: 8px;
}

.subscription-current-plan-left-side-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subscription-current-plan-right-side-block {
  display: flex;
  align-items: center;
  gap: 16px;
}

.subscription-current-plan-name {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: start;
  color: #ffffff;
}

.subscription-current-plan-price {
  font-family: "Inter";
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: start;
  color: #ffffff;
}

.subscription-current-plan-cancel-button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.subscription-current-plan-change-button {
  width: fit-content;
  height: 44px;
  padding: 8px 16px;
  border-radius: 8px;
  gap: 8px;
  background-color: #ffffff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #141315;
}

.subscription-current-plan-change-button:hover {
  background-color: #f7fafe;
}

.user-icon-settings-navigation {
  width: 70px;
  height: 52px;
  background-color: #e0c8ff;
  position: absolute;
  bottom: 8px;
  left: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  padding-left: 6px;
}

.user-icon-settings-navigation.expanded {
  width: 240px;
}

.user-icon-settings-navigation.expanded .dashboard-navigation-bottom-user-info-name,
.user-icon-settings-navigation.expanded .dashboard-navigation-bottom-user-info-plan {
  color: #141315;
}

.schedule-notification-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.settings-tab-underline {
  width: calc(100% + 48px);
  height: 1px;
  background-color: #e2e8f0;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: -24px;
}

.settings-tab-payment-methods-header-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: -8px;
}

.settings-tab-payment-methods-title {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
}

.settings-tab-payment-method-add-btn {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
  color: #9747ff;
}

.settings-tab-payment-method-item-block {
  width: 100%;
  height: 76px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  gap: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.settings-tab-payment-method-item-left-side {
  display: flex;
  align-items: center;
  gap: 12px;
}

.settings-tab-payment-method-item-right-side {
  display: flex;
  align-items: center;
  gap: 16px;
}

.settings-tab-payment-method-item-paypal-icon {
  width: 36px;
}

.settings-tab-payment-method-item-information {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.settings-tab-payment-method-item-information-name {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: start;
  color: #141315;
}

.settings-tab-payment-method-item-information-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: start;
  color: #141315;
}

.settings-tab-payment-method-action-icon {
  height: 18px;
  cursor: pointer;
}

.settings-tab-payment-method-item-stripe-icon {
  width: 40px;
}

.settings-tab-payment-method-edit-actions-block {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}

.settings-tab-payment-edit-cancel {
  width: fit-content;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #141315;
  gap: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
}

.settings-tab-payment-edit-save {
  width: fit-content;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.settings-edit-method-make-default-block {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.settings-edit-method-make-default-label {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.settings-edit-method-default-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #141315 !important;
  border-radius: 4px;
}

.settings-edit-method-default-checkbox:focus {
  border: 2px solid #141315 !important;
}

.settings-edit-method-default-checkbox:checked {
  background-color: #9747ff !important;
  border: 2px solid #9747ff !important;
}

.credit-card-form-wrapper {
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
  gap: 16px;
}

.credit-card-input-block {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
}

.credit-card-input-label {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d8898;
}

.profile-settings-password-eye {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

.enter-password-modal-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  position: relative;
}

.enter-password-modal-content-title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.enter-password-modal-close {
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
}

.enter-password-modal-send-button {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.corner-border-settings-navigation {
  position: absolute;
  left: 0px;
  bottom: 60px;
  background-color: #e0c8ff;
  width: 64px;
  height: 60px;
  z-index: 2;
}

.corner-border-settings-navigation.expanded {
  width: 240px;
}

.corner-border-settings-purple-background {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 8px;
  background-color: #1b003e;
}

.credit-card-cvc-image {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.settings-tab-payment-method-default-btn {
  width: 118px;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background-color: #edf3f9;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #7d8898;
}

.settings-tab-payment-method-set-default-btn {
  min-width: 118px;
  height: 44px;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #141315;
  gap: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
}

.enter-password-modal-cancel-button {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #141315;
  gap: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
}

.user-feedback-modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.user-feedback-header-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.user-feedback-header-title {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #141315;
}

.user-feedback-rating-block {
  display: flex !important;
  align-items: center;
  gap: 12px;
}

.user-feedback-input {
  width: 100%;
  height: 180px;
  padding: 8px 12px;
  resize: none;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.user-feedback-input:focus,
.user-feedback-input:focus-visible,
.user-feedback-input:hover,
.user-feedback-input:active {
  border: 1px solid transparent !important;
}

.user-feedback-input::placeholder {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bec5cf;
}

.user-feedback-input-wrapper {
  position: relative;
  padding-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #e2e8f0 !important;
  overflow: hidden;
}

.user-feedback-characters-used {
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.user-feedback-buttons-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.user-feedback-send-button {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.user-feedback-send-button:disabled {
  opacity: 0.35;
}

.user-feedback-later-button {
  width: 100%;
  border-radius: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
}

.user-feedback-sent-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.user-feedback-sent-title {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #141315;
}

.user-feedback-sent-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #7d8898;
}

.user-feedback-modal .max-w-md .rounded-lg {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
}

.user-feedback-modal .max-w-md {
  display: flex;
  align-items: center;
}

.overview-panel-info-link {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9747ff;
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 300px;
}

.overview-panel-info-link:hover {
  color: #7f2fe7;
}

.overview-panel-code-view-fullscreen {
  width: 100%;
  min-height: 36px;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-top: none;
  gap: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
  border-radius: 0 0 8px 8px;
}

.overview-panel-code-view-fullscreen:hover {
  background-color: #f7fafe;
}

.overview-panel-stats-see-full-button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9747ff;
}

.overview-panel-stats-period-current {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-align: center;
  color: #7d8898;
  text-transform: uppercase;
}

.overview-panel-stats-period-week-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.overview-panel-stats-period-week-next-prev-block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.overview-panel-stats-week-next-prev-btn {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-panel-stats-week-next-prev-btn:disabled {
  opacity: 0.6;
}

.overview-panel-header-filters-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.view-fullscreen-code-modal-body {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
}

.view-fullscreen-code-modal .max-w-lg .rounded-lg {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
}

.view-fullscreen-code-modal .max-w-lg {
  display: flex;
  align-items: center;
  max-width: 600px;
}

.view-fullscreen-code-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
}

.view-fullscreen-code-preview-block {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  margin: 0 auto;
}

.view-fullscreen-code-preview-block img {
  border: 1px solid #e2e8f0;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
}

.view-fullscreen-code-download {
  width: 100%;
  height: 56px;
  padding: 8px 16px;
  gap: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  border-radius: 0 0 6px 6px;
  transition: 0.2s;
}

.view-fullscreen-code-download:hover {
  transition: 0.2s;
  background-color: #7f2fe7;
}

.view-fullscreen-code-link-block {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  border-radius: 8px;
  background-color: #f7fafe;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.view-fullscreen-code-landing-page-btn {
  min-width: 173px;
  height: 44px;
  border-radius: 8px;
  border: 0.5px solid #141315;
  gap: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
  transition: 0.2s;
  padding: 0 8px;
}

.view-fullscreen-code-landing-page-btn:hover {
  border: 0.5px solid #bec5cf;
  transition: 0.2s;
}

.view-fullscreen-code-link-url {
  width: calc(100% - 170px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view-fullscreen-code-close {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.view-fullscreen-code-close:hover {
  background-color: #edf3f9;
  transition: 0.2s;
}

.view-fullscreen-code-close:hover svg {
  color: #141315 !important;
  transition: 0.2s;
}

.overview-code-page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #1b003e;
  padding: 8px;
}

.overview-code-page-content {
  width: 100%;
  height: calc(100vh - 16px);
  padding: 24px 0px;
  background-color: #edf3f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.profile-settings-uploaded-photo-remove {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #9747ff;
}

.profile-setting-uploaded-photo-circle-actions {
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background-color: #bec5cf;
}

.setting-navigation-item-mobile-arrow {
  display: none;
}

.payments-and-plan-change-item {
  width: 100%;
  height: 77px;
  padding: 16px;
  gap: 14px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  cursor: pointer;
}

.payments-and-plan-change-item-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.payments-and-plan-change-item-title {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.payments-and-plan-change-item-price {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.payments-and-plan-change-buttons-block {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
}

.payments-and-plan-change-cancel {
  min-width: 72px;
  height: 44px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #141315;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
  padding: 0 16px;
}

.payments-and-plan-change-save {
  min-width: 121px;
  height: 44px;
  gap: 8px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0 16px;
}

.payments-and-plan-change-save:disabled {
  opacity: 0.35;
}

.subscription-current-auto-renews-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d8898;
}

.payments-and-plans-change-item-save-money {
  min-width: 73px;
  height: 24px;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #27ae60;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #27ae60;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}

.onboarding-modal .max-w-xl .rounded-lg {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  max-height: 95dvh;
}

.onboarding-modal .max-w-xl {
  display: flex;
  align-items: center;
  max-width: 745px;
}

.onboarding-modal-body {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.onboarding-modal-content-block {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  padding: 16px;
}

.onboarding-modal-footer-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-top: 1px solid #e2e8f0;
  padding: 16px;
}

.onboarding-footer-skip-button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #9747ff;
  transition: 0.2s;
}

.onboarding-footer-skip-button:hover {
  color: #7f2fe7;
  transition: 0.2s;
}

.onboarding-footer-get-started-button {
  height: 44px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  transition: 0.2s;
}

.onboarding-footer-get-started-button:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.onboarding-content-text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  max-width: 340px;
}

.onboarding-content-images-block {
  position: relative;
}

.onboarding-content-text-title {
  font-family: "Inter";
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
}

.onboarding-content-text-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d8898;
  padding-right: 20px;
}

.onboarding-content-images-edit-icon {
  position: absolute;
  top: 4px;
  right: -4px;
}

.onboarding-content-images-navigation-icon {
  position: absolute;
  top: 40px;
  left: -40px;
}

.onboarding-content-images-notification-icon {
  position: absolute;
  top: 50%;
  left: -25px;
}

.onboarding-content-images-trend-icon {
  position: absolute;
  bottom: 0px;
  left: -135px;
}

.onboarding-content-codes-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-top: 8px;
}

.onboarding-content-code-item {
  height: 54px;
  padding: 8px 16px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  transition: 0.2s;
}

.onboarding-content-code-item:hover {
  border-color: #141315;
  transition: 0.2s;
}

.onboarding-content-code-item.active {
  border: 1px solid #9747ff;
  transition: 0.2s;
}

.onboarding-content-code-name {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.onboarding-footer-first-step-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.onboarding-footer-back-button {
  min-width: 58px;
  height: 44px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #141315;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #141315;
  transition: 0.2s;
}

.onboarding-footer-back-button:hover {
  border-color: #bec5cf;
  transition: 0.2s;
}

.onboarding-footer-next-button {
  height: 44px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  transition: 0.2s;
}

.onboarding-footer-next-button:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.onboarding-footer-steps-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
}

.onboarding-footer-step-indicator-circle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background-color: #e2e8f0;
  transition: 0.2s;
}

.onboarding-footer-step-indicator-circle.active {
  background-color: #141315;
  transition: 0.2s;
}

.onboarding-content-first-step-block {
  position: relative;
  background-color: #f5edff;
  padding: 24px;
  padding-top: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.onboarding-first-step-image {
  margin: 0 -40px;
  transition: 0.3s;
  width: 289px;
}

.onboarding-first-step-image.small {
  transform: scale(0.6);
  transition: 0.3s;
  transform-origin: 50% 50%;
}

.onboarding-first-step-progress-block {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 4px;
}

.onboarding-first-step-progress-item {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #ffffff;
  transition: 0.2s;
}

.onboarding-first-step-progress-item.active {
  width: 35px;
  height: 6px;
  border-radius: 6px;
  background-color: #ffffff;
  transition: 0.2s;
}

.onboarding-first-step-description {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #141315;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 250px;
  width: 100%;
}

.onboarding-first-step-progress-item-fill {
  display: block;
  width: 0;
  max-width: 35px;
  height: 6px;
  border-radius: 6px;
  background-color: #9747ff;
  animation-duration: 3.5s;
  animation-name: onboarding-progress-fill;
  z-index: 2;
}

@keyframes onboarding-progress-fill {
  0% {
    width: 0;
  }

  100% {
    width: 35px;
  }
}

.onboarding-content-abilities-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #edf3f9;
  border-radius: 8px;
  margin-top: 8px;
}

.onboarding-content-ability-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.onboarding-content-ability-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #141315;
}

.onboarding-content-images-scans-activity-icon {
  position: absolute;
  top: -4px;
  left: 24px;
}

.onboarding-content-images-grey-code-icon {
  position: absolute;
  top: 60%;
  left: -46px;
}

.onboarding-content-images-scans-by-browser-icon {
  position: absolute;
  bottom: 15px;
  left: 0px;
}

.onboarding-content-mobile-first-step-header {
  display: none;
}

.onboarding-welcome-image-desktop {
  display: block;
  max-height: 470px;
}

.onboarding-welcome-image-mobile {
  display: none;
}

.header-notifications-active {
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 5px);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: radial-gradient(100% 779.34% at 0% 0%, #eb72a5 0%, #9747ff 100%);
  border: 1px solid #1b003e;
}

.header-notifications-block.opened .header-notifications-active {
  border: 1px solid #e0c8ff;
}

.notifications-panel-wrapper {
  position: absolute;
  top: 60px;
  right: 8px;
  width: 450px;
  height: calc(100vh - 68px);
  background-color: #ffffff;
  box-shadow: 0px 0px 24px 0px #0000001f;
  z-index: 4;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: hidden;
}

.notifications-panel-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #ffffff;
  border-bottom: 1px solid #e2e8f0;
}

.notification-panel-header-top-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.notification-panel-header-title-block {
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
}

.notification-panel-header-title {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
}

.notification-panel-header-count {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #9747ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5px;
  text-align: left;
  color: #ffffff;
}

.notification-panel-header-mark-as-read-block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.notification-panel-header-mark-as-read-btn {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: start;
  color: #9747ff;
}

.notification-panel-header-mark-as-read-btn:disabled {
  opacity: 0.5;
}

.notification-panel-header-back-button {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.notification-panel-header-bottom-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 44px;
  padding: 4px;
  gap: 4px;
  border-radius: 8px;
  background-color: #edf3f9;
}

.notification-panel-type-switch-item {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  width: 100%;
  height: 100%;
  color: #7d8898;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}

.notification-panel-type-switch-item:hover {
  color: #141315;
  transition: 0.2s;
}

.notification-panel-type-switch-item.active {
  width: 100%;
  height: 36px;
  border-radius: 6px;
  background-color: #ffffff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #9747ff;
  transition: 0.2s;
}

.notifications-panel-content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 129px);
  overflow-y: auto;
  align-items: center;
}

.notifications-panel-content.empty {
  justify-content: center;
}

.notifications-panel-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  user-select: none;
}

.notifications-panel-empty img {
  width: 45px;
}

.notifications-panel-empty-title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #bec5cf;
}

.notifications-panel-list-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notifications-panel-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  gap: 8px;
  border-bottom: 1px solid #e2e8f0;
}

.notification-item-icon {
  min-width: 44px;
  height: 44px;
  width: 44px;
}

.notifications-panel-item.just-read-notification .notification-panel-item-main-info-block .notification-item-icon,
.notifications-panel-item.just-read-notification .notification-panel-item-main-info-block .notifications-panel-item-title,
.notifications-panel-item.just-read-notification .notification-panel-item-main-info-block .notifications-panel-item-description,
.notifications-panel-item.just-read-notification .notification-panel-item-main-info-block .notifications-panel-item-time,
.notifications-panel-item.just-read-notification .notification-panel-item-button {
  opacity: 0.5;
}

.notifications-panel-item-information {
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  position: relative;
}

.notifications-panel-item-title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #141315;
  word-break: break-all;
}

.notifications-panel-item-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #141315;
}

.notifications-panel-item-time {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #7d8898;
  margin-top: 8px;
}

.notification-panel-item-unread {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #9747ff;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.notifications-panel-item.unread {
  background-color: #f5edff;
  border-bottom: 1px solid #e2e8f0;
}

.notification-panel-item-button {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  background-color: #9747ff;
  margin-top: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}

.notification-panel-item-main-info-block {
  display: flex;
  align-items: start;
  gap: 12px;
}

.notifications-panel-item-many-countries-block {
  min-width: 44px;
  display: flex;
}

.notifications-panel-item-many-countries-block img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.notifications-panel-item-many-countries-block img:nth-child(1) {
  margin-right: -9px;
  z-index: 1;
}

.notifications-panel-item-many-countries-block img:nth-child(2) {
  margin-right: -9px;
  z-index: 2;
}

.notifications-panel-item-many-countries-block img:nth-child(3) {
  margin-right: -9px;
  z-index: 3;
}

.notification-panel-item-read-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #27ae60;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.notifications-panel-item:hover,
.notifications-panel-item.unread:hover {
  background-color: #f7fafe;
}

.notifications-panel-item:hover .notification-panel-item-read-circle {
  display: none;
}

.notifications-panel-item:hover .notification-panel-item-unread {
  display: none;
}

.notifications-panel-item-more-options-block {
  width: 44px;
  height: 44px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
}

.notifications-panel-item-more-options-block img {
  filter: invert(56%) sepia(15%) saturate(355%) hue-rotate(176deg) brightness(92%) contrast(92%);
  transition: 0.2s;
}

.notifications-panel-item-more-options-block:hover img {
  filter: none;
  transition: 0.2s;
}

.notifications-panel-item:hover .notifications-panel-item-more-options-block {
  display: flex;
}

.notifications-panel-item-more-options-block:hover {
  background-color: #edf3f9;
}

.notification-panel-item-dropdown {
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translateY(-50%);
  z-index: 4;
}

.notification-panel-item-dropdown .dropdown-item {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  color: #141315;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  padding: 0 12px;
  gap: 12px;
  width: 232px;
}

.notification-panel-item-dropdown .dropdown-item:hover {
  background-color: #edf3f9;
  border-radius: 8px;
}

.notification-panel-item-dropdown .dropdown-menu {
  padding: 4px !important;
  z-index: 3;
}

.notifications-panel-header-mobile-back {
  display: none;
}

.desktop-hide {
  display: none;
}

.padding-top-24 {
  padding-top: 24px !important;
}

.confirm-account-deletion-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.confirm-account-deletion-modal-title {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #141315;
}

.confirm-account-deletion-modal-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #141315;
}

.profile-settings-deletion-input {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #141315;
  padding-left: 0 !important;
  width: calc(100% - 20px);
}

.profile-settings-deletion-input:focus {
  outline-color: transparent !important;
  border-color: transparent !important;
}

.profile-settings-deletion-input::placeholder {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #7d8898;
}

.confirm-account-deletion-modal-button {
  width: 100%;
  height: 44px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #ffffff;
  margin-top: 8px;
}

.confirm-account-deletion-modal-button:disabled {
  opacity: 0.35;
}

.confirm-account-deletion-modal-keep {
  width: 100%;
  height: 44px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #141315;
  margin-top: -16px;
  margin-bottom: -8px;
}

.account-deletion-feedback-modal-selected-reason {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.account-deletion-feedback-modal-selected-reason.select {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #7d8898;
}

.confirm-account-deletion-modal-reason-dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  top: 55px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  height: 224px;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0px 9px 18px 0px #00000026;
  z-index: 2;
}

.account-deletion-feedback-modal-reason {
  height: 36px;
  padding: 0px 12px;
  gap: 16px;
  border-radius: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  color: #141315;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}

.account-deletion-feedback-modal-reason:hover {
  background-color: #edf3f9;
  transition: 0.2s;
}

.confirm-account-deletion-model-other-input-block {
  width: 100%;
  height: 150px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  position: relative;
}

.account-deletion-reason-block-active {
  border: 1px solid #e0c8ff;
}

.confirm-account-deletion-other-textarea-field {
  resize: none;
  height: calc(100% - 30px);
  width: 100%;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #141315;
  padding: 0;
}

.confirm-account-deletion-other-textarea-field:focus {
  outline-color: transparent !important;
  border-color: transparent !important;
}

.confirm-account-deletion-other-textarea-field::placeholder {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #bec5cf;
}

.confirm-account-deletion-modal-text-length {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: end;
  color: #141315;
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.overflow-unset-important {
  overflow: unset !important;
}

.settings-tab-user-information-block {
  display: flex;
  align-items: center;
  gap: 16px;
}

.settings-tab-user-information-edit-btn {
  width: fit-content;
  height: 44px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #ffffff;
}

.settings-tab-user-avatar {
  width: 184px;
  height: 184px;
  border-radius: 8px;
}

.settings-tab-user-profile-information {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.settings-tab-user-profile-information-item {
  display: flex;
  flex-direction: column;
}

.settings-tab-user-profile-information-label {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #7d8898;
}

.settings-tab-user-profile-information-value {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #141315;
}

.user-profile-information-value-password-placeholder-circle {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #141315;
}

.user-information-profile-image-wrapper {
  position: relative;
}

.user-information-profile-image-change-block {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  color: #ffffff;
}

.user-information-profile-image-wrapper:hover .user-information-profile-image-change-block {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000080;
  cursor: pointer;
}

.settings-tab-user-information-edit-cancel {
  width: fit-content;
  height: 44px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #141315;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #141315;
}

.settings-select-language-container {
  position: relative;
}

.profile-settings-select-language-search-block {
  width: 100%;
  height: 44px;
  padding: 16px 12px;
  border-radius: 8px;
  background-color: #edf3f9;
  display: flex;
  align-items: center;
  gap: 8px;
}

.profile-settings-select-language-search-input {
  width: 100%;
  height: 100%;
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  background-color: transparent !important;
  color: #141315;
  padding-left: 0 !important;
}

.profile-settings-select-language-search-input:focus {
  outline-color: transparent !important;
  border-color: transparent !important;
}

.profile-settings-select-language-search-input::placeholder {
  color: #7d8898;
}

.testimonials-section-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f5edff;
  padding: 80px 69px;
  gap: 40px;
  overflow: hidden;
}

.testimonials-section-wrapper .swiper-slide {
  display: flex;
  height: initial;
}

.testimonials-section-title {
  font-family: "Inter";
  font-size: 33px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: center;
  width: 100%;
  color: #141315;
}

.testimonials-section-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #141315;
  margin-top: -24px;
}

.testimonials-section-carousel-wrapper {
  max-width: 1095px;
  display: flex;
  align-items: center;
  margin: 0 auto !important;
  gap: 16px;
  position: relative;
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.testimonials-carousel-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.testimonial-item {
  width: 342px;
  min-height: 416px;
  padding: 40px;
  gap: 16px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 6px 12px 0px #0000001c;
}

.testimonial-item-name {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
}

.testimonial-item-stars-block {
  display: flex;
  align-items: center;
  gap: 1px;
  margin-top: 4px;
}

.testimonial-item-underline {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e2e8f0;
  margin: 16px 0;
}

.testimonial-item-text {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #141315;
}

.carousel-control-item {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.carousel-control-item.left {
  position: absolute;
  left: -45px;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-control-item.right {
  position: absolute;
  right: -45px;
  top: 50%;
  transform: rotate(180deg) translateY(50%);
}

.mobile-slide-indicators-block {
  display: none;
}

.swiper.swiper-initialized {
  padding: 20px 0;
  padding-left: 10px;
}

.static-toast-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.static-toast-convert-button {
  width: calc(100% - 32px);
  height: 32px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 100px;
  background-color: rgba(151, 71, 255, 1);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  left: 16px;
  bottom: 16px;
  transition: 0.2s;
}

.static-toast-convert-button:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.static-toast-block-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  padding-right: 30px;
}

.static-toast-close-button {
  position: absolute;
  right: 14px;
  transform: translateY(-50%);
  top: calc(50% - 20px);
}

.toast-gradient.dynamic-created {
  display: flex;
  align-items: center;
}

.color-palette-list {
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 8px;
  column-gap: 16px;
  margin-top: 8px;
  width: calc(100% - 8px);
}

.color-palette-item {
  width: 100%;
  height: 124px;
  padding: 8px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.color-palette-item.active {
  border: 2px solid #9747ff;
}

.color-palette-item-color-block {
  width: 100%;
  height: 24px;
  border-radius: 4px;
}

.color-palette-item-selected-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(45%, -45%);
  background-color: white;
  border-radius: 50%;
}

.convert-static-codes-to-dynamic-modal .rounded-lg {
  border-radius: 16px;
}

.convert-static-codes-to-dynamic-modal .modal-dialog.modal-sm {
  max-width: 400px;
}

.convert-static-codes-to-dynamic-modal .modal-content {
  border-radius: 16px;
}

.convert-static-codes-to-dynamic-modal .modal-body {
  max-height: 90vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convert-static-codes-to-dynamic-modal-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.convert-static-codes-title {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #141315;
}

.convert-static-codes-title-grey {
  color: #bec5cf;
}

.convert-static-codes-title-pink {
  background: radial-gradient(180% 102.34% at 0% 0%, #eb72a5 0%, #9747ff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.convert-static-codes-header-block {
  display: flex;
  align-items: center;
}

.convert-static-codes-header-close {
  margin-left: auto;
  cursor: pointer;
  transition: 0.2s;
}

.convert-static-codes-header-back {
  cursor: pointer;
  transition: 0.2s;
}

.convert-static-codes-header-back:hover {
  color: #141315 !important;
  transition: 0.2s;
}

.convert-static-codes-header-close:hover {
  color: #141315 !important;
  transition: 0.2s;
}

.convert-static-codes-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #141315;
  margin-top: -8px;
}

.convert-static-codes-buttons-list {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.convert-static-codes-all-btn {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  z-index: 10;
  transition: 0.3s;
}

.convert-static-codes-all-btn:hover {
  background-color: #7f2fe7;
  transition: 0.3s;
}

.convert-static-codes-manually-btn {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #141315;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #141315;
  transition: 0.2s;
}

.convert-static-codes-manually-btn:hover {
  border-color: #e2e8f0;
  transition: 0.2s;
}

.convert-static-codes-not-show-btn {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #141315;
  margin-top: -8px;
}

.convert-static-codes-disclaimer-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #7d8898;
}

.convert-static-codes-disclaimer-title {
  font-weight: 600;
}

.select-codes-to-convert-search-block {
  position: relative;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background-color: #edf3f9;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
}

.select-codes-to-convert-search-input {
  border-color: transparent !important;
  outline-color: transparent !important;
  background-color: transparent !important;
  padding-left: 0 !important;
}

.select-codes-to-convert-search-input:focus {
  outline-color: transparent !important;
  border-color: transparent !important;
}

.select-codes-to-convert-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.select-codes-all-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: right;
  width: 100%;
  color: #9747ff;
  cursor: pointer;
  transition: 0.2s;
}

.select-codes-all-text:hover {
  transition: 0.2s;
  color: #7f2fe7;
}

.select-code-to-convert-checkbox {
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px !important;
  border: 2px solid #bec5cf !important;
  cursor: pointer;
}

.select-code-to-convert-checkbox:checked {
  border: 2px solid #9747ff !important;
}

.select-codes-to-convert-btn-convert {
  width: 100%;
  height: 44px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  transition: 0.2s;
}

.select-codes-to-convert-btn-convert:disabled {
  opacity: 0.35;
}

.select-codes-to-convert-btn-convert:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.design-customize-section-color-title {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.desktop-creation-flow {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 340px;
  min-height: calc(100vh - 68px);
}

.desktop-creation-left-side {
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 24px;
  border-right: 1px solid #e2e8f0;
  position: relative;
  overflow-y: auto;
  max-height: calc(100vh - 68px);
}

.desktop-creation-right-side {
  display: flex;
  flex-direction: column;
  padding: 40px;
  overflow-y: auto;
  max-height: calc(100vh - 68px);
}

.desktop-creation-select-type-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.desktop-creation-select-type-header-title {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #141315;
}

.desktop-creation-dynamic-switch-block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.desktop-creation-dynamic-switch-label {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  color: #141315;
}

.desktop-creation-codes-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.desktop-creation-code-item {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
  min-height: 95px;
  cursor: pointer;
  transition: 0.2s;
}

.desktop-creation-code-item:hover {
  background-color: #9747ff;
  border: 1px solid #9747ff;
  transition: 0.2s;
}

.desktop-creation-code-item:hover * {
  color: white !important;
  transition: 0.2s;
}

.desktop-creation-code-icon {
  width: 44px;
  height: 44px;
  min-width: 44px;
}

.desktop-creation-code-info-block {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.desktop-creation-code-name {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
}

.desktop-creation-code-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #141315;
}

.desktop-creation-preview-image {
  border-radius: 24px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 166px 66px rgba(0, 0, 0, 0.01),
    0px 93px 56px rgba(0, 0, 0, 0.02), 0px 41px 41px rgba(0, 0, 0, 0.03),
    0px 10px 23px rgba(0, 0, 0, 0.04);
}

.desktop-creation-step2-title {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: start;
  color: #7d8898;
  display: flex;
  align-items: center;
  gap: 16px;
  user-select: none;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 24px;
  transition: 0.2s;
}

.desktop-creation-step2-title-back-text:hover {
  color: #141315;
  transition: 0.2s;
}

.desktop-creation-step2-title-black {
  color: #141315;
}

.desktop-creation-generate-btn {
  width: 100%;
  height: 56px;
  padding: 8px 24px;
  gap: 16px;
  border-radius: 8px;
  background-color: #9747ff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  transition: 0.2s;
}

.desktop-creation-generate-btn-wrapper {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  margin-top: 24px;
}

.desktop-creation-design-header {
  width: 100%;
  height: 71px;
  padding: 24px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  left: 0;
  z-index: 5;
}

.desktop-creation-apply-design-block {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 24px 24px 24px;
  background-color: transparent;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-creation-apply-button {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background-color: #9747ff;
  transition: 0.2s;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.desktop-creation-apply-button:hover {
  background-color: #7f2fe7;
  transition: 0.2s;
}

.selected-type-description-authorized {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: left;
  color: #7d8898;
}

.upload-block-description-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: #7d8898;
  margin: 0;
  margin-bottom: -4px;
}

.mobile-creation-first-step-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #edf3f9;
}

.mobile-creation-select-codes-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  background-color: #ffffff;
}

.mobile-creation-select-codes-title {
  font-family: "Inter";
  font-size: 19px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #141315;
}

.mobile-creation-select-code-types {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  width: 100%;
}

.mobile-creation-select-code-type {
  height: 44px;
  padding: 0 8px;
  gap: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: fit-content;
  transition: 0.2s;
}

.mobile-creation-select-code-type.selected {
  background-color: #f5edff;
  border: 1px solid #9747ff;
  transition: 0.2s;
}

.mobile-creation-select-code-type img {
  width: 24px;
}

.mobile-creation-select-code-name {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #141315;
}

.mobile-creation-preview-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 225px);
  padding: 40px;
}

.mobile-creation-preview-image img {
  height: 100%;
}

.desktop-creation-design-edit-back-btn {
  gap: 16px;
}

.desktop-creation-design-edit-back-btn img {
  filter: invert(55%) sepia(21%) saturate(276%) hue-rotate(176deg) brightness(93%) contrast(86%);
  transition: 0.2s;
}

.desktop-creation-design-edit-back-btn:hover img {
  filter: invert(3%) sepia(2%) saturate(4336%) hue-rotate(227deg) brightness(101%) contrast(91%);
  transition: 0.2s;
}

.desktop-creation-design-edit-btn {
  filter: invert(55%) sepia(21%) saturate(276%) hue-rotate(176deg) brightness(93%) contrast(86%);
  transition: 0.2s;
}

.desktop-creation-design-edit-btn:hover {
  filter: invert(3%) sepia(2%) saturate(4336%) hue-rotate(227deg) brightness(101%) contrast(91%);
  transition: 0.2s;
}

.dashboard-navigation-settings-button {
  width: calc(100% - 12px);
  height: 36px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ffffff1a;
  background-color: #311951;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-settings-button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-dashboard-navigation-collapsed .dashboard-navigation-settings-button-text {
  display: none;
}

.landing-faq-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 50px;
  width: 100%;
}

.landing-faq-title {
  font-family: "Inter";
  font-size: 33px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #141315;
}

.reset-password-sent-modal .max-w-md,
.reset-password-modal .max-w-md {
  display: flex;
  width: 100%;
  align-items: center;
}

.reset-password-sent-modal .max-w-md .rounded-lg,
.reset-password-modal .max-w-md .rounded-lg {
  width: 100%;
}

.enter-password-modal .max-w-md {
  display: flex;
  align-items: center;
  width: 100%;
}

.cancel-plan-upgrade-to-yearly-block {
  background-color: #9747ff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
}

.cancel-plan-confirm-block {
  border: 1px solid #e2e8f0;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  height: 100%;
}

.modal-cancel-plan .modal-lg {
  max-width: 680px;
}

.modal-cancel-plan .modal-md {
  max-width: 420px;
}

.charge-page-background {
  width: 100%;
  height: 466px;
  min-height: 466px;
  background-image: url("../assets/images/charge-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
}

.charge-page-background-top-layer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #390084 0%, #9747ff 100%);
  width: 100%;
  height: 100%;
  filter: blur(40px);
  mix-blend-mode: hard-light;
}

.upgrade-plan-new-plan-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
  width: 100%;
  max-width: 1264px;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 24px;
}

.upgrade-plan-new-plan-header__title {
  font-size: 33px;
  line-height: 40px;
  font-weight: 800;
  letter-spacing: -0.02em;
  color: #141315;
}

.upgrade-plan-new-plan-header__steps {
  display: flex;
  align-items: center;
  grid: 8px;
  user-select: none;
}

.upgrade-plan-new-plan-header__step-item {
  height: 36px;
  border-radius: 8px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.upgrade-plan-new-plan-header__step-item-text {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #141315;
}

.upgrade-plan-new-plan-header__step-item-active {
  height: 36px;
  border-radius: 8px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #edf3f9;
}

.upgrade-plan-new-plan-header__step-item-next-text {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #7d8897;
}

.stripe-shadow-blocks {
  box-shadow: 0px 24px 52px 0px #1b003e14, 0px 94px 94px 0px #1b003e12,
    0px 212px 127px 0px #1b003e0a, 0px 377px 151px 0px #1b003e03,
    0px 590px 165px 0px #1b003e00;
}

.upgrade-plan-navigation-line {
  position: absolute;
  width: 60px;
  height: 44px;
  left: 10px;
  bottom: 117px;
  background-color: #ffffff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.upgrade-plan-navigation-line-top {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 46px;
  bottom: 44px;
  background-color: #ffffff;
}

.upgrade-plan-navigation-line-bottom {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 46px;
  top: 44px;
  background-color: #ffffff;
}

.upgrade-plan-navigation-line-top-inside {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 8px;
  background-color: #1b003e;
}

.upgrade-plan-navigation-line-bottom-inside {
  width: 100%;
  height: 100%;
  border-top-right-radius: 8px;
  background-color: #1b003e;
}

.create-code-help-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.create-code-help-modal .modal-dialog.modal-md {
  width: 100%;
  max-width: 400px;
  border-radius: 24px;
}

.create-code-help-modal .modal-content {
  border: none !important;
  border-radius: 24px !important;
}

.trial-period-radial-gradient {
  background: radial-gradient(100% 100% at 0% 0%, #EB72A5 0%, #9747FF 100%);
}

.embed-code-modal .modal-md {
  max-width: 480px !important;
  display: flex;
  padding-bottom: 20px;
  height: 100%;
}

.embed-code-modal .modal-content {
  margin: auto 0;
}

@media (max-width: 1400px) {
  .new-sign-in-up-form {
    padding: 0 20px;
  }
}

@media (max-width: 900px) {
  .embed-code-modal .modal-content {
    width: calc(100% - 24px);
    margin: auto;
  }

  .faq-section-header-mobile-close {
    display: block;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  .faq-section-header {
    width: 100%;
    height: 60px;
    padding: 16px;
    border-bottom: none;
    background-color: #1b003e;
    font-family: "Inter";
    font-size: 23px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #e0c8ff;
    position: relative;
  }

  .faq-section-wrapper {
    width: 100%;
    height: 100vh;
    max-height: unset;
    box-shadow: 0px 9px 18px 0px #00000026;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1b003e;
    overflow: hidden;
  }

  .faq-section-content {
    padding: 16px;
    overflow-y: auto;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 16px 16px 0 0;
    background-color: white;
  }

  .analytics-total-info-wrapper {
    padding: 0;
    gap: 0;
    border-bottom: 1px solid #e2e8f0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .analytics-total-info-block-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 0px;
    border: unset;
    width: 100%;
    height: unset;
    background-color: #ffffff;
    height: 100%;
  }

  .analytics-total-info-block-information {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .analytics-total-info-block-title {
    font-family: "Inter";
    font-size: 19px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #141315;
  }

  .analytics-total-info-block-description {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #7d8898;
  }

  .onboarding-welcome-image-desktop {
    display: none;
  }

  .onboarding-welcome-image-mobile {
    display: block;
  }

  .maintenance-page-wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #1b003e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 8px;
  }

  .maintenance-page-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-transform: uppercase;
  }

  .maintenance-page-image {
    width: 60px;
  }

  .main-block-wrapper {
    width: 100%;
    min-height: 400px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
  }

  .static-types-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
  }

  .header-unauthorized-desktop {
    display: none;
  }

  .header-unauthorized-mobile {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .header-menu-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #9747ff;
    height: 100%;
    width: 100%;
    z-index: 100;
    padding: 0 16px;
  }

  .header-menu-mobile-signedin {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    min-height: 100vh;
    width: 100%;
    z-index: 100;
    padding: 0 16px;
  }

  .please-sign-up-modal-body {
    display: flex;
    flex-direction: column-reverse;
  }

  .please-signup-text-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 16px;
    height: 100%;
    min-height: 100vh;
  }

  .please-signup-big-dark-text {
    text-align: center;
  }

  .please-signup-big-grey-text {
    text-align: center;
  }

  .please-signup-girl-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 0px;
    overflow: hidden;
    display: none;
  }

  .please-signup-close-btn {
    position: absolute;
    width: 44px;
    height: 44px;
    right: 0px;
    bottom: 0px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-top-right-radius: 0px;
  }

  .please-signup-close-btn svg {
    fill: black;
    margin-top: 12px;
  }

  .modal-please-signup {
    min-height: 100vh;
  }

  .qr-colors-customize-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    gap: 8px;
    border-radius: 0px;
    border: unset;
  }

  .qr-color-picker-block {
    display: flex;
    width: 100%;
    margin-top: 0px;
    justify-content: flex-start;
  }

  .qr-color-change-input-block {
    display: flex;
    margin-right: 8px;
    max-width: 100%;
  }

  .fixed-button-generate {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    z-index: 99;
  }

  .create-qr-code-btn-mobile {
    display: flex;
    height: 44px;
    background: #9747ff;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    padding: 8px 16px;
    width: 100%;
  }

  .qr-code-block {
    display: none;
    width: 100%;
    box-shadow: none;
  }

  .static-types-wrapper-mobile {
    display: flex;
    flex-wrap: wrap;
  }

  .qr-code-block-back-btn {
    display: flex;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #9747ff;
    padding: 16px 12px;
    padding-left: 0px;
    gap: 1px;
    height: 32px;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .main-content-wrapper {
    min-height: calc(100vh - 76px);
    /* max-width: 1120px; */
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
  }

  .main-content-wrapper-centered {
    min-height: calc(100vh - 76px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .qr-code-properties-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 0px;
    width: 100%;
    background: #ffffff;
    border-radius: 0px;
    position: relative;
    margin-top: -22px;
  }

  .generate-btn-under-qr {
    display: none;
  }

  .select-type-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 0;
  }

  .generate-qr-code-title {
    padding-left: 5px;
  }

  .header-free-downloads-left {
    display: none;
  }

  .header-vertical-line {
    display: none;
  }

  .header-notification-icon {
    display: none;
  }

  .border-line-static-types {
    display: flex;
    width: 100%;
    height: 1px;
    border: 1px solid #f1f5f9;
  }

  .block-inputs-column {
    flex-direction: column;
  }

  .header-menu-close-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    padding-top: 23px;
  }

  .header-menu-header-logo-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 14.25px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
  }

  .qr-customize-option-label {
    color: #334155;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .header-dropdown-desktop {
    display: none !important;
  }

  .header-dropdown-mobile {
    display: flex !important;
  }

  .navigation-create-new-code {
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 8px;
    gap: 0px;
    height: 36px;
    background: #9747ff;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 0px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
  }

  .header-menu-navigation-item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
  }

  .header-menu-upgrade-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 12px;
    gap: 8px;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #334155;
    margin-right: 16px;
    background-color: white;
  }

  .upgrade-plan-block-wrapper {
    display: none;
  }

  .overview-welcome-back-block {
    display: none;
  }

  .overview-stats-block {
    max-width: 1200px;
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
  }

  .overview-stats-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .overview-block-wrapper {
    max-width: 1200px;
    width: 100%;
    padding: 0px;
  }

  .overview-stats-live-scans-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 722px;
    width: 100%;
    height: 419px;
    background: #ffffff;
    border-radius: 0px;
    margin-top: 2px;
  }

  .overview-stats-activity-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
    max-width: 722px;
    width: 100%;
    height: fit-content;
    background: #ffffff;
    border-radius: 0px;
  }

  .overview-active-codes-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }

  .overview-active-codes-block {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }

  .overview-block-qr {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    width: 192px;
    height: 325px;
    background: #ffffff;
    border-radius: 16px;
    position: relative;
  }

  .overview-active-block-qrs-list {
    display: flex;
    justify-content: flex-start;
    margin-top: 24px;
    padding: 0 20px;
    gap: 10px;
    overflow-x: scroll;
  }

  .overview-block-qr-image-block {
    width: 160px;
    height: 160px;
  }

  .overview-qr-code-link-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 8px;
    gap: 6px;
    width: 100%;
    height: 36px;
    border: 0.789389px solid #e2e8f0;
    border-radius: 5.54px;
    margin-top: 2px;
  }

  .overview-qr-code-more-options {
    position: absolute;
    top: 185px;
    right: 0px;
    cursor: pointer;
  }

  .overview-block-qr-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  .header-signup-white-text {
    text-align: start;
  }

  .overview-mobile-generate-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 6px;
    width: 100%;
    height: 44px;
    background: #9747ff;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    margin-right: 8px;
  }

  .overview-mobile-generate-button:disabled {
    border-radius: 8px;
    background: #e0c8ff;
  }

  .creation-qr-code-block {
    display: none;
    width: 30%;
    background-color: #fff;
    padding: 24px;
    border-radius: 16px;
    height: fit-content;
  }

  .creation-content-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: fit-content;
  }

  .creation-dashboard-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    gap: 0px;
    width: 100%;
  }

  .creation-content-first-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .creation-content-title {
    color: #334155;
    font-size: 19px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    line-height: 23px;
    letter-spacing: -0.38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
  }

  .selected-type-block {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    border: 1px solid #f1f5f9;
    background: rgba(151, 71, 255, 0.1);
    cursor: pointer;
    width: 100%;
    flex-basis: unset;
    border-collapse: unset;
    margin: 0px;
    border-radius: 8px;
  }

  .select-type-block {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    border: 1px solid #f1f5f9;
    background: #fff;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    flex-basis: unset;
    border-collapse: unset;
    margin: 0px;
  }

  .creation-content-select-type {
    display: flex;
    flex-wrap: wrap;
    border-radius: 0px;
    overflow: hidden;
    border: none;
  }

  .creation-content-show-codes {
    display: none;
  }

  .creation-content-show-codes-mobile {
    display: flex;
    color: #9747ff;
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.28px;
    cursor: pointer;
    margin-top: 16px;
  }

  .selected-type-title {
    color: #141315;
    font-size: 19px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    line-height: 23px;
    letter-spacing: -0.38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
  }

  .qr-code-customize-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 0;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #f1f5f9;
    background: #fff;
    gap: 24px;
  }

  .qr-colors-customize-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }

  .generator-image-block {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 2px;
  }

  .generator-image-block-selected {
    background: rgba(151, 71, 255, 0.1);
    border-radius: 8px;
  }

  .creation-mobile-generate-block {
    display: flex;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .qr-code-customization-frames-margin {
    margin-bottom: 60px;
  }

  .creation-qr-code-block-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px;
  }

  .preview-section-phone-block {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
  }

  .preview-upgrade-block {
    width: 100%;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: none;
    border-radius: 8px;
  }

  .preview-upgrade-block-line-mobile {
    display: block;
    height: 1px;
    border-top: 1px solid #f1f5f9;
    position: relative;
    width: 100vw;
    margin-left: -16px;
    margin-top: 16px;
  }

  .content-creation-input-label {
    margin-bottom: 8px;
    color: #141315;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .preview-link-text {
    display: flex;
    height: 34px;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #f1f5f9;
    color: #9747ff;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .modal-please-signup {
    min-height: 100vh;
    height: 100%;
  }

  .modal-please-signup .rounded-lg {
    padding: 0 !important;
    border-radius: 0px;
    min-height: 100%;
    max-height: unset;
    overflow-y: scroll;
  }

  .modal-please-signup .max-w-xl {
    padding: 0 !important;
    border-radius: 0px;
    height: 100%;
    min-height: 100vh;
  }

  .modal-please-signup .max-w-4xl {
    padding: 0 !important;
    height: 100%;
    min-height: 100vh;
    border-radius: 0px;
  }

  .modal-please-signup .max-w-md {
    padding: 0 !important;
    min-height: 100vh;
    border-radius: 0px;
  }

  .header-wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background: #ffffff;
  }

  .please-signup-img-mobile {
    display: none;
  }

  .user-dashboard-navigation {
    max-width: 260px;
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #1b003e;
    height: 100dvh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .user-dashboard-navigation-collapsed {
    max-width: 60px;
    width: 100%;
    display: none;
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    height: 100dvh;
    border-right: 1px solid #e2e8f0;
  }

  .user-dashboard-navigation-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    min-height: 100%;
    padding: 82px 0 0 0;
  }

  .dashboard-content-create-block {
    display: none;
    align-items: center;
    width: 100%;
    border: 1px solid #bec5cf;
  }

  .header-logo-text {
    text-align: center;
    font-family: "Inter";
    font-size: 14.25px;
    font-style: normal;
    font-weight: 800;
    line-height: 17.25px;
    letter-spacing: -0.28px;
    margin-left: 8px;
    margin-top: 2px;
    color: #141315;
    text-transform: capitalize;
  }

  .user-header-input-wrapper {
    display: flex;
    width: fit-content;
    height: 44px;
    padding: 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: transparent;
    border: none;
  }

  .user-header-input-wrapper:hover {
    border: none;
    background: none;
    transition: 0.2s;
  }

  .mobile-header-user-image {
    width: 32px;
    height: 32px;
    border-radius: 32px;
  }

  .dashboard-content-trial-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    min-height: 136px;
    padding: 16px 16px 16px 16px;
    justify-content: space-between;
    align-items: center;
    background: var(--New-Gradient,
        radial-gradient(125.73% 1111.42% at 0% 0%, #eb72a5 0%, #9747ff 100%));
  }

  .trial-block-left-text {
    color: #fff;
    text-align: start;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .close-trial-image-desktop {
    display: none;
  }

  .close-trial-image-mobile {
    display: flex;
  }

  .trial-block-left-days {
    display: none;
    height: 32px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .trial-block-left-side {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  .trial-block-right-side {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;
    margin-top: 16px;
    width: 100%;
  }

  .trial-block-right-upgrade {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #334155;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    border-radius: 8px;
    background: #ffffff;
    width: 100%;
  }

  .content-codes-list-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
    padding: 16px;
  }

  .content-codes-filters-block {
    display: none;
    align-items: center;
    gap: 24px;
  }

  .code-creation-header-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #334155;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  .convert-code-graient {
    width: 163px;
    height: 163px;
    transform: rotate(45deg);
    flex-shrink: 0;
    opacity: 0.20000000298023224;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%),
      radial-gradient(785.73% 141.42% at -0% 0%,
        #56ccf2 0%,
        #f2c34c 33.85%,
        #ff47ed 65.1%,
        #9747ff 100%);
    position: absolute;
    top: 35px;
    left: 28px;
  }

  .content-codes-item-block {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-radius: 8px;
    background: #fff;
    border-top: unset;
    border-bottom: none;
  }

  .content-codes-item-block:last-child {
    border-bottom: none;
  }

  .content-codes-item-checkbox {
    width: 24px !important;
    height: 24px !important;
    border-radius: 4px !important;
    border-color: #e2e8f0 !important;
    border-width: 2px !important;
    display: none !important;
  }

  .user-dashboard-wrapper {
    width: 100%;
    display: flex;
    min-height: calc(100vh - 76px);
    background-color: #ffffff;
  }

  .user-dashboard-qr-code-image {
    width: 60px;
    min-height: 60px;
  }

  .code-main-name-text {
    color: #334155;
    text-align: start;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: -8px;
    white-space: nowrap;
    overflow: hidden;
    max-width: unset;
    padding-right: 0;
    -webkit-mask-image: unset;
  }

  .code-main-name-date {
    color: #334155;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .code-main-name-type {
    display: flex;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    border: 1px solid #e2e8f0;
    color: #9747ff;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    background-color: white;
    width: fit-content;
  }

  .content-code-dynamic-base-info {
    display: none;
    padding: 16px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #f1f5f9;
  }

  .content-dynamic-details-button {
    border-radius: 8px;
    background: #f1f5f9;
    display: none;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #334155;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .content-codes-link-block {
    display: none;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #f1f5f9;
    height: 40px;
    gap: 8px;
  }

  .content-convert-to-dynamic-wrraper {
    display: none;
    height: 40px;
    padding: 1px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #56ccf2, #f2c34c, #ff47ed, #9747ff);
    border-radius: 8px;
  }

  .content-codes-list-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    border-radius: 8px;
    border: none;
    background: transparent;
    overflow: hidden;
    padding: 16px;
    padding-top: 0;
  }

  .dashboard-content-codes-list {
    display: flex;
    width: 100%;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .content-codes-mobile-type-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  }

  .download-svg {
    fill: #bec5cf;
    color: #bec5cf;
  }

  .user-dashboard-add-mobile-btn {
    display: flex;
    width: 56px;
    height: 56px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #9747ff;
    position: fixed;
    bottom: 50px;
    right: 15px;
    cursor: pointer;
  }

  .mobile-search-wrapper {
    position: absolute;
    z-index: 3;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .mobile-search-block {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    padding-bottom: 8px;
  }

  .mobile-search-input-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    padding: 12px 16px;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    background: #f1f5f9;
    width: calc(100% - 85px);
  }

  .mobile-search-vertical-line {
    border-right: 2px solid #334155;
    width: 1px;
    height: 100%;
  }

  .mobile-search-input {
    background-color: transparent !important;
    border: none;
    padding-left: 0px !important;
    width: 85%;
  }

  .mobile-search-input:focus {
    box-shadow: none !important;
    border: none;
  }

  .mobile-search-input::placeholder {
    color: #bec5cf;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .mobile-search-cancel {
    color: #9747ff;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .mobile-search-filters-border {
    border-bottom: 1px solid #f1f5f9;
  }

  .content-codes-list-wrapper-search {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .upgrade-plan-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 100%;
    background-color: white;
  }

  .upgrade-plan-title {
    color: #334155;
    font-family: "Inter";
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.46px;
    margin-bottom: 16px;
    width: 100%;
    text-align: start;
  }

  .upgrade-plan-name {
    color: #334155;
    font-family: "Inter";
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.38px;
  }

  .upgrade-plan-card-block {
    display: flex;
    min-height: 520px;
    height: unset;
    max-width: 380px;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 14px;
    border: 1px solid #e2e8f0;
  }

  .upgrade-plan-card-block-best {
    display: flex;
    min-height: 520px;
    height: unset;
    max-width: 380px;
    padding: 24px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    border-top: 1px solid white;
    background-color: #f5edff;
    position: relative;
    margin-top: 34px;
  }

  .upgrade-plan-cards-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }

  .choose-plan-button {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: #9747ff;
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-top: 16px;
  }

  .upgrade-success-text {
    max-width: 320px;
    width: 100%;
    color: #334155;
    text-align: center;
    font-family: "Inter";
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: -0.46px;
    margin-top: 24px;
    margin-bottom: 40px;
  }

  .upgrade-success-button {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    display: flex;
    width: 320px;
    height: 44px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #9747ff;
  }

  .mobile-upgrade-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    margin-top: 0px;
  }

  .desktop-upgrade-text {
    display: none;
  }

  .upgrade-text-title-mobile {
    margin-bottom: 8px !important;
  }

  .user-dashboard-mobile-pointer .user-dashboard-content {
    background-color: #e2e8f0;
  }

  .user-dashboard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 60px);
    overflow-y: auto;
    position: relative;
  }

  .checkout-body {
    flex-direction: column;
  }

  .checkout-field-block,
  .checkout-body-right,
  .checkout-header {
    width: 100%;
    border-radius: 0;
  }

  .checkout-field-block {
    border-top: none;
    padding: 16px;
    border: unset;
  }

  .checkout-body {
    gap: 4px;
  }

  .checkout-page {
    gap: 0;
    padding: 0 !important;
  }

  .checkout-header span {
    font-size: 12px;
    line-height: 12px;
  }

  .checkout-header span img {
    height: 14px;
    width: auto;
    margin-right: 4px !important;
  }

  .checkout-header {
    padding: 16px !important;
    border-bottom: 1px solid #f2f2f2;
  }

  .checkout-body-header {
    font-size: 23px;
    font-weight: 700;
    line-height: 28px;
    text-align: start;
    padding: 16px !important;
  }

  .checkout-card-field,
  .checkout-address-block {
    padding: 16px !important;
  }

  .checkout-address-block h2 {
    font-size: 19px;
    line-height: 23px;
  }

  .checkout-button {
    height: 44px;
    font-size: 16px;
  }

  .checkout-plan-info {
    border: none;
    padding: 0 !important;
  }

  .checkout-satisfaction {
    display: flex;
    padding: 16px;
    border-radius: 0px;
    margin-top: 16px;
    position: relative;
    width: 100vw;
    margin-left: -24px;
    background-color: transparent;
    border-bottom: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
  }

  .code-details-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0px;
    background-color: white;
    padding: 16px 0px 16px 0;
  }

  .details-header-back-btn {
    display: flex;
    height: 44px;
    padding: 10px;
    padding-left: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: none;
    color: var(--black, #334155);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .deatils-header-download-btn {
    display: none;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--purple, #9747ff);
    color: var(--white, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .details-header-edit-btn {
    display: none;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--grey-1, #f1f5f9);
    color: var(--black, #334155);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .details-header-actions-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  .details-code-item-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    border-radius: 14px;
    background: #fff;
    padding: 0;
  }

  .details-code-base-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    width: unset;
  }

  .code-details-header-container .code-main-info-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .content-code-dynamic-base-info-details {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #f1f5f9;
    width: 100%;
  }

  .code-dynamic-base-links-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 0px;
    border-right: none;
    padding-top: 8px;
    border-top: 1px solid #f1f5f9;
    width: 100%;
    align-items: center;
  }

  .details-code-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 24px;
    padding: 0 16px;
  }

  .code-dynamic-total-scans-count {
    color: #334155;
    text-align: center;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.56px;
  }

  .details-period-filter-list {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #fff;
    padding: 2px;
    overflow-x: auto;
    width: 100%;
  }

  .details-statistics-graph-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
  }

  .details-statistics-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }

  .details-statistics-graph {
    background-color: #fff;
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 14px;
  }

  .details-filter-period-mobile {
    border-radius: 0px;
    height: 100%;
    padding: 0px;
  }

  .details-filter-period-mobile .details-period-filter-item-active {
    display: flex;
    height: 100%;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 0px;
    background: transparent;
    color: #9747ff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    cursor: pointer;
    min-width: 110px;
    border-bottom: 2px solid #9747ff;
  }

  .details-filter-period-mobile .details-period-filter-item {
    display: flex;
    height: 28px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    color: #334155;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    background: transparent;
    cursor: pointer;
    min-width: 110px;
    border-bottom: 2px solid transparent;
  }

  .details-statistics-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 43px;
    border-top: none;
    border-bottom: 1px solid var(--grey-2, #e2e8f0);
    border-top: 1px solid var(--grey-2, #e2e8f0);
    padding-top: 0;
  }

  .details-operating-system-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding-top: 16px;
  }

  .details-scans-table {
    padding: 0px;
    background-color: white;
    border-radius: 14px;
    width: 100%;
  }

  .scans-table-header-wrapper-mobile {
    display: none;
  }

  .scans-table {
    margin-top: 16px;
    width: 100%;
  }

  .scans-table-row {
    height: unset;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 4px 0;
  }

  .progress-bar-mobile {
    width: 85% !important;
  }

  .scans-persentage-mobile {
    position: absolute;
    right: 0;
    bottom: 8px;
  }

  .scans-table-cell-progress-block {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px 4px 0;
    width: 100%;
  }

  .code-details-mobile-actions-block {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 76px;
    z-index: 5;
    background-color: white;
    border-top: 1px solid #f1f5f9;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .code-details-preview-button {
    display: flex;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: var(--purple, #9747ff);
    color: var(--white, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    width: 100%;
  }

  .details-filter-scans-mobile {
    padding: 2px;
    background-color: #f1f5f9;
  }

  .details-filter-scans-mobile .details-period-filter-item-active {
    display: flex;
    height: 28px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 6px;
    background: #fff;
    color: #9747ff;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    cursor: pointer;
    width: 100px;
  }

  .details-filter-scans-mobile .details-period-filter-item {
    display: flex;
    height: 28px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 6px;
    color: #334155;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    background: transparent;
    cursor: pointer;
    width: 100px;
  }

  .scans-by-language-mobile {
    margin-bottom: 80px;
  }

  .mobile-creation-flow-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    background-color: #fff;
  }

  .mobile-creation-step-wrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .mobile-creation-heading {
    color: var(--black, #334155);
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 23px;
    letter-spacing: -0.38px;
  }

  .mobile-creation-flow-border-line {
    height: 1px;
    border-top: 1px solid #e2e8f0;
    width: 100%;
  }

  .mobile-creation-type-select {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: #fff;
    border-radius: 8px;
  }

  .mobile-select-dynamic-active {
    border-image-slice: 1;
    border-width: 1px;
    border-image-source: linear-gradient(to right,
        #56ccf2,
        #f2c34c,
        #ff47ed,
        #9747ff);
    border-radius: 8px;
  }

  .mobile-creation-type-select-border-wrapper-dynamic {
    background: linear-gradient(to right, #56ccf2, #f2c34c, #ff47ed, #9747ff);
    padding: 1px;
    border-radius: 8px;
  }

  .mobile-creation-type-select-border-wrapper {
    background: #e2e8f0;
    padding: 1px;
    border-radius: 8px;
  }

  .mobile-creation-flow-actions-block {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    width: 100%;
    z-index: 1;
    border-top: 1px solid #f1f5f9;
  }

  .mobile-creation-flow-actions-block-first-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    width: 100%;
    z-index: 1;
    border-top: 1px solid #f1f5f9;
  }

  .mobile-creation-flow-cancel-btn {
    display: flex;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: var(--grey-2, #e2e8f0);
    color: var(--black, #141315);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .mobile-creation-flow-next-btn {
    display: flex;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: var(--purple, #9747ff);
    color: var(--white, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .mobile-creation-flow-next-btn:disabled {
    background-color: rgba(151, 71, 255, 0.2);
  }

  .mobile-creation-steps-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile-creation-header-back {
    display: flex;
    align-items: center;
  }

  .mobile-creation-header-back-word {
    color: var(--purple, #9747ff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 1px;
  }

  .video-dynamic-checkbox-list {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  .mobile-creation-preview-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    min-height: calc(100vh - 50vh);
  }

  .overview-mobile-generate-button-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px;
    background-color: white;
    width: 100%;
    box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.04);
    z-index: 4;
  }

  .qr-customize-option-header-actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .creation-step-container-default {
    display: none;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #f1f5f9;
    color: #334155;
    text-align: center;
    font-size: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .checkout-page-loader {
    background: transparent;
    width: 100%;
    max-width: 1420px;
    height: fit-content;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    overflow: auto;
  }

  .dashboard-content-create-block-wrapper {
    display: none;
  }

  .user-dashboard-add-desktop-btn {
    display: none;
  }

  .graph-activity-no-data {
    color: var(--off-black, #7d8898);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    width: 100%;
    padding: 20px 0 10px 0;
  }

  .code-dynamic-total-scans-wrapper {
    display: none;
    flex-direction: column;
    border-right: 1px solid #e2e8f0;
    padding: 4px 0;
    padding-right: 24px;
  }

  .dashboard-code-buttons-wrapper {
    display: none;
  }

  .bootstrap-transparent-dropdown {
    padding: 0px !important;
  }

  .dashboard-code-row-with-gap {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .global-search-wrapper {
    width: unset;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .user-global-search-container {
    padding: 16px;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    transform: unset;
    border-radius: 0px;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
    z-index: 10;
    height: 100vh;
    width: 100%;
    max-width: unset;
  }

  .user-global-search-input {
    display: flex;
    width: calc(100% - 63px);
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #f1f5f9;
  }

  .user-global-search-cancel {
    display: block;
    color: var(--purple, #9747ff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .global-search-codes-list {
    max-height: unset;
    height: 100%;
    min-height: 80px;
    overflow-y: auto;
  }

  .global-search-filter-background .link-filter-block {
    border-radius: 14px 14px 0 0;
  }

  .global-search-filter-background {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background: rgba(51, 65, 85, 0.5);
    z-index: 7;
    border-radius: 0px;
  }

  .dashboard-content-trial-end-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: unset;
    padding: 16px 24px 16px 16px;
    justify-content: space-between;
    align-items: center;
    background: #ef1e11;
    gap: 16px;
  }

  .trial-end-block-left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .trial-end-block-left-desktop-text {
    display: none;
  }

  .trial-end-block-left-texts-mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .trial-end-mobile-left-text-bold {
    color: var(--white, #fff);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .trial-end-mobile-left-text-normal {
    color: var(--white, #fff);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .trial-end-block-right-upgrade {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--black, #334155);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    border-radius: 8px;
    background: #fff;
    width: 100%;
  }

  .manage-plan-active-plan-block {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 16px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--light-purple, #f5edff);
    width: 100%;
    margin-top: 16px;
  }

  .manage-plan-active-plan-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .manage-plan-active-plan-button {
    display: flex;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--purple, #9747ff);
    color: var(--white, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    width: 100%;
    margin-top: 16px;
    position: absolute;
    bottom: 24px;
    left: 24px;
    width: calc(100% - 48px);
    margin-bottom: 66px;
  }

  .manage-plan-modal {
    z-index: 100 !important;
  }

  .user-dashboard-wrapper-edit {
    width: 100%;
    display: flex;
    min-height: 100vh;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
  }

  .edit-list-files-img {
    width: 44px;
    height: 44px;
  }

  .edit-file-left-side-img-block {
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--grey-2, #e2e8f0);
    overflow: hidden;
    min-width: 44px;
  }

  .edit-file-right-side-block {
    display: none;
    align-items: center;
    gap: 16px;
  }

  .edit-file-left-side-img {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid var(--grey-2, #e2e8f0);
  }

  .dropdown-edit-list {
    display: block;
  }

  .auth-form-mobile {
    box-shadow: none;
  }

  .edit-file-image-mobile {
    width: 25px;
  }

  .details-header-edit-btn-icon {
    margin-left: 15px;
    display: none;
  }

  .global-search-static-image-wrapper {
    width: 46px;
    height: 46px;
    padding: 10px;
    border-radius: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--empty-state-text, #e2e8f0);
  }

  .global-search-dynamic-image-gradient-wrapper {
    background: linear-gradient(to right, #eb72a5, #9747ff);
    padding: 1px;
    border-radius: 100%;
  }

  .global-search-dynamic-image-wrapper {
    width: 44px;
    height: 44px;
    padding: 10px;
    border-radius: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navigation-footer-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px;
  }

  .nav-dropdown-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--black, #334155);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    height: 44px;
    padding: 16px 12px 16px 4px;
  }

  .nav-dropdown-item:hover {
    background-color: #f5edff;
    border-radius: 8px;
  }

  .creation-step-container-mobile {
    display: flex;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #f1f5f9;
    color: #334155;
    text-align: center;
    font-size: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .details-no-scans-banner-gradient {
    background: none;
    padding: 0px;
    border-radius: 0px;
    width: 100vw;
    margin-left: -16px;
    margin-top: -16px;
    position: relative;
  }

  .details-no-scans-banner {
    display: flex;
    flex-direction: column;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #f5edff;
    border-radius: 0px;
  }

  .details-no-scans-banner-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .details-no-scans-banner-text {
    color: var(--black, #334155);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    width: 250px;
  }

  .details-no-scans-banner-copy-btn {
    display: flex;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    color: var(--purple, #9747ff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .details-no-scans-image {
    width: 36px;
  }

  .already-have-an-account-text {
    display: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #334155;
    margin-top: 24px;
    text-align: center;
    position: fixed;
    bottom: 16px;
    left: 0;
    width: 100%;
  }

  .convert-code-icon-block {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .convert-code-qr-text {
    color: #334155;
    text-align: center;
    font-family: "Inter";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1.36px;
    margin-top: 20px;
    z-index: 2;
  }

  .convert-code-options-block {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #f1f5f9;
    margin-top: 20px;
  }

  .convert-code-button {
    display: flex;
    height: 60px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #9747ff;
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .reset-password-back-btn {
    display: block;
    color: var(--purple, #9747ff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 94px;
    left: 16px;
  }

  .edit-mp3-image {
    width: 25px;
  }

  .global-search-code-name-mobile {
    max-width: 60%;
  }

  .trademark-text {
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: -0.38px;
    font-style: italic;
    width: 100%;
    text-align: center;
    margin-bottom: 90px;
  }

  .reset-password-sent-modal>div {
    padding: 0 !important;
  }

  .stripe-purchase-button {
    padding: 0 16px 16px 16px;
    display: none;
  }

  .purchase-button-mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .product-information-section {
    padding: 16px;
    padding-left: 24px;
    padding-top: 0;
  }

  .header-busines-pages-navigation {
    display: none;
    align-items: center;
    gap: 16px;
  }

  .header-busines-pages-navigation-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 40px;
    width: 100vw;
    margin-left: -16px;
    margin-top: 16px;
  }

  .header-nav-link {
    color: var(--Black, #ffffff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.28px;
    transition: 0.5s;
    margin-top: 10px;
  }

  .qr-code-expired-label {
    display: flex;
    height: 18px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: var(--Black, #334155);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border-radius: 100px;
    width: 60px;
    background: rgba(239, 30, 17, 0.2);
  }

  .reset-header-logo-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 60px;
    padding: 0px 16px;
    border-bottom: 1px solid #f1f5f9;
    z-index: 2;
  }

  .send-confirmation-btn {
    margin-bottom: 46px;
  }

  .reset-password-modal-wrapper {
    min-height: calc(100vh - 76px);
    justify-content: space-between;
  }

  .reset-password-close {
    position: absolute;
    top: 28px;
    right: 15px;
    cursor: pointer;
    display: none;
  }

  .mobile-block {
    display: block;
  }

  .reset-password-sent-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    min-height: calc(100vh - 76px);
    width: 100%;
    gap: 24px;
  }

  .manage-plan-modal-body {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .new-home-introducing-section {
    width: 100%;
    max-width: 1180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
    padding-bottom: 0;
    gap: 0px;
  }

  .introducing-section-information {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .introducing-section-heading {
    color: #334155;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.56px;
    text-align: center;
  }

  .introducing-section-description {
    color: #334155;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 20px;
    text-align: center;
  }

  .introducing-section-create-button {
    width: 100%;
  }

  .new-home-abilities-section-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    max-width: 1180px;
    width: 100%;
    padding: 0px;
  }

  .abilities-section-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 50px 16px;
    max-width: unset;
    border-bottom: 2px solid white;
  }

  .abilities-section-block:first-child {
    padding-top: 0;
  }

  .abilities-section-block:last-child {
    padding-bottom: 0;
    border: none;
  }

  .abilities-section-block-title {
    color: #334155;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.38px;
    margin-top: 16px;
    text-align: center;
  }

  .abilities-section-block-description {
    color: #334155;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;
    text-align: center;
  }

  .discover-section-codes-preview-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  .discover-section-codes-preview {
    display: flex;
    flex-direction: column-reverse;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 24px;
    background: #f1f5f9;
    max-height: unset;
  }

  .discover-section-heading {
    color: #334155;
    text-align: center;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.46px;
  }

  .new-home-discover-section {
    display: flex;
    flex-direction: column;
    max-width: 1180px;
    width: 100%;
    padding: 24px 16px;
  }

  .discover-section-description {
    color: #334155;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .discover-section-codes-preview-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 400px;
    width: 100%;
  }

  .discover-section-code-item-default {
    display: flex;
    width: calc(50% - 4px);
    padding: 16px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #f1f5f9;
    background: #fff;
    color: var(--Black, #334155);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.28px;
    gap: 12px;
    cursor: pointer;
  }

  .discover-section-code-item-active {
    display: flex;
    width: calc(50% - 4px);
    padding: 16px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #9747ff;
    color: var(--White, #fff);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.28px;
    gap: 12px;
    cursor: pointer;
  }

  .discover-section-codes-preview-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  .discover-section-codes-preview-about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .discover-section-preview-content-description {
    color: #334155;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 8px;
    text-align: center;
  }

  .new-home-empower-section {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1180px;
    width: 100%;
    padding: 24px 16px;
  }

  .empower-block-title-text {
    color: #334155;
    text-align: center;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.46px;
    max-width: 222px;
    width: 100%;
  }

  .empower-section-description {
    color: #334155;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 320px;
    margin-top: 24px;
  }

  .footer-main-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 24px;
    gap: 24px;
    border-bottom: 1px solid #e2e8f0;
  }

  .footer-main-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
  }

  .footer-main-right-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  .new-home-footer {
    padding: 24px 0px;
    background-color: rgba(237, 243, 249, 1);
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .footer-copyright {
    color: #334155;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 0;
    padding-top: 24px;
  }

  .header-mobile-sign-up-button {
    display: flex;
    height: 36px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #9747ff;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .new-flow-mobile-creation-header {
    position: absolute;
    top: -76px;
    left: 0;
    width: 100%;
    height: 76px;
    z-index: 200;
    background-color: red;
  }

  .introducing-section-image {
    position: relative;
    display: none;
  }

  .introducing-section-image-mobile {
    position: relative;
    display: block;
  }

  .discover-section-codes-preview-picture {
    height: unset;
    width: unset;
  }

  .discover-section-codes-preview-about {
    max-width: 292px;
    width: 100%;
    height: 100%;
    padding-top: 0px;
  }

  .content-codes-link-text {
    color: #334155;
    text-align: center;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    max-width: 300px;
  }

  .code-expired-main-content {
    min-height: calc(100vh - 152px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    height: 100%;
  }

  .code-expired-create-button {
    display: none;
    width: 320px;
    height: 60px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    border-radius: 8px;
    background: #9747ff;
    margin-top: 16px;
  }

  .code-expired-title {
    color: #334155;
    text-align: center;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: -0.56px;
    margin-top: 16px;
  }

  .code-expired-description {
    color: #334155;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .code-expired-mobile-create-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    border-top: 2px solid #f1f5f9;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 76px;
  }

  .code-expired-create-button-mobile {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #9747ff;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
  }

  .user-header-logo-block-width-centered {
    display: flex;
    align-items: center;
    min-width: unset;
    max-width: unset;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .user-header-logo-block-width {
    display: flex;
    align-items: center;
    min-width: 180px;
    max-width: 180px;
  }

  .navigation-control-icon {
    margin-right: 0px;
    cursor: pointer;
  }

  .navigation-control-icon-wrapper {
    position: absolute;
    left: 260px;
    top: 0;
    background-color: #1b003e;
    z-index: 2;
    width: calc(100% - 260px);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 30px;
  }

  .navigation-control-icon-wrapper:hover {
    background-color: #1b003e !important;
  }

  .dashboard-mobile-bottom-navigation {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  .header-mobile-navigation-logout-button {
    display: flex;
    height: 36px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    color: #334155;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .header-manage-plan-new-button {
    display: flex;
    height: 36px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #334155;
    color: #334155;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .dashboard-second-navigation-filters-mobile {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #ffffff;
    padding-bottom: 8px;
  }

  .codes-list-add-code-block {
    background: #ffffff;
    padding: 24px;
    color: #9747ff;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
  }

  .dashboard-options-icons-block {
    display: none;
    align-items: center;
    gap: 1px;
  }

  .dashboard-code-item-content-mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .dashboard-code-item-mobile-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }

  .dashboard-code-item-mobile-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
  }

  .dashboard-code-item-mobile-info-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .dashboard-code-item-mobile-download {
    display: flex;
    height: 36px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #9747ff;
    color: #fff;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    width: calc(100% - 44px);
  }

  .dashboard-code-item-mobile-download:disabled {
    opacity: 0.6;
  }

  .code-main-name-type-gradient {
    background: #e0c8ff;
    padding: 1px;
    border-radius: 12px;
    width: fit-content;
  }

  .code-main-types-block-mobile {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .dashboard-code-dynamic-info-block {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-right: 0px;
    height: 100%;
    border-right: unset;
    height: unset;
  }

  .dashboard-code-static-info-block {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
  }

  .mobile-filter-expand-block-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .mobile-filter-expand-block-title {
    color: #334155;
    font-family: "Inter";
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.38px;
  }

  .mobile-filter-expand-block-reset {
    color: #9747ff;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    cursor: pointer;
  }

  .mobile-filter-expand-block-reset.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .dashboard-more-options-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
    color: #334155;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    height: unset;
    padding: 16px 0;
    border-radius: 0px;
    border-bottom: 1px solid #e2e8f0;
  }

  .mobile-confirm-tray {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .code-main-type-global-search-mobile {
    color: #334155;
  }

  .code-main-type-global-search-mobile img {
    filter: invert(22%) sepia(12%) saturate(1524%) hue-rotate(176deg) brightness(96%) contrast(87%);
  }

  .mobile-tray-horizontal-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-tray-horizontal-bar-element {
    width: 50px;
    height: 4px;
    border-radius: 100px;
    background: #e2e8f0;
    margin-top: -12px;
    margin-bottom: 5px;
  }

  .upgrade-plan-new-mobile-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    margin-top: 61px;
  }

  .upgrade-plan-new-plans-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px;
    border-radius: 16px;
    border: 1px solid #e2e8f0;
    background: #edf3f9;
  }

  .upgrade-plan-new-mobile-item {
    display: flex;
    padding: 16px;
    width: 100%;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    border: 2px solid transparent;
  }

  .upgrade-plan-new-mobile-item-active {
    display: flex;
    padding: 16px;
    width: 100%;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #9747ff;
    background: #ffffff;
  }

  .upgrade-plan-new-small-padding {
    padding: 4px;
  }

  .upgrade-plan-new-mobile-item-price {
    color: #334155;
    font-family: "Inter";
    font-size: 23px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: -0.46px;
  }

  .upgrade-plan-new-mobile-item-name {
    color: #334155;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .upgrade-plan-new-mobile-item-text-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: calc(100% - 36px);
  }

  .upgrade-plan-new-mobile-item-price-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .upgrade-plan-new-mobile-item-best {
    display: flex;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    border: 1px solid #9747ff;
    color: #9747ff;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .upgrade-plan-new-mobile-select-icon-inactive {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border: 2px solid #bec5cf;
    border-radius: 100px;
  }

  .upgrade-plan-new-mobile-item-save {
    display: flex;
    height: 24px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    border: 1px solid #27ae60;
    color: #27ae60;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  .upgrade-plan-new-mobile-choose-button-block {
    position: sticky;
    bottom: 0px;
    left: -4px;
    width: calc(100% + 8px);
    padding: 16px;
    border-top: 1px solid #e2e8f0;
    background: #ffffff;
  }

  .upgrade-plan-new-mobile-choose-button {
    display: flex;
    width: 100%;
    height: 56px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #9747ff;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
    color: #ffffff;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .checkout-new-title-powered {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 40px;
  }

  .checkout-card-field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 16px;
    padding: 16px;
    border-bottom: unset;
    border-top: 3px solid #e2e8f0;
    margin-top: 123px;
    margin-left: -16px;
    width: calc(100% + 32px);
  }

  .checkout-new-payment-method-title {
    color: #334155;
    font-family: "Inter";
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.46px;
  }

  .select-payments-block-wrapper {
    display: flex;
    padding: 0px;
    align-items: center;
    border-radius: 8px;
    background: #ffffff;
    width: 100%;
  }

  .selecet-payment-method-element {
    display: flex;
    align-items: center;
    gap: 8px;
    display: flex;
    height: 36px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: 100%;
  }

  .checkout-new-selected-plan-block {
    width: 100%;
    display: none;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
  }

  .checkout-body-right {
    background: #ffffff;
    padding: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  .checkout-guarantee-underline {
    margin-left: -16px;
    width: calc(100% + 32px);
    height: 4px;
    background-color: #e2e8f0;
  }

  .checkout-guarantee-block-text {
    color: #334155;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  .checkout-guarantee-icon-wrapper {
    display: flex;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #f5edff;
    border: 1px solid #e0c8ff;
  }

  .payment-element-wrapper {
    padding-bottom: 0px;
  }

  .disable-on-desktop {
    display: flex;
  }

  .checkout-new-purchase-block {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-top: 1px solid #e2e8f0;
    background: #ffffff;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .checkout-new-purchase-button {
    display: flex;
    width: 100%;
    height: 56px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #9747ff;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
    color: #ffffff;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .change-selected-plan-block-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    padding: 16px;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #ffffff;
  }

  .sign-up-modal-right-side-wrapper {
    display: none;
  }

  .new-sign-in-up-form {
    padding: 0;
  }

  .please-signup-small-text {
    color: #1c2128;
    text-align: center;
    font-family: "Inter";
    font-size: 19.432px;
    font-style: normal;
    font-weight: 800;
    line-height: 23.523px;
    letter-spacing: -0.389px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
    gap: 8px;
  }

  .please-signup-small-text span {
    color: #1c2128;
    text-align: center;
    font-family: "Inter";
    font-size: 19.432px;
    font-style: normal;
    font-weight: 800;
    line-height: 23.523px;
    letter-spacing: -0.389px;
  }

  .please-signup-small-text img {
    width: 32px;
    height: 32px;
  }

  .new-sign-up-in-page-title-wrapper-mobile {
    display: none;
  }

  .auth-switch-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    background: #edf3f9;
    margin-bottom: 40px;
  }

  .auth-switch-item {
    display: flex;
    height: 36px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    background-color: transparent;
    color: #141315;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    border-radius: 4px;
    cursor: pointer;
  }

  .auth-switch-item.active {
    background: #fff;
  }

  .mobile-hidden {
    display: none !important;
  }

  .desktop-hidden {
    display: flex !important;
  }

  .new-sign-up-in-page-google-btn {
    display: flex;
    height: 56px;
    width: 56px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 0px;
    align-self: stretch;
    border: 1px solid #e2e8f0;
    color: #141315;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 100px;
    font-size: 0px;
    margin: 0 auto;
  }

  .new-auth-page-close-mobile {
    display: block;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
  }

  .user-dashboard-content {
    border-radius: 16px 16px 0 0 !important;
    background-color: #f1f5f9;
    padding-bottom: 0px;
    margin-right: 0px;
  }

  .dashboard-navigation-bottom-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .dashboard-navigation-bottom-user-block-arrow {
    display: none;
  }

  .dashboard-navigation-options-dropdown {
    display: none;
  }

  .dashboard-navigation-bottom-user-block {
    padding-left: 8px;
    padding-bottom: 8px;
    width: calc(100% + 16px);
  }

  .dashboard-navigation-bottom-user-block:hover {
    transition: 0.2s;
  }

  .dashboard-navigation-mobile-underline {
    width: 100%;
    height: 1px;
    background: #311951;
    margin: 8px 0;
  }

  .purple-corners-block-wrapper {
    display: flex;
    width: 100%;
    background-color: transparent;
    padding: 0px;
  }

  .product-information-main {
    position: absolute;
    top: 60px;
    left: 0px;
    width: 100%;
    min-height: calc(100vh - 60px);
    background-color: white;
    display: flex;
    justify-content: center;
    border-radius: 16px 16px 0 0;
  }

  .upgrade-plan-purple-corners {
    border-radius: 16px 16px 0 0;
    height: 100dvh;
    overflow-y: scroll;
  }

  .checkout-page-loader-wrapper {
    width: 100%;
    min-height: calc(100vh - 76px);
    background-color: #e2e8f0;
    display: flex;
    justify-content: center;
    border-radius: 0px;
    height: unset;
  }

  .checkout-form {
    width: 100%;
    background-color: #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    height: unset;
  }

  .widget__actor__text {
    display: none !important;
  }

  .overview-panel-wrapper {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    z-index: 10;
    height: 100vh;
    background-color: #fff;
    border-left: 1px solid #e2e8f0;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }

  .overview-panel-code-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;
  }

  .overview-panel-code-info-name {
    color: #141315;
    text-align: start;
    font-family: "Inter";
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.46px;
    margin-bottom: 0px;
    border-bottom: none;
    padding-bottom: 0px;
    max-width: 100%;
  }

  .overview-panel-scrollable-content {
    height: calc(100vh - 52px);
    overflow-y: auto;
  }

  .overview-panel-mobile-code-info-block {
    display: flex;
    flex-direction: column;
  }

  .overview-panel-code-info-table td {
    padding-top: 16px;
  }

  .overview-panel-code-info-preview-block {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #ffffff;
    width: 100%;
    min-height: unset;
    position: relative;
    border: 1px solid #e2e8f0;
    max-width: unset;
  }

  .overview-panel-code-info-preview-phone {
    border-radius: 5px;
    background: #fff;
    width: 110px;
    height: 184px;
    overflow-y: auto;
  }

  .overview-panel-code-image-dynamic {
    width: 175px;
  }

  .overview-panel-stats-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .overview-panel-stats-scans-by-category-two-cols {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .overview-panel-stats-scans-by-category-table .scans-table-row {
    height: 44px;
    border-bottom: 1px solid #e2e8f0;
    display: table;
    width: 100%;
  }

  .overview-panel-count-scans {
    text-align: end;
    color: #141315;
    font-family: "Inter";
    font-size: 23px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 28px !important;
    letter-spacing: -0.46px;
  }

  .overview-panel-stats-convert-static-block {
    display: flex;
    flex-direction: column;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: #f5edff;
    margin-bottom: 0px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 20;
  }

  .overview-panel-stats-convert-static-block-right-button {
    display: flex;
    height: 36px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #9747ff;
    color: #fff;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    white-space: nowrap;
    width: 100%;
  }

  .overview-panel-stats-convert-static-block-right {
    width: 100%;
  }

  .overview-panel-mobile-stats-padding {
    padding-bottom: 124px;
  }

  .overview-panel-mobile-stats-padding-small {
    padding-bottom: 80px;
  }

  .overview-panel-code-image-static {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
    border: none;
  }

  .tooltip-custom {
    display: none;
  }

  .navigation-control-icon-block:active,
  .navigation-control-icon-block:target {
    background: rgba(255, 255, 255, 0.2) !important;
  }

  .navigation-control-icon-block:hover {
    background: transparent;
  }

  .navigation-control-icon-wrapper:active,
  .navigation-control-icon-wrapper:target {
    background: #1b003e !important;
  }

  .analytics-wrapper {
    width: 100%;
    background-color: #1b003e;
    padding: 0;
    display: flex;
  }

  .analytics-content-wrapper {
    border-radius: 16px 16px 0 0;
    background-color: #e2e8f0;
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 60px);
    padding: 0;
  }

  .analytics-content-wrapper.mobile-scrolled .analytics-content-select-codes-container {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    background-color: white;
    height: 76px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 4px 8px 0px #00000014;
    transition: 0.3s;
    transition-property: top, left, height, position;
    justify-content: space-between;
    z-index: 2;
  }

  .analytics-content-wrapper.mobile-scrolled {
    border-radius: 0px !important;
  }

  .analytics-content-wrapper.mobile-scrolled .analytics-content-codes-select {
    max-width: calc(50% - 16px);
  }

  .analytics-content-header {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    background-color: white;
    gap: 8px;
    border-bottom: 1px solid #e2e8f0;
  }

  .analytics-content-stats-block-wrapper {
    padding: 0px;
  }

  .analytics-content-stats-block {
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    border-radius: 0px;
    background: #ffffff;
    overflow-y: unset;
  }

  .analytics-content-select-codes-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    padding: 16px;
    padding-bottom: 0;
    width: 100%;
  }

  .analytics-content-block {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #e2e8f0;
    background: #fff;
  }

  .analytics-content-block-title.mobile {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: start;
    gap: 8px;
  }

  .analytics-block-table-mobile {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .analytics-block-table-mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .analytics-block-table-mobile-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .analytics-header-mobile-setting-button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid transparent;
  }

  .analytics-header-mobile-setting-button:active {
    border: 1px solid #141315;
  }

  .analytics-mobile-settings-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  .analytics-mobile-settings-item-text {
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
  }

  .analytics-mobile-settings-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .analytics-select-codes-menu-where-my-data-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    width: calc(100% + 32px);
    margin-left: -16px;
    border-top: 1px solid #e2e8f0;
  }

  .analytics-mobile-filter-header-with-back {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .analytics-mobile-settings-filters-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
    gap: 16px;
  }

  .analytics-mobile-settings-filters-label {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141315;
    width: 50%;
  }

  .analytics-mobile-settings-filters-selected {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #7d8898;
    width: 45%;
    text-align: end;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .analytics-content-block.mobile {
    gap: 0;
  }

  .analytics-mobile-settings .link-filter-block {
    min-height: unset;
  }

  .analytics-table-cell-percentage {
    color: #141315;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    width: 55px;
  }

  .analytics-graph-mobile-static-ticks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    bottom: 100%;
    left: calc(100% - 25px);
    height: calc(260px - 25px);
    background-color: white;
    width: fit-content;
    padding-left: 5px;
  }

  .analytics-graph-mobile-static-ticks span {
    font-size: 12px;
    font-weight: 600px;
    line-height: 14px;
    font-family: "Inter";
    letter-spacing: 0.08em;
    color: #bec5cf;
  }

  .analytics-header-reset-all-changes {
    padding-top: 16px;
    padding-right: 16px;
    height: fit-content;
  }

  .analytics-content-wrapper.mobile-scrolled .analytics-header-reset-all-changes {
    display: none;
  }

  .dashboard-second-navigation-mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard-second-navigation-mobile-header-actions {
    display: flex;
    align-items: center;
  }

  .content-codes-list-title {
    color: #141315;
    font-family: "Inter";
    font-size: 23px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .dashboard-second-navigation-mobile-code-actions {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .user-dashboard-second-navigation-create-code {
    width: 100%;
    height: 67px;
    padding: 8px;
    border-radius: 8px;
    gap: 8px;
    background-color: #9747ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    cursor: pointer;
    border: 1px solid #9747ff;
    transition: 0.2s;
  }

  .user-dashboard-second-navigation-default-button {
    min-width: unset;
    width: 100%;
    height: 67px;
    padding: 8px;
    border-radius: 8px;
    gap: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141315;
    cursor: pointer;
    border: 1px solid #e2e8f0;
    justify-content: center;
    transition: 0.2s;
  }

  .dashboard-list-view-mobile-element {
    border-top: 1px solid #e2e8f0;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .dashboard-list-view-mobile-element:last-child {
    border-bottom: 1px solid #e2e8f0;
  }

  .dashboard-list-view-mobile-element:active {
    background-color: #f7fafe;
  }

  .dashboard-list-view-mobile-element:first-child {
    margin-top: 8px;
  }

  .dashboard-list-view-mobile-left-side {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: calc(100% - 130px);
  }

  .dashboard-list-view-mobile-right-side {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .dashboard-list-view-mobile-left-name {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: start;
    width: 100%;
  }

  .dashboard-list-view-mobile-left-name span {
    white-space: nowrap;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(90deg, #000 80%, transparent);
    padding-right: 8px;
  }

  .dashboard-list-view-mobile-left-name .dashboard-active-dynamic-code-green-circle {
    margin-left: -15px;
  }

  .dashboard-list-view-mobile-left-status-blocks {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .filters-dropdown-menu-padding-mobile {
    padding: 2px !important;
    width: 240px !important;
  }

  .dashboard-mobile-settings-options-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  .global-search-qr-code-name {
    color: #141315;
    text-align: start;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(240px);
    width: 100%;
    -webkit-mask-image: linear-gradient(90deg, #000 80%, transparent);
  }

  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 5px;
  }

  .settings-wrapper {
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .settings-content-wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #1b003e;
    padding: 0px;
  }

  .settings-content-tabs {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0px;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 40px;
  }

  .settings-tab-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  .settings-tab-title {
    font-family: "Inter";
    font-size: 19px;
    font-weight: 800;
    line-height: 23px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #141315;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
  }

  .profile-settings-input {
    width: calc(100% - 30px);
  }

  .profile-settings-inputs-group {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .settings-mobile-tab-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    padding: 16px;
    padding-top: 0px;
    width: calc(100% + 32px);
    margin-left: -16px;
    border-bottom: 1px solid #e2e8f0;
    z-index: 2;
  }

  .settings-tab-information-block {
    width: 100%;
    padding: 0;
    border-radius: 16px;
    border: none;
    gap: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .profile-settings-buttons-group {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 8px;
  }

  .profile-settings-cancel-button {
    width: 100%;
    height: 44px;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #141315;
    gap: 8px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #141315;
  }

  .profile-settings-save-button {
    width: 100%;
    height: 44px;
    padding: 8px 12px;
    border-radius: 8px;
    gap: 8px;
    background-color: #9747ff;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
  }

  .settings-navigation-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 0px;
    border-top-left-radius: 0px;
  }

  .user-icon-settings-navigation {
    width: 70px;
    height: 45px;
    background-color: #e0c8ff;
    position: absolute;
    bottom: 8px;
    left: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: none;
    align-items: center;
    justify-content: start;
    padding-left: 4px;
  }

  .settings-mobile-menu-tab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    gap: 8px;
    border-bottom: 1px solid #e2e8f0;
  }

  .settings-mobile-menu-tab-header .settings-tab-title {
    width: 100%;
    margin-bottom: 0px;
  }

  .settings-navigation-item {
    width: calc(100% - 32px);
    height: 52px;
    padding: 0px 16px;
    border-radius: 0px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141315;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
    user-select: none;
    border: 1px solid #e2e8f0;
    border-bottom: none;
    margin-left: 16px;
  }

  .settings-navigation-item.first {
    margin-top: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .settings-navigation-item.last {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid #e2e8f0;
  }

  .settings-navigation-item.sign-out {
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #141315;
    justify-content: center;
    height: 44px;
  }

  .settings-navigation-item.sign-out:hover {
    background-color: #bec5cf;
    border: 1px solid #bec5cf;
  }

  .settings-navigation-item:hover,
  .settings-navigation-item:active {
    background-color: #edf3f9;
    border: 1px solid #edf3f9;
  }

  .settings-tab-description {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #7d8898;
  }

  .notification-settings-switch-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 8px;
    border: 1px solid #e2e8f0;
    margin-top: 16px;
    border-radius: 8px;
    position: relative;
  }

  .notification-settings-right-side {
    position: absolute;
    right: 16px;
    top: 20px;
  }

  .setting-tab-sub-title {
    font-family: "Inter";
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #141315;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .notification-settings-switch-group-block-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #e2e8f0;
    gap: 8px;
    position: relative;
  }

  .scheduled-notifications-setup {
    margin-left: 0px;
    width: fit-content;
    height: unset;
    padding: 8px 16px;
    border-radius: 8px;
    gap: 8px;
    background-color: #edf3f9;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }

  .schedule-notification-timezone-menu {
    position: absolute;
    top: -57px;
    right: 0px;
    border-radius: 8px !important;
    gap: 4px;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    user-select: none;
  }

  .payments-and-plan-settings-subtitle {
    font-family: "Inter";
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 0px;
  }

  .subscription-current-plan-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 8px;
    background: linear-gradient(90deg, #eb72a5 0%, #9747ff 100%);
    padding: 16px 24px 16px 16px;
    border-radius: 8px;
  }

  .subscription-current-plan-right-side-block {
    display: flex;
    flex-direction: column-reverse;
    align-items: start;
    width: 100%;
    gap: 8px;
  }

  .subscription-current-plan-cancel-button {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 100%;
  }

  .subscription-current-plan-change-button {
    width: 100%;
    height: 44px;
    padding: 8px 16px;
    border-radius: 8px;
    gap: 8px;
    background-color: #ffffff;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    color: #141315;
  }

  .corner-border-settings-navigation {
    display: none;
    position: absolute;
    left: 0px;
    bottom: 60px;
    background-color: #e0c8ff;
    width: 64px;
    height: 60px;
    z-index: 2;
  }

  .credit-card-form-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 8px;
    gap: 16px;
  }

  .settings-tab-underline {
    width: calc(100% + 32px);
    height: 1px;
    background-color: #e2e8f0;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: -16px;
  }

  .settings-tab-payment-method-edit-actions-block {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .settings-tab-payment-method-edit-actions-block button {
    width: 50%;
  }

  .settings-tab-payment-method-item-block {
    width: 100%;
    height: unset;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    gap: 14px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin-top: 4px;
    position: relative;
  }

  .settings-tab-payment-methods-header-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 4px;
  }

  .settings-tab-payment-method-action-icon {
    height: 18px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 16px;
  }

  .settings-tab-payment-method-default-btn {
    width: 100%;
    height: 44px;
    padding: 8px 12px;
    border-radius: 8px;
    gap: 8px;
    background-color: #edf3f9;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #7d8898;
  }

  .settings-tab-payment-method-set-default-btn {
    width: 100%;
    height: 44px;
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid #141315;
    gap: 8px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #141315;
  }

  .settings-tab-payment-method-item-right-side {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
  }

  .enter-password-modal .max-w-md {
    display: flex;
    align-items: center;
  }

  .enter-password-modal-close {
    position: absolute;
    top: -16px;
    right: -16px;
    cursor: pointer;
  }

  .user-feedback-input {
    width: 100%;
    height: 100px;
    padding: 8px 12px;
    resize: none;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #141315;
  }

  .user-feedback-modal-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 16px !important;
  }

  .overview-panel-info-link {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #9747ff;
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: unset;
    word-break: break-all;
  }

  .overview-panel-code-view-fullscreen {
    width: 100%;
    min-height: 36px;
    padding: 8px 12px;
    border: none;
    border-top: 1px solid #e2e8f0;
    gap: 8px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #141315;
    border-radius: 0 0 8px 8px;
  }

  .overview-panel-stats-header.mobile-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
  }

  .view-fullscreen-code-modal .max-w-lg {
    display: flex;
    align-items: center;
    max-width: 600px;
    padding: 0 !important;
  }

  .view-fullscreen-code-modal .max-w-lg .rounded-lg {
    min-height: 100vh;
    border-radius: 0px;
    overflow: hidden;
    width: 100%;
  }

  .view-fullscreen-code-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    gap: 24px;
  }

  .view-fullscreen-code-download {
    width: 100%;
    height: 36px;
    padding: 8px 16px;
    gap: 8px;
    background-color: #9747ff;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    border-radius: 0 0 6px 6px;
  }

  .view-fullscreen-code-link-block {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    justify-content: space-between;
    width: 100%;
    height: unset;
    border-radius: 8px;
    background-color: transparent;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #141315;
  }

  .view-fullscreen-code-exit-preview {
    min-width: 107px;
    width: 107px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #141315;
    gap: 8px;
    background-color: #ffffff;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #141315;
  }

  .view-fullscreen-code-landing-page-btn {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    border: 0.5px solid #9747ff;
    gap: 8px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    background-color: #9747ff;
  }

  .overview-code-page-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #1b003e;
    padding: 0px;
  }

  .overview-code-page-content {
    width: 100%;
    height: 100vh;
    padding: 24px 0px;
    background-color: #edf3f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
  }

  .overview-panel-wrapper.full-page .overview-panel-scrollable-content {
    height: calc(100vh - 36px);
    overflow-y: auto;
  }

  .overview-panel-wrapper.full-page {
    position: initial;
    width: 700px;
    box-shadow: none;
    border: 1px solid #e2e8f0;
    border-radius: 0px;
  }

  .setting-navigation-item-mobile-arrow {
    display: block;
    transform: rotate(-90deg);
  }

  .payments-and-plan-change-buttons-block button {
    width: 50%;
  }

  .onboarding-modal-content-block {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    padding: 16px;
  }

  .onboarding-first-step-progress-block {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    align-items: center;
    gap: 4px;
  }

  .onboarding-content-first-step-block {
    position: relative;
    background-color: #f5edff;
    padding: 24px;
    padding-top: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }

  .onboarding-content-images-block {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .onboarding-content-images-block.second {
    justify-content: end;
    background-color: #f5edff;
    border-radius: 8px;
  }

  .onboarding-content-images-edit-icon {
    position: absolute;
    top: 30px;
    right: 24px;
    width: 23px;
  }

  .onboarding-content-images-trend-icon {
    position: absolute;
    bottom: -10px;
    left: unset;
    right: 10px;
    width: 95px;
  }

  .onboarding-content-images-navigation-icon {
    position: absolute;
    top: 45px;
    left: 20px;
    width: 40px;
  }

  .onboarding-content-images-notification-icon {
    position: absolute;
    top: unset;
    left: 30px;
    bottom: -2px;
    width: 24px;
  }

  .onboarding-content-text-title {
    font-family: "Inter";
    font-size: 23px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #141315;
  }

  .onboarding-content-text-description {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #7d8898;
    padding: 0;
  }

  .onboarding-modal-footer-block {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-top: none;
    padding: 16px;
    padding-top: 0px;
    position: relative;
  }

  .onboarding-modal-footer-block.with-padding-top {
    padding-top: 24px;
  }

  .onboarding-content-codes-block {
    display: none;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin-top: 8px;
  }

  .onboarding-first-step-image {
    margin: 0 -30px;
    transition: 0.5s;
    width: 120px;
  }

  .onboarding-content-mobile-first-step-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 16px);
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
  }

  .onboarding-content-first-step-block .onboarding-preview-image {
    max-height: 300px;
  }

  .onboarding-mobile-first-step-code-type-block {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .onboarding-mobile-first-step-code-type-block img {
    width: 30px;
  }

  .onboarding-mobile-first-step-code-type {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
    color: #141315;
  }

  .onboarding-mobile-first-step-active-code {
    width: 32px;
    height: 24px;
    gap: 4px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    color: #141315;
    border-radius: 4px;
  }

  .onboarding-footer-steps-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .onboarding-footer-first-step-buttons {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  .onboarding-footer-first-step-buttons button {
    width: 50%;
  }

  .onboarding-content-abilities-block {
    display: none;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border: 1px solid #edf3f9;
    border-radius: 8px;
    margin-top: 8px;
  }

  .onboarding-content-images-tracking-image {
    max-height: 350px;
  }

  .onboarding-content-images-grey-code-icon {
    position: absolute;
    top: unset;
    bottom: -8px;
    left: -16px;
  }

  .onboarding-content-images-scans-by-browser-icon {
    position: absolute;
    bottom: 15px;
    left: 0px;
    display: none;
  }

  .onboarding-footer-get-started-button {
    width: 100%;
    height: 44px;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 8px;
    background-color: #9747ff;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
  }

  .settings-invoices-block {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8px;
  }

  .settings-invoice-block {
    max-width: 320px;
    width: 100%;
    border: 1px solid #e2e8f0;
    padding: 16px !important;
    border-radius: 8px;
    flex-direction: column;
    padding-bottom: 0px !important;
  }

  .settings-invoice-info {
    width: 100% !important;
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .settings-invoice-details-none {
    display: none !important;
  }

  .settings-invoice-header {
    width: auto !important;
  }

  .settings-invoice-bold {
    font-weight: 700 !important;
  }

  .settings-invoice-details {
    display: flex !important;
    margin-top: 8px !important;
    justify-content: space-between !important;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #e2e8f0;
  }

  .settings-invoice-button {
    background: #fff !important;
    color: #9747ff !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .onboarding-preview-mobile-left-switch-image {
    zoom: 0.5;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-70%, -50%);
    max-height: 452px;
  }

  .onboarding-preview-mobile-right-switch-image {
    zoom: 0.5;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(70%, -50%);
    max-height: 452px;
  }

  .contact-us-section.settings {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    gap: 24px;
    width: 100%;
    padding: 16px;
  }

  .notifications-panel-wrapper {
    position: absolute;
    top: 0px;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    box-shadow: 0px 0px 24px 0px #0000001f;
    z-index: 2;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .notification-panel-header-mark-as-read-block {
    display: none;
    align-items: center;
    gap: 8px;
  }

  .notifications-panel-header-mobile-back {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
  }

  .notifications-panel-header-mobile-back img {
    width: 20px;
    height: 20px;
  }

  .notifications-panel-item-more-options-block {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
  }

  .notification-panel-header-title {
    font-family: "Inter";
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #141315;
  }

  .notification-panel-item-unread {
    display: none;
  }

  .notification-panel-item-read-circle {
    display: none;
  }

  .notification-panel-item-dropdown {
    position: absolute;
    top: 15px;
    right: -1px;
    transform: translateY(-50%);
    z-index: 4;
  }

  .view-fullscreen-code-preview-block {
    display: flex;
    flex-direction: column;
    max-width: unset;
    width: 100%;
    margin: 0 auto;
  }

  .desktop-hide {
    display: flex;
  }

  .header-notifications-active {
    position: absolute;
    top: calc(50% - 3px);
    left: calc(50% - 7px);
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background: radial-gradient(100% 779.34% at 0% 0%,
        #eb72a5 0%,
        #9747ff 100%);
    border: 1px solid #1b003e;
  }

  .settings-tab-user-information-block {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
  }

  .settings-tab-user-profile-information {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .settings-tab-user-avatar {
    width: 115px;
    height: 115px;
    border-radius: 8px;
  }

  .settings-tab-user-information-edit-btn {
    width: 50%;
    height: 44px;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 8px;
    background-color: #9747ff;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #ffffff;
  }

  .settings-tab-user-information-edit-cancel {
    width: 50%;
    height: 44px;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #141315;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #141315;
  }

  .profile-mobile-select-language-list {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .profile-mobile-selected-language-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #000000;
  }

  .profile-mobile-selected-language-circle.active {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #9747ff;
  }

  .profile-mobile-selected-language-nested-circle {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
  }

  .profile-mobile-selected-language-circle.active .profile-mobile-selected-language-nested-circle {
    background-color: #9747ff;
  }

  .user-information-profile-image-change-block {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000080;
    cursor: pointer;
  }

  .mobile-save-cancel-profile-buttons {
    flex-direction: row-reverse;
  }

  .testimonials-section-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f5edff;
    padding: 24px 16px;
    gap: 24px;
    overflow: hidden;
  }

  .carousel-control-item {
    display: none;
  }

  .testimonial-item {
    max-width: 350px;
    width: 100%;
    min-height: 416px;
    padding: 40px;
    gap: 16px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 6px 12px 0px #0000001c;
    margin: 0 auto;
  }

  .mobile-slide-indicators-block {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
    width: fit-content;
  }

  .mobile-slide-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
  }

  .mobile-slide-indicator.active {
    background-color: #9747ff;
  }

  .testimonials-section-carousel-wrapper {
    max-width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 !important;
    gap: 16px;
    margin-top: -20px !important;
    margin-bottom: -20px !important;
    width: calc(100% + 20px);
    margin-left: -10px !important;
  }

  .swiper.swiper-initialized {
    padding: 20px 10px;
    padding-bottom: 40px;
  }

  .swiper-pagination {
    top: unset !important;
    bottom: 0px !important;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ffffff !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #9747ff !important;
  }

  .no-scroll {
    overflow: hidden;
  }

  .convert-static-codes-to-dynamic-modal-body {
    padding: 24px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .color-palette-list {
    display: grid;
    width: fit-content;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    column-gap: 16px;
    margin-top: 8px;
    width: calc(100% - 12px);
  }

  .desktop-creation-preview-image {
    border-radius: 24px;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 166px 66px rgba(0, 0, 0, 0.01),
      0px 93px 56px rgba(0, 0, 0, 0.02), 0px 41px 41px rgba(0, 0, 0, 0.03),
      0px 10px 23px rgba(0, 0, 0, 0.04);
    height: 100%;
  }

  .dashboard-navigation-settings-button {
    width: 100%;
    height: 36px;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #ffffff1a;
    background-color: #311951;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 8px;
  }

  .desktop-creation-flow {
    width: 100%;
    display: none;
    grid-template-columns: 1fr 340px;
    min-height: calc(100vh - 68px);
  }

  .landing-faq-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 24px 16px;
    width: 100%;
  }

  .landing-faq-title {
    font-family: "Inter";
    font-size: 23px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #141315;
  }

  .testimonials-section-description {
    margin-top: 0;
  }

  .empower-section-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 16px;
    padding-bottom: 16px;
    border-radius: 16px;
    width: 100%;
    background-color: #fff;
  }

  .cancel-plan-upgrade-to-yearly-block {
    background-color: #9747ff;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    height: fit-content;
  }

  .cancel-plan-confirm-block {
    border: 1px solid #e2e8f0;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    height: fit-content;
  }

  .charge-page-background {
    width: 100%;
    height: 254px;
    min-height: 254px;
    background-image: url("../assets/images/charge-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0px;
    overflow: hidden;
    position: relative;
  }

  .charge-page-background-top-layer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #390084 0%, #9747ff 100%);
    width: 100%;
    height: 100%;
    filter: blur(0px);
  }

  .sticky-header-upgrade.new-dashboard-header-wrapper {
    background-color: #ffffff !important;
  }

  .embed-code-modal .modal-md {
    max-width: 480px !important;
    display: flex;
    height: unset;
  }

  .user-dashboard-content-new-home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100dvh - 60px);
    overflow-y: auto;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
  }
}

@media (min-width: 900px) {
  .global-search-codes-list::-webkit-scrollbar {
    width: 5px;
  }

  .global-search-codes-list::-webkit-scrollbar-thumb {
    background-color: #e2e8f0;
  }
}